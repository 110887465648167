import queryString from 'query-string';

/**
 * Retrieves given query param from current URL.
 * @param {string} param - the name of the query param
 */
export const getQueryParam = param => queryString.parse(location.search)[param] || '';

/**
 * Removes given query param from current URL in place, without triggering a page reload
 * @param {string} param - the name of the query param
 */
export function removeQueryParam(param) {
  const params = queryString.parse(location.search);
  delete params[param];
  history.replaceState(history.state, '', `${location.pathname}?${queryString.stringify(params)}`);
}
