import { Tooltip } from '@salesforce/design-system-react';
import copy from 'copy-text-to-clipboard';

import EditTranscript from './EditTranscript';

const Transcript = ({
  callId,
  recordingId,
  transcript,
  editTranscriptMode,
  dualSpeakers,
  dualChannel,
  srmName,
  singleCall,
  keywordSearchText,
  keywordSearchBy,
  groupedKeywords,
  setCallTimeCallback,
  disableAutoScrollCallback,
  editTranscriptCallback,
}) => {
  const getTranscriptTimestamp = startTime => {
    const timestampMinutes = Math.floor(startTime / 60);
    const timestampSeconds = Math.floor(startTime - timestampMinutes * 60);
    const combinedTimestamp = [];
    if (timestampMinutes > 0) combinedTimestamp.push(`${timestampMinutes}m`);
    combinedTimestamp.push(`${timestampSeconds}s`);
    return combinedTimestamp.join(' ');
  };

  const highlightMessage = (sentence, index, speakerBound) => {
    const sentenceIndex = index;
    let matchedKeyword;
    let messageIndex = -1;
    if (keywordSearchText) messageIndex = sentence.message.toLowerCase().indexOf(keywordSearchText.toLowerCase());
    else if (!Array.isArray(groupedKeywords))
      messageIndex = sentence.message.toLowerCase().indexOf(groupedKeywords.toLowerCase());
    else {
      matchedKeyword = sentence.message.match(new RegExp(groupedKeywords.join('|'), 'gi'));
      for (let i = 0; i < groupedKeywords.length; i++) {
        if (messageIndex === -1)
          messageIndex = sentence.message.toLowerCase().indexOf(groupedKeywords[i].toLowerCase());
      }
    }
    const chatBubbleClass =
      (keywordSearchBy &&
        ((keywordSearchBy === 'Supplier' && speakerBound === 'inbound') ||
          (keywordSearchBy === 'SRM' && speakerBound === 'outbound') ||
          !dualChannel ||
          keywordSearchBy === 'Both')) ||
      !keywordSearchBy
        ? 'highlight-bubble'
        : '';
    const newMessage = (
      <div className="slds-grid slds-grid_vertical-align-center">
        {speakerBound === 'inbound' && editTranscriptMode && (
          <EditTranscript
            dualChannel={dualChannel}
            speakerBound={speakerBound}
            transcript={sentence}
            editTranscript={updatedTranscript => editTranscriptCallback(sentenceIndex, updatedTranscript)}
            disableAutoScroll={disableAutoScrollCallback}
          />
        )}
        <div
          className={`slds-chat-message__text slds-chat-message__text_${speakerBound} ${
            messageIndex >= 0 && chatBubbleClass
          }`}
          onClick={() => setCallTimeCallback(sentence.startTime)}
        >
          {sentence.message
            .split(
              new RegExp(
                keywordSearchText || (Array.isArray(groupedKeywords) ? groupedKeywords.join('|') : groupedKeywords),
                'gi'
              )
            )
            .reduce((prev, current, i) => {
              if (!i) return [current];
              return prev.concat(
                <span
                  key={
                    (keywordSearchText || (Array.isArray(groupedKeywords) ? matchedKeyword[i - 1] : groupedKeywords)) +
                    current +
                    i
                  }
                  className="highlight-text"
                >
                  {keywordSearchText || (Array.isArray(groupedKeywords) ? matchedKeyword[i - 1] : groupedKeywords)}
                </span>,
                current
              );
            }, [])}
        </div>
        {speakerBound === 'outbound' && editTranscriptMode && (
          <EditTranscript
            dualChannel={dualChannel}
            speakerBound={speakerBound}
            transcript={sentence}
            editTranscript={updatedTranscript => editTranscriptCallback(sentenceIndex, updatedTranscript)}
            disableAutoScroll={disableAutoScrollCallback}
          />
        )}
      </div>
    );
    return newMessage;
  };

  const copyTimeLink = callTime => {
    copy(`${window.location.protocol}//${window.location.host}/call/${recordingId}?time=${callTime}`);
  };

  return (
    <ul className="slds-chat-list">
      {transcript &&
        transcript.map((sentence, index) => {
          let speakerBound = sentence.speakerTag === 1 ? 'inbound' : 'outbound';
          if (!dualSpeakers) speakerBound = 'inbound';
          const messageMeta = `${
            dualChannel ? `${speakerBound === 'outbound' ? srmName : 'Supplier'} • ` : ''
          }${getTranscriptTimestamp(sentence.startTime)}`;
          return (
            <li
              key={index}
              id={`${callId}-sentence-${index}`}
              className={`slds-chat-listitem slds-chat-listitem_${speakerBound} ${
                !dualSpeakers || singleCall ? ' full-width-chat' : ''
              }`}
            >
              <div className="slds-chat-message">
                <div className="slds-chat-message__body">
                  {(keywordSearchText || (!singleCall && groupedKeywords.length > 0)) &&
                    highlightMessage(sentence, index, speakerBound)}
                  {!keywordSearchText && (singleCall || groupedKeywords.length === 0) && (
                    <div className="slds-grid slds-grid_vertical-align-center">
                      {speakerBound === 'inbound' && editTranscriptMode && (
                        <EditTranscript
                          dualChannel={dualChannel}
                          speakerBound={speakerBound}
                          transcript={sentence}
                          editTranscript={updatedTranscript => editTranscriptCallback(index, updatedTranscript)}
                          disableAutoScroll={disableAutoScrollCallback}
                        />
                      )}
                      <div
                        className={`slds-chat-message__text slds-chat-message__text_${speakerBound}`}
                        onClick={() => setCallTimeCallback(sentence.startTime)}
                      >
                        {' '}
                        {sentence.message}
                      </div>
                      {speakerBound === 'outbound' && editTranscriptMode && (
                        <EditTranscript
                          dualChannel={dualChannel}
                          speakerBound={speakerBound}
                          transcript={sentence}
                          editTranscript={updatedTranscript => editTranscriptCallback(index, updatedTranscript)}
                          disableAutoScroll={disableAutoScrollCallback}
                        />
                      )}
                    </div>
                  )}
                  <Tooltip
                    align={speakerBound === 'outbound' ? 'bottom right' : 'bottom left'}
                    content="Click on a timestamp to copy a link to that specific point in the transcript to your clipboard."
                  >
                    <div
                      className="slds-chat-message__meta"
                      onClick={() => copyTimeLink(sentence.startTime)}
                      tabIndex="0"
                    >
                      {messageMeta}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default Transcript;
