import { useEffect } from 'react';
import DocumentTitle from './DocumentTitle';
import { redirectToLocalhost } from '../auth';

const LocalhostRedirect = () => {
  useEffect(redirectToLocalhost, []);

  return <DocumentTitle title="SRM Calls | Localhost Redirect" />;
};

export default LocalhostRedirect;
