import { getQueryParam, removeQueryParam } from './url-utils';
import { getRequest } from './call-utils';
import axios from 'axios';

const C2FO_AUTH_URL = 'https://gateway.c2fo.com/api/auth/admin-user-auth';
const LOGIN_REDIRECT_URL_BASE = 'https://admin.c2fo.com/login?redirect-to=https:%2F%2Fcalls.c2fo-lab.com';
const LOGOUT_REDIRECT_URL = 'https://admin.c2fo.com';
const LOCALHOST_URL_BASE = 'http://localhost:3000';

const AUTH_TOKEN_QUERY_PARAM = 'token';
const AUTH_TOKEN_KEY = 'adminToken';
const USER_EMAIL_KEY = 'c2foEmail';
const USER_ROLE_KEY = 'c2foRole';

const setToken = token => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  // Axios will include this token in the headers of every request.
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setUserEmail = authResponse => localStorage.setItem(USER_EMAIL_KEY, authResponse.payload.user.emailAddress);

const setUserRole = authResponse => localStorage.setItem(USER_ROLE_KEY, authResponse.payload.user.features);

function clearAuth() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(USER_EMAIL_KEY);
}

export function redirectToLogin() {
  clearAuth();
  let hostname = LOGIN_REDIRECT_URL_BASE;
  if (window.location.hostname === 'localhost') hostname += '%2Flocalhost';
  window.location = `${hostname}${encodeURIComponent(
    window.location.pathname + location.search
  )}&tokenQueryParam=token`;
}

export function redirectToLocalhost() {
  const route = window.location.pathname.replace('/localhost', '');
  window.location = `${LOCALHOST_URL_BASE}${route}?${AUTH_TOKEN_QUERY_PARAM}=${getToken()}${location.search.replace(
    '?',
    '&'
  )}`;
}

async function verifyToken(token) {
  const response = await getRequest('/user-info', { token });
  setToken(token);
  setUserEmail(response);
  setUserRole(response);
  return true;
}

export function logout() {
  clearAuth();
  window.location = LOGOUT_REDIRECT_URL;
}

export function isLoggedIn() {
  const token = getToken();
  if (!token) return redirectToLogin();
  removeQueryParam(AUTH_TOKEN_QUERY_PARAM);
  return verifyToken(token);
}

export const getUserEmail = () => localStorage.getItem(USER_EMAIL_KEY);

export const getToken = () => getQueryParam(AUTH_TOKEN_QUERY_PARAM) || localStorage.getItem(AUTH_TOKEN_KEY);

export const checkUserRole = role => {
  if (process.env.REACT_APP_NOAUTH) return true;

  const userRoles = localStorage.getItem(USER_ROLE_KEY);
  if (userRoles) return userRoles.split(',').includes(role);
  else return false;
};

export const getUserFullName = skipFormatting => {
  const splitEmailAddress = getUserEmail().split('@')[0].split('.');
  if (skipFormatting) return splitEmailAddress.join(' ');
  else return splitEmailAddress.map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
};
