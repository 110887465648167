import { useState, useEffect } from 'react';
import { getBlockedNumbers, blockNumber, unblockNumber } from '../call-utils';
import moment from 'moment';
import DeleteConfirmation from './DeleteConfirmation';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Modal, Checkbox } from '@salesforce/design-system-react';

const BlockedNumbers = () => {
  const [blockedNumbers, setBlockedNumbers] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [hideExistingCalls, setHideExistingCalls] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const loadBlockedNumbers = async () => {
    const numbers = await getBlockedNumbers();
    setBlockedNumbers(numbers);
  };

  useEffect(() => {
    loadBlockedNumbers();
  }, [phoneNumber]);

  const handleBlockNumber = async () => {
    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      await blockNumber(phoneNumber, hideExistingCalls);
      setPhoneNumber(undefined);
      setHideExistingCalls(false);
    } else setIsAlertOpen(true);
  };

  const handleUnblockNumber = async phoneNumberId => {
    await unblockNumber(phoneNumberId);
    loadBlockedNumbers();
  };

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <main className="settings-page-container">
      <div className="settings-page">
        <div className="slds-card">
          <div className="slds-panel__header">
            <h2 className="slds-panel__header-title slds-text-heading_large slds-truncate">Blocked Numbers</h2>
            <PhoneInput
              defaultCountry="US"
              international={true}
              withCountryCallingCode={true}
              countryCallingCodeEditable={false}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
            <div className="block-number-button__container">
              <button
                className="slds-button slds-button_destructive slds-button_brand"
                type="submit"
                onClick={handleBlockNumber}
              >
                Block&nbsp;Number
              </button>
              <Checkbox
                labels={{ label: 'Hide Existing Calls' }}
                checked={hideExistingCalls}
                onChange={e => setHideExistingCalls(e.target.checked)}
              />
            </div>
          </div>

          <div className="slds-panel__body">
            {blockedNumbers.length > 0 && (
              <table className="slds-table slds-table_cell-buffer slds-table_bordered ">
                <thead>
                  <tr className="slds-line-height_reset">
                    <th scope="col">
                      <div className="slds-truncate">Blocked Number</div>
                    </th>
                    <th scope="col" colSpan="1">
                      <div className="slds-truncate">Blocked By</div>
                    </th>
                    <th scope="col" colSpan="2">
                      <div className="slds-truncate">Date</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {blockedNumbers.map((row, index) => (
                    <tr className="slds-hint-parent" key={row.phoneNumber + index}>
                      <td width="200">
                        <div className="slds-truncate">{row.phoneNumber}</div>
                      </td>
                      <td width="200">
                        <div className="slds-truncate">{row.createdBy}</div>
                      </td>
                      <td width="200">
                        <div className="slds-truncate">{moment.utc(row.created).format('MMM Do YYYY')}</div>
                      </td>
                      <td data-label="Contact" width="50">
                        <div className="slds-truncate">
                          <DeleteConfirmation
                            onDelete={() => handleUnblockNumber(row.id)}
                            body="Are you sure you want to unblock this number?"
                            heading="Unblock Number"
                            clearType
                            buttonVariant="brand"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Modal
          isOpen={isAlertOpen}
          onRequestClose={handleCloseAlert}
          size="small"
          heading="Invalid Phone Number"
          prompt="error"
        >
          Please recheck the number and try again.
        </Modal>
      </div>
    </main>
  );
};

export default BlockedNumbers;
