import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { updateLanguage, updateAccount, getCall, transformCallData, getTagList } from '../call-utils';
import { getQueryParam } from '../url-utils';

import DocumentTitle from './DocumentTitle';
import CallCard from './CallCard';

const SingleCall = () => {
  const { recordingId } = useParams();
  const [call, setCall] = useState();
  const [tagList, setTagList] = useState();

  useEffect(() => {
    loadCall();
    loadTagList();
  }, []);

  const loadCall = async () => {
    const callData = (await getCall(recordingId))[0];
    const transformedCallData = transformCallData({ ...callData });
    setCall(transformedCallData);
  };

  const loadTagList = async () => {
    const tags = await getTagList();
    setTagList(tags);
  };

  const handleLanguageUpdate = async (_, callLanguage) => {
    const languageUpdateResponse = await updateLanguage(recordingId, callLanguage);
    if (languageUpdateResponse) setCall({ ...call, callLanguage });
  };

  const handleAccountUpdate = async (_, accountId, accountName) => {
    const accountUpdateResponse = await updateAccount(recordingId, accountId, accountName);
    if (accountUpdateResponse) setCall({ ...call, relatedAccounts: [{ accountId, accountName }] });
  };

  const handleTagUpdate = async (_, tagId, checked) => {
    if (checked) {
      if (!call.tags) call.tags = [tagId];
      else call.tags.push(tagId);
    } else call.tags.splice(call.tags.indexOf(tagId), 1);

    setCall({ ...call });
  };

  return (
    <DocumentTitle title={`SRM Calls | ${recordingId}`}>
      <section className="slds-grid slds-wrap single-call">
        {call && (
          <CallCard
            call={call}
            singleCall
            time={getQueryParam('time')}
            updateLanguageCallback={handleLanguageUpdate}
            updateAccountCallback={handleAccountUpdate}
            toggleTagCallback={handleTagUpdate}
            tagList={tagList}
          />
        )}
      </section>
    </DocumentTitle>
  );
};

export default SingleCall;
