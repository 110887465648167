export default function parseFilters(filter) {
  const updatedFilter = filter;
  if (typeof updatedFilter.minAudioLength === 'string')
    updatedFilter.minAudioLength = Number(updatedFilter.minAudioLength);
  if (typeof updatedFilter.dualChannel === 'string') updatedFilter.dualChannel = updatedFilter.dualChannel === 'true';
  if (typeof updatedFilter.hideVoicemails === 'string')
    updatedFilter.hideVoicemails = updatedFilter.hideVoicemails === 'true';
  if (typeof updatedFilter.hideUnknownSuppliers === 'string')
    updatedFilter.hideUnknownSuppliers = updatedFilter.hideUnknownSuppliers === 'true';
  if (typeof updatedFilter.hideUntranscribed === 'string')
    updatedFilter.hideUntranscribed = updatedFilter.hideUntranscribed === 'true';
  if (typeof updatedFilter.selectedSrmTeamMembers === 'string')
    updatedFilter.selectedSrmTeamMembers = [updatedFilter.selectedSrmTeamMembers];
  if (typeof updatedFilter.selectedTags === 'string') updatedFilter.selectedTags = [updatedFilter.selectedTags];
  return updatedFilter;
}

export const formatAnswerStatus = answerStatus => {
  switch (answerStatus) {
    case 'ANSWERED':
      return 'Answered';
    case 'BUSY':
      return 'Busy';
    case 'CHANUNAVAIL':
      return 'Unavailable/Invalid';
    case 'CONGESTION':
      return 'Congestion';
    case 'NOANSWER':
      return 'No Answer';
    default:
      return null;
  }
};
