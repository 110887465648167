import { useEffect, useState } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const QAGraph = ({ QAGraphData, teamName }) => {
  const [chartOptions, setOptions] = useState([]);

  // If there's a teamname, create a team breakdown chart
  // Otherwise, create a general QA Metrics chart
  useEffect(() => {
    teamName ? createTeamBreakdownSeries() : createMetricsSeries();
  }, [QAGraphData]);

  const createDateList = () => {
    let dates = new Set();

    QAGraphData.forEach(graphData => dates.add(moment(graphData.date).format('l')));
    return Array.from(dates);
  };

  const createTeamBreakdownSeries = () => {
    const teamMembers = Object.keys(QAGraphData[0]).filter(x => x != 'date');

    const series = teamMembers.map(srmName => {
      let scores = [];
      QAGraphData.forEach(call => {
        scores.push(call[srmName]);
      });
      return { name: srmName, data: scores };
    });

    saveOptions(series, createDateList(), `QA Team Breakdown (${teamName})`);
  };

  const createMetricsSeries = () => {
    const sections = [
      { name: 'overallScore', label: 'Overall Score' },
      { name: 'prepScore', label: 'Prep Score' },
      { name: 'exploreScore', label: 'Explore Score' },
      { name: 'demoScore', label: 'Demo Score' },
      { name: 'closingScore', label: 'Closing Score' },
    ];

    const series = sections.map(section => {
      let scores = [];
      QAGraphData.forEach(callData => {
        const score = callData[section.name] ? JSON.parse(callData[section.name]) * 100 : callData[section.name];
        scores.push(score);
      });
      return {
        name: section.label,
        data: scores,
        type: section.name === 'overallScore' ? 'area' : null,
        step: section.name === 'overallScore' ? 'step' : null,
      };
    });

    saveOptions(series, createDateList(), 'QA Metrics');
  };

  const saveOptions = (series, dates, title) => {
    const options = {
      chart: {
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 21.5,
      },
      title: {
        text: title,
      },
      xAxis: {
        title: { text: 'Date' },
        categories: dates,
      },
      yAxis: {
        title: { enabled: false },
        max: 100,
      },
      series: series,
    };

    setOptions(options);
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default QAGraph;
