import { getQATemplates, createQATemplate, hideQATemplate, updateQATemplate } from '../call-utils';
import { useState, useEffect } from 'react';
import { Button, Modal, Input, Toast, ToastContainer } from '@salesforce/design-system-react';
import DeleteConfirmation from './DeleteConfirmation';
import moment from 'moment';

const TEMPLATE_NAME_ERROR_TEXT = 'Template Name is required.';
const SECTION_ERROR_TEXT = 'Section Name is required.';
const QUESTION_ERROR_TEXT = 'Question text is required.';

const QATemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editTemplateId, setEditTemplateId] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [templateNameError, setTemplateNameError] = useState('');
  const [sections, setSections] = useState([{ sectionName: '', errorText: '' }]);
  const [subitems, setSubitems] = useState([[{ questionText: '', optionalText: '', errorText: '' }]]);
  const [errorToastText, setErrorToastText] = useState('');

  const loadQATemplates = async () => {
    const qaTemplates = await getQATemplates();
    setTemplates(qaTemplates);
  };

  const assembleTemplate = () => {
    const template = sections.map((section, i) => {
      const templateSubitems = subitems[i].map((subitem, j) => ({
        id: `${i + 1}-${j + 1}`,
        summary: subitem.questionText,
        details: subitem.optionalText,
      }));
      return {
        id: (i + 1).toString(),
        summary: section.sectionName,
        subitems: templateSubitems,
      };
    });

    return template;
  };

  const publishTemplate = async () => {
    const isValid = await validateForm();
    if (isValid) {
      const template = assembleTemplate();
      let response;

      isEdit
        ? (response = await updateQATemplate(editTemplateId, templateName, template))
        : (response = await createQATemplate(templateName, template));
      if (response.message) {
        setErrorToastText(response.message);
      } else {
        setOpenAddTemplate(false);
        setEditTemplateId(null);
        setIsEdit(false);
        loadQATemplates();
      }
    }
  };

  useEffect(() => {
    if (!openAddTemplate) {
      // Reset form state when closing modal
      setTemplateName('');
      setTemplateNameError('');
      setIsEdit(false);
      setSections([{ sectionName: '', errorText: '' }]);
      setSubitems([[{ questionText: '', optionalText: '', errorText: '' }]]);
    }
  }, [openAddTemplate]);

  const validateForm = async () => {
    let isValid = true;

    if (templateName === '') {
      setTemplateNameError(TEMPLATE_NAME_ERROR_TEXT);
      isValid = false;
    } else {
      setTemplateNameError('');
    }

    const tempSections = [...sections];
    tempSections.forEach(section => {
      if (section.sectionName === '') {
        section.errorText = SECTION_ERROR_TEXT;
        isValid = false;
      } else {
        section.errorText = '';
      }
    });
    setSections(tempSections);

    const tempSubitems = [...subitems];
    tempSubitems.forEach(subitemList => {
      subitemList.forEach(subitem => {
        if (subitem.questionText === '') {
          subitem.errorText = QUESTION_ERROR_TEXT;
          isValid = false;
        } else {
          subitem.errorText = '';
        }
      });
    });
    setSubitems(tempSubitems);

    return isValid;
  };

  const hideTemplate = async templateId => {
    await hideQATemplate(templateId);
    loadQATemplates();
  };

  const handleUpdateTemplate = (template, name, id) => {
    const formattedSections = template.map(section => {
      return { sectionName: section.summary };
    });

    const formattedSubitems = template.map(section => {
      let list = [];
      [...section.subitems].forEach(subitem =>
        list.push({ questionText: subitem.summary, optionalText: subitem.details })
      );

      return list;
    });

    setTemplateName(name);
    setSections([...formattedSections]);
    setSubitems([...formattedSubitems]);
    setEditTemplateId(id);
    setIsEdit(true);
    setOpenAddTemplate(true);
  };

  useEffect(() => {
    loadQATemplates();
  }, []);

  const addTemplateSection = () => {
    setSections([...sections, { sectionName: '', errorText: '' }]);
    let tempSubitems = [...subitems];
    tempSubitems.push(new Array({ questionText: '', optionalText: '' }));
    setSubitems([...tempSubitems]);
  };

  const removeTemplateSection = i => {
    let tempSections = [...sections];
    let tempSubitems = [...subitems];

    tempSections.splice(i, 1);
    tempSubitems.splice(i, 1);

    setSections([...tempSections]);
    setSubitems([...tempSubitems]);
  };

  const removeSectionSubitem = (section, i) => {
    let tempSubitems = [...subitems];
    tempSubitems[section].splice(i, 1);

    setSubitems([...tempSubitems]);
  };

  const addSectionSubitem = i => {
    let tempSubitems = [...subitems];
    const newSubitem = { questionText: '', optionalText: '', errorText: '' };

    if (subitems[i]) {
      let updatedSubitems = subitems[i];
      updatedSubitems.push(newSubitem);
      tempSubitems[i] = updatedSubitems;
    } else {
      tempSubitems[i] = new Array(newSubitem);
    }

    setSubitems([...tempSubitems]);
  };

  const updateField = (type, text, i, subIndex) => {
    let tempSections;
    let tempSubitems;
    if (type === 'section') {
      tempSections = [...sections];
      tempSections[i].sectionName = text;
      setSections([...tempSections]);
      return;
    } else {
      tempSubitems = [...subitems];
    }

    if (type === 'subitemOptionalText') {
      tempSubitems[i][subIndex].optionalText = text;
    }
    if (type === 'subitemQuestion') {
      tempSubitems[i][subIndex].questionText = text;
    }
    setSubitems([...tempSubitems]);
  };

  return (
    <main className="settings-page-container">
      <div className="settings-page">
        <div className="slds-card">
          <div className="slds-panel__header">
            <h2 className="slds-panel__header-title slds-text-heading_large slds-truncate">QA Templates</h2>
            <button className="slds-button slds-button_brand" type="submit" onClick={() => setOpenAddTemplate(true)}>
              Add&nbsp;Template
            </button>
          </div>

          <div className="slds-panel__body">
            {templates.length > 0 && (
              <table className="slds-table slds-table_cell-buffer slds-table_bordered ">
                <thead>
                  <tr className="slds-line-height_reset">
                    <th scope="col">
                      <div className="slds-truncate">Name</div>
                    </th>
                    <th scope="col" colSpan="1">
                      <div className="slds-truncate">Created By</div>
                    </th>
                    <th scope="col" colSpan="2">
                      <div className="slds-truncate">Date</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((row, index) => (
                    <tr className="slds-hint-parent" key={row.name + index}>
                      <td width="200">
                        <div className="slds-truncate">
                          {row.name} (v{row.version})
                        </div>
                      </td>
                      <td width="200">
                        <div className="slds-truncate">{row.author}</div>
                      </td>
                      <td width="200">
                        <div className="slds-truncate">{moment.utc(row.created).format('MMM Do YYYY')}</div>
                      </td>
                      <td width="50">
                        <div className="slds-truncate">
                          <Button
                            iconCategory="utility"
                            iconName="edit"
                            variant="brand"
                            onClick={() => handleUpdateTemplate(row.template, row.name, row.id)}
                            className="slds-m-right_x-small slds-button-utility"
                          />
                          <DeleteConfirmation
                            onDelete={() => hideTemplate(row.id)}
                            body="Are you sure you want to hide this QA template? It will not appear in the list, although existing QAs will still be correctly associated with it."
                            heading="Hide Template"
                            buttonVariant="destructive"
                            hideType
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Modal
          className="template-form_modal"
          size="medium"
          isOpen={openAddTemplate}
          onRequestClose={() => setOpenAddTemplate(false)}
          heading="Add QA Template"
          align="top"
          directional
          footer={
            <div className="slds-m-top_medium">
              <Button label="Add Section" onClick={addTemplateSection} />
              <Button label="Publish Template" onClick={publishTemplate} variant="brand" />
            </div>
          }
        >
          <form>
            <div className="template-form-name">
              <Input
                placeholder="e.g. EMEA Strategy"
                label="Template Name"
                value={templateName}
                required
                errorText={templateNameError}
                readOnly={isEdit}
                onChange={e => setTemplateName(e.target.value)}
              />
            </div>
            <div className="slds-col">
              {sections.length > 0 &&
                sections.map((section, i) => {
                  return (
                    <div className="template-form-section" key={i}>
                      <div className="template-section-name">
                        <Input
                          value={section.sectionName}
                          required
                          errorText={section.errorText}
                          placeholder={'Section Name (e.g. Quick Prep)'}
                          onChange={e => updateField('section', e.target.value, i)}
                        />
                        {sections.length > 1 && (
                          <Button
                            iconCategory="utility"
                            iconName="clear"
                            variant="brand"
                            onClick={() => removeTemplateSection(i)}
                            className="slds-button-utility add-subitem-button"
                          />
                        )}
                      </div>
                      <ul className="subitems-list">
                        {subitems[i] &&
                          subitems[i].length > 0 &&
                          subitems[i].map((item, subIndex) => {
                            return (
                              <li key={`${i}-${subIndex}`}>
                                <Input
                                  className="subitem-field"
                                  value={item.questionText}
                                  required
                                  errorText={item.errorText}
                                  placeholder={'Example Question'}
                                  onChange={e => updateField('subitemQuestion', e.target.value, i, subIndex)}
                                />
                                <Input
                                  className="subitem-field"
                                  value={item.optionalText}
                                  placeholder={'Optional Helper Text'}
                                  onChange={e => updateField('subitemOptionalText', e.target.value, i, subIndex)}
                                />
                                <div className="clear-subitem-container">
                                  {subitems[i].length > 1 && (
                                    <Button
                                      iconCategory="utility"
                                      iconName="clear"
                                      variant="neutral"
                                      onClick={() => removeSectionSubitem(i, subIndex)}
                                      className="slds-button-utility"
                                    />
                                  )}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                      <Button
                        iconCategory="utility"
                        iconName="add"
                        variant="neutral"
                        onClick={() => addSectionSubitem(i)}
                        className="slds-button-utility add-subitem-button"
                      />
                    </div>
                  );
                })}
            </div>
          </form>
          <ToastContainer>
            {errorToastText !== '' && (
              <Toast
                duration={5000}
                labels={{ heading: errorToastText }}
                variant="error"
                onRequestClose={() => setErrorToastText('')}
              />
            )}
          </ToastContainer>
        </Modal>
      </div>
    </main>
  );
};
export default QATemplates;
