import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Badge,
  Button,
  Input,
  Textarea,
  Popover,
  Modal,
  ScopedNotification,
  RadioButtonGroup,
  Radio,
  ToastContainer,
  Toast,
  Tooltip,
  Accordion,
  AccordionPanel,
} from '@salesforce/design-system-react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { find, findIndex, throttle, sortBy, isEmpty, sum, round } from 'lodash';
import { useMap } from 'react-use';

import {
  acknowledgeCallQA,
  addCallQA,
  addCallToLibrary,
  flattenTagList,
  getBadgeClassName,
  getScoreButtonClassName,
  getCall,
  getCallQAData,
  getLibraryAnnotations,
  getQaTemplate,
  getSentenceIndexByTime,
  getTagList,
  removeCallQA,
  transformCallData,
  updateTranscript,
  updateLibraryAnnotations,
} from '../call-utils';
import { getQueryParam } from '../url-utils';
import { useInterval } from '../hooks';
import { checkUserRole, getUserFullName } from '../auth';

import DocumentTitle from './DocumentTitle';
import WavesurferPlayer from './WavesurferPlayer';
import DeleteConfirmation from './DeleteConfirmation';
import Transcript from './Transcript';
import AggregatedScoreGauge from './AggregatedScoreGauge';

momentDurationFormatSetup(moment);

const buttonGroupLabel = { label: 'Feedback Type' };
const scores = [
  {
    label: 'Needs Improvement',
    value: 0,
  },
  {
    label: 'Neutral',
    value: 0.5,
  },
  {
    label: 'Successful',
    value: 1,
  },
];

const scoreConversion = {};
scores.forEach(score => (scoreConversion[score.label] = score.value));

const Annotations = ({ regionDetails, onDelete, onUpdate, isSelected, annotationType, isModifying }) => {
  const [start, setStartTime] = useState(regionDetails.start);
  const [end, setEndTime] = useState(regionDetails.end);
  const [note, setNote] = useState(regionDetails.data.note);
  const [valueChanged, setValueChanged] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startTimeError, setStartTimeError] = useState();
  const [endTimeError, setEndTimeError] = useState();
  const [feedbackType, setFeedbackType] = useState();

  const handleSubmit = event => {
    event.preventDefault();
    setIsModalOpen(false);
    onUpdate({
      id: regionDetails.id,
      start,
      end,
      data: {
        note,
        feedbackType,
      },
    });
  };

  const handleModalClose = () => {
    if (regionDetails.newRegion) onDelete(regionDetails);
    setIsModalOpen(false);
  };

  const handleEditButtonClick = () => {
    resetDetails();
    setIsModalOpen(true);
  };

  const handleButtonGroupClick = event => setFeedbackType(event.target.value);

  const handleFormChange = (event, value) => {
    switch (event) {
      case 'start':
        setStartTime(getTimeInSeconds(value));
        break;
      case 'end':
        setEndTime(getTimeInSeconds(value));
        break;
      case 'note':
        setNote(value);
        break;
      case 'feedbackType':
        setFeedbackType(value, 'feedbackType');
        break;
      default:
        break;
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const getTimeInSeconds = timeString => {
    const timestampRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/gm;
    if (timeString.match(timestampRegex)) {
      const values = timeString.split(':');
      if (values.length === 2) return parseInt(values[0], 10) * 60 + parseInt(values[1], 10);
      else if (values.length === 3)
        return parseInt(values[0], 10) * 3600 + parseInt(values[1], 10) * 60 + parseInt(values[2], 10);
    }
  };

  const validateForm = () => {
    setValueChanged(false);
    setFormValid(true);
    setStartTimeError('');
    setEndTimeError('');
    if (
      start !== regionDetails.start ||
      end !== regionDetails.end ||
      note !== regionDetails.data.note ||
      feedbackType !== regionDetails.data.feedbackType
    )
      setValueChanged(true);
    if (start === undefined) {
      setFormValid(false);
      setStartTimeError('Please enter a time in hh:mm:ss format');
    } else if (end === undefined) {
      setFormValid(false);
      setEndTimeError('Please enter a time in hh:mm:ss format');
    } else if (start > end) {
      setFormValid(false);
      setStartTimeError('End time should be less than start time');
      setEndTimeError('End time should be less than start time');
    }
  };

  const resetDetails = () => {
    setStartTime(regionDetails.start);
    setEndTime(regionDetails.end);
    setNote(regionDetails.data.note);
    setFeedbackType(regionDetails.data.feedbackType || 'Neutral');
  };

  useEffect(() => {
    validateForm();
  }, [start, end, feedbackType, note]);

  useEffect(() => {
    resetDetails();
    if (regionDetails && regionDetails.newRegion) setIsModalOpen(true);
  }, [regionDetails]);

  return (
    <div
      className={`slds-item slds-p-around_small annotation-card ${isSelected ? 'selected' : ''} ${
        regionDetails.unsubmitted ? 'unsubmitted-annotation' : ''
      }`}
      id={`annotation-card-${regionDetails.id}`}
    >
      <article className="slds-tile slds-tile_board">
        <div className="slds-tile__detail">
          {annotationType === 'QA' && (
            <div className="slds-p-bottom_x-small">
              <Badge className={getBadgeClassName(feedbackType)} content={feedbackType} />
            </div>
          )}
          <p className="slds-text-heading_small annotation-text">{note || 'N/A'}</p>
          {regionDetails.unsubmitted && <p className="unsubmitted-marker">New</p>}
          <div className="slds-grid">
            <div className="slds-col slds-size_1-of-2">
              <p className="annotation-time slds-p-top_xx-small">
                {moment.duration(regionDetails.start, 'seconds').format('h:mm:ss', { stopTrim: 'm' })} →{' '}
                {moment.duration(regionDetails.end, 'seconds').format('h:mm:ss', { stopTrim: 'm' })}
              </p>
            </div>
            {isModifying && (
              <div className="slds-col slds-size_1-of-2 slds-p-top_xx-small slds-text-align_right">
                <Button
                  iconCategory="utility"
                  iconName="edit"
                  onClick={handleEditButtonClick}
                  variant="brand"
                  className="slds-m-right_x-small slds-button-utility"
                />
                <DeleteConfirmation
                  onDelete={() => onDelete(regionDetails)}
                  body="Are you sure you want to delete this annotation?"
                  heading="Delete Annotation"
                  buttonVariant="destructive"
                />
              </div>
            )}
          </div>
        </div>
      </article>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        heading={`${regionDetails.newRegion ? 'Add' : 'Edit'} Annotation`}
        className="add-keyword-group-modal"
      >
        <section className="slds-p-around_large">
          <form onSubmit={handleSubmit}>
            <div className="slds-grid slds-wrap slds-gutters">
              {annotationType === 'QA' && (
                <div className="feedback-button-group slds-col">
                  <RadioButtonGroup labels={buttonGroupLabel} onChange={handleButtonGroupClick}>
                    {scores.map(score => (
                      <Radio
                        key={score.label}
                        id={score.label}
                        labels={{ label: score.label }}
                        value={score.label}
                        checked={feedbackType === score.label}
                        variant="button-group"
                      />
                    ))}
                  </RadioButtonGroup>
                </div>
              )}
              <div className="slds-col slds-size_2-of-2">
                <label className="slds-form-element__label" htmlFor={`annotation-note-${regionDetails.id}`}>
                  Note
                </label>
                <Textarea
                  className="annotation-note-input"
                  id={`annotation-note-${regionDetails.id}`}
                  name="note"
                  value={note}
                  required
                  onChange={e => handleFormChange('note', e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="slds-col slds-size_1-of-2">
                <label className="slds-form-element__label" htmlFor={`annotation-start_time-${regionDetails.id}`}>
                  Start Time
                </label>
                <Input
                  id={`annotation-start_time-${regionDetails.id}`}
                  placeholder="Start"
                  type="text"
                  required
                  errorText={startTimeError}
                  defaultValue={moment.duration(start, 'seconds').format('h:mm:ss', { stopTrim: 'm' })}
                  onChange={(e, data) => handleFormChange('start', data.value)}
                />
              </div>
              <div className="slds-col slds-size_1-of-2">
                <label className="slds-form-element__label" htmlFor={`annotation-end_time-${regionDetails.id}`}>
                  End Time
                </label>
                <Input
                  id={`annotation-end_time-${regionDetails.id}`}
                  placeholder="End"
                  type="text"
                  required
                  errorText={endTimeError}
                  defaultValue={moment.duration(end, 'seconds').format('h:mm:ss', { stopTrim: 'm' })}
                  onChange={(e, data) => handleFormChange('end', data.value)}
                />
              </div>
            </div>
            <div className="slds-m-top_medium">
              <Button type="submit" label="Save Annotation" variant="brand" disabled={!valueChanged || !formValid} />
            </div>
          </form>
        </section>
      </Modal>
    </div>
  );
};

const AnnotateCall = ({ annotationType, isModifying }) => {
  const history = useHistory();
  const { recordingId } = useParams();

  const [call, setCall] = useState();
  const [audioTime, setAudioTime] = useState(getQueryParam('time') || 0);
  const [forceUpdateAudio, setForceUpdateAudio] = useState(false);

  const [localRegions, setRegions] = useState();
  const [selectedRegion, setSelectedRegion] = useState();

  const libraryPermission = annotationType === 'library' && checkUserRole('lab_prototypes');
  const qaPermission = checkUserRole('call_transcriber_review');
  const [authorPermission, setAuthorPermission] = useState(false);
  const [srmPermission, setSrmPermission] = useState(false);

  const [aggregatedScore, setAggregatedScore] = useState(null);
  const [scoreCount, { set: setScoreCount }] = useMap({ 'Needs Improvement': 0, Neutral: 0, Successful: 0 });
  const [
    calibrationFormData,
    { set: setCalibrationFormData, setAll: setAllCalibrationFormData, remove: clearCalibrationFormData },
  ] = useMap({});

  const [qaTemplate, setQaTemplate] = useState(); //{name:null, version:null, template: null}

  const [transcriptSettingOptions] = useState([
    { label: 'Download Audio', value: 'downloadAudio' },
    { type: 'divider' },
    { label: 'Disable Auto-Scroll', value: 'autoScroll' },
    { type: 'divider' },
    { label: 'Enable Edit Mode', value: 'editMode' },
  ]);
  const [transcript, setTranscript] = useState();

  const [isAudioReady, setIsAudioReady] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const [autoScroll, setAutoScroll] = useState(false);
  const [pauseAutoScroll, setPauseAutoScroll] = useState(true);
  const [disableAutoScrollTimeout, setDisableAutoScrollTimeout] = useState(null);
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(true);

  const [isInLibrary, setIsInLibrary] = useState(false);
  const [isQAed, setIsQAed] = useState(false);
  const [showQAButtons, setShowQAButtons] = useState(false);

  const [acknowledgedDate, setAcknowledgedDate] = useState();
  const [annotationAuthor, setAnnotationAuthor] = useState();
  const [annotationTimestamp, setAnnotationTimestamp] = useState();

  const [newAnnotationsAdded, setNewAnnotationsAdded] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState();

  const [editTranscriptMode, setEditTranscriptMode] = useState(false);
  const [splitChannel, setSplitChannel] = useState(true);

  const [tagList, setTagList] = useState();
  const flatTagList = useMemo(() => (tagList ? flattenTagList(tagList) : []), [tagList]);

  const loadTagList = async () => {
    const tags = await getTagList();
    setTagList(tags);
  };

  useEffect(() => {
    enableAutoScroll();
    loadCall();
    loadTagList();
  }, []);

  useEffect(() => {
    if (selectedRegion) scrollToAnnotation();
  }, [selectedRegion]);

  // Implements auto-scrolling behavior with an interval hook
  useInterval(() => {
    if (isAudioPlaying && autoScroll && !pauseAutoScroll) goToCurrentSentence();
  }, 250);

  // Set aggregated score when the calibration form changes
  useEffect(() => {
    const scoreList = Object.keys(calibrationFormData).map(i => scoreConversion[calibrationFormData[i].score]);
    const finalScore = sum(scoreList) / scoreList.length;
    setAggregatedScore(finalScore);
  }, [calibrationFormData]);

  const loadQaTemplate = async () => {
    let templateData = await getQaTemplate(recordingId);
    templateData = templateData[0];
    setQaTemplate({
      id: templateData.templateId,
      name: templateData.name,
      version: templateData.version,
      template: templateData.template,
    });
  };

  useEffect(() => {
    if (!isQAed && qaPermission && showQAButtons && !qaTemplate) {
      loadQaTemplate();
    }
  }, [showQAButtons]);

  const setSplitChannelOption = dualChannel => {
    if (!dualChannel) return;

    const splitChannelOption = {
      label: 'Disable Split Channel',
      value: 'splitChannel',
    };

    // Grab splitChannel value from localStorage
    const storedSplitChannelValue = localStorage.splitChannel && JSON.parse(localStorage.splitChannel);
    if (storedSplitChannelValue !== undefined) {
      // Update the splitChannel value in state, based on the localStorage value
      setSplitChannel(storedSplitChannelValue);

      // If the splitChannel value from localStorage was false, switch the label
      if (!storedSplitChannelValue) splitChannelOption.label = 'Enable Split Channel';
    }

    transcriptSettingOptions.push({ type: 'divider' }, splitChannelOption);
  };

  // Functions for Transcript
  const goToCurrentSentence = currentTime => {
    if (!transcript || (!currentTime && !isAudioReady)) return;
    else if (!currentTime && isAudioReady) currentTime = audioTime;

    const sentenceIndex = getSentenceIndexByTime(transcript, currentTime);
    const sentenceEl = document.getElementById(`${call.callId}-sentence-${sentenceIndex}`);
    if (!sentenceEl) return;

    let additionalOffset = splitChannel && call.rawAudioLength < 1800 ? 468 : 340;
    if (annotationTimestamp) additionalOffset += 20;

    sentenceEl.parentElement.parentElement.scrollTop = sentenceEl.offsetTop - additionalOffset;
    setIgnoreScrollEvent(true);
  };

  const setCallTime = callTime => {
    goToCurrentSentence(callTime);
    setAudioTime(callTime);
    setForceUpdateAudio(true);
  };

  const editTranscript = (index, updatedUtterance) => {
    const originalUtterance = transcript[index];

    if (updatedUtterance.message.length === 0) transcript.splice(index, 1);
    else transcript[index] = updatedUtterance;
    setTranscript([...transcript]);
    updateTranscript(transcript, recordingId);

    if (updatedUtterance.message.includes('?') || originalUtterance.message.includes('?'))
      recalculateQuestionCount(transcript);
  };

  const recalculateQuestionCount = updatedTranscript => {
    let newQuestionCount = 0;
    updatedTranscript.forEach(utterance => {
      if (utterance.speakerTag === 2 && utterance.message.includes('?')) newQuestionCount++;
    });
    call.questionCount = newQuestionCount;
  };

  const disableAutoScroll = () => {
    find(transcriptSettingOptions, ['value', 'autoScroll']).label = 'Enable Auto-Scroll';
    setAutoScroll(false);
  };

  //  End Functions for Transcript

  const loadRegions = () => {
    const regions = getLocalRegions();
    setRegions(regions);
  };

  useEffect(() => {
    if (!isModifying && annotationType === 'library' && localRegions?.length) {
      setCallTime(localRegions[0].start);
      setSelectedRegion(localRegions[0]);
    }
  }, [localRegions]);

  const getLocalRegions = () => {
    if (localStorage[`localRegions-${annotationType}-${recordingId}`])
      return JSON.parse(localStorage[`localRegions-${annotationType}-${recordingId}`]);
    else return [];
  };

  const loadCall = async () => {
    const callData = (await getCall(recordingId))[0];
    const transformedCallData = transformCallData(callData);
    setCall(transformedCallData);
    setTranscript(transformedCallData.transcript);
    setSplitChannelOption(transformedCallData.dualChannel);
    setIsInLibrary(transformedCallData.isInLibrary);
    setIsQAed(transformedCallData.isQAed);

    if (transformedCallData.isInLibrary && annotationType === 'library') loadLibraryAnnotations();
    else if (transformedCallData.isQAed && annotationType === 'QA') {
      loadQAAnnotations();
      if (transformedCallData.srmName.toLowerCase() === getUserFullName(true)) setSrmPermission(true);
    } else {
      if (annotationType === 'QA') setShowQAButtons(true);
      if (isModifying) {
        const regions = getLocalRegions();
        setRegions(regions);

        // Loading WIP QA annotations from localStorage should enable the Submit QA button
        if (regions.length && annotationType === 'QA') setNewAnnotationsAdded(true);
      }
    }
  };

  const loadLibraryAnnotations = async () => {
    const annotationList = await getLibraryAnnotations(recordingId);
    const formattedAnnotationList = annotationList.notes.map(annotation => ({
      id: annotation.id,
      start: annotation.startTime,
      end: annotation.endTime,
      data: {
        note: annotation.message,
      },
    }));

    const regionList = getLocalRegions();
    if (regionList.length && isModifying) {
      // If localStorage regions for this library entry exist, inspect them for any unsubmitted changes
      const unsubmittedRegions = regionList.filter(localRegion => localRegion.unsubmitted);
      if (unsubmittedRegions.length) {
        setNewAnnotationsAdded(true);
        // Loop through unsubmittedRegions, replacing matching regions by id in formattedAnnotationList, appending new regions
        unsubmittedRegions.map(unsubmittedRegion => {
          const originalRegionIndex = findIndex(
            formattedAnnotationList,
            regionDetails => regionDetails.id === unsubmittedRegion.id
          );
          if (originalRegionIndex >= 0) formattedAnnotationList[originalRegionIndex] = unsubmittedRegion;
          else formattedAnnotationList.push(unsubmittedRegion);
        });
      }
    }

    if (isModifying) {
      updateLocalStorageRegions(formattedAnnotationList);
      loadRegions();
    } else setRegions(formattedAnnotationList);
  };

  const loadQAAnnotations = async () => {
    const annotationList = await getCallQAData(recordingId);
    const formattedAnnotationList = annotationList.notes.map(annotation => ({
      id: annotation.id,
      start: annotation.startTime,
      end: annotation.endTime,
      data: {
        note: annotation.message,
        feedbackType: annotation.feedbackType,
      },
    }));
    setRegions(formattedAnnotationList);

    if (annotationList.author) {
      setAnnotationAuthor(annotationList.authorName);
      if (annotationList.authorName.toLowerCase() === getUserFullName(true)) setAuthorPermission(true);
    }
    if (annotationList.created) setAnnotationTimestamp(moment(annotationList.created).format('LLL'));
    if (annotationList.acknowledgedDate) setAcknowledgedDate(moment(annotationList.acknowledgedDate).format('LLL'));
    setShowQAButtons(true);

    if (annotationList.calibrationFormData) setAllCalibrationFormData(annotationList.calibrationFormData);

    setAggregatedScore(annotationList.aggregatedScore);

    const scoreList = Object.keys(annotationList.calibrationFormData).map(
      i => annotationList.calibrationFormData[i].score
    );

    scoreList.forEach(score => {
      setScoreCount(score, (scoreCount[score] += 1));
    });

    if (annotationList.qaTemplate) {
      setQaTemplate({
        name: annotationList.qaTemplateName,
        version: annotationList.qaTemplateVersion,
        template: annotationList.qaTemplate,
      });
    }
  };

  const handleWavesurferEvents = (event, time) => {
    if (event === 'play' || event === 'pause') setIsAudioPlaying(event === 'play');
    if (event === 'ready') setIsAudioReady(true);
    if (event === 'audioprocess') handleWavesurferAudioProcessThrottled(time);
    if (event === 'seek') {
      setAudioTime(time);
      setForceUpdateAudio(false);
      if (!selectedRegion || (selectedRegion && (time < selectedRegion.start || time > selectedRegion.end)))
        setSelectedRegion();
      if (isAudioReady && !isAudioPlaying) goToCurrentSentence(time);
    }
  };

  const handleWavesurferAudioProcess = newAudioTime => setAudioTime(newAudioTime);
  const handleWavesurferAudioProcessThrottled = useCallback(throttle(handleWavesurferAudioProcess, 500), []);

  const toggleEditMode = () => {
    find(transcriptSettingOptions, ['value', 'editMode']).label = editTranscriptMode
      ? 'Enable Edit Mode'
      : 'Disable Edit Mode';
    setEditTranscriptMode(!editTranscriptMode);
  };
  const toggleAutoScroll = () => (autoScroll ? disableAutoScroll() : enableAutoScroll());
  const enableAutoScroll = () => {
    find(transcriptSettingOptions, ['value', 'autoScroll']).label = 'Disable Auto-Scroll';
    setAutoScroll(true);
    setPauseAutoScroll(false);
  };

  const temporarilyDisableAutoScroll = () => {
    if (autoScroll && !ignoreScrollEvent) {
      if (pauseAutoScroll) clearTimeout(disableAutoScrollTimeout);
      setPauseAutoScroll(true);
      setDisableAutoScrollTimeout(setTimeout(enableAutoScroll, 5000));
    } else if (ignoreScrollEvent) setIgnoreScrollEvent(false);
  };

  const handleSettingChange = setting => {
    switch (setting.value) {
      case 'autoScroll':
        toggleAutoScroll();
        break;
      case 'editMode':
        toggleEditMode();
        break;
      case 'splitChannel':
        toggleSplitChannel();
        break;
      case 'downloadAudio':
        downloadAudio();
        break;
      default:
        break;
    }
  };

  const downloadAudio = () => {
    const link = document.createElement('a');
    link.href = call.audioSrc;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleSplitChannel = () => {
    find(transcriptSettingOptions, ['value', 'splitChannel']).label = splitChannel
      ? 'Enable Split Channel'
      : 'Disable Split Channel';
    setSplitChannel(!splitChannel);
    localStorage.setItem('splitChannel', !splitChannel);
  };

  const handleWavesurferRegionEvents = event => {
    const details = {
      id: event.id,
      start: Math.round(event.start * 100) / 100,
      end: Math.round(event.end * 100) / 100,
      data: event.data,
    };
    if (!find(localRegions, regionDetails => regionDetails.id === details.id)) {
      details.newRegion = true;
      addRegion(details);
    } else {
      updateRegion(details);
    }
  };

  const addRegion = regionToAdd => {
    const regionList = getLocalRegions();
    regionList.push(regionToAdd);
    saveRegions(regionList);
  };

  const updateRegion = regionDetailsToUpdate => {
    regionDetailsToUpdate.unsubmitted = true;
    const regionList = getLocalRegions();
    const newList = [];
    regionList.forEach(regionDetails => {
      if (regionDetailsToUpdate.id === regionDetails.id) {
        newList.push(regionDetailsToUpdate);
      } else {
        newList.push(regionDetails);
      }
    });
    saveRegions(newList);
  };

  const deleteRegion = regionToDelete => {
    const regionList = getLocalRegions();
    const newList = [];
    regionList.forEach(regionDetails => {
      if (regionToDelete.id !== regionDetails.id) newList.push(regionDetails);
    });
    saveRegions(newList);
  };

  const updateLocalStorageRegions = regionList => {
    const sortedRegions = sortBy(regionList, ['start']);
    localStorage.setItem(`localRegions-${annotationType}-${recordingId}`, JSON.stringify(sortedRegions));
  };

  const saveRegions = regionList => {
    updateLocalStorageRegions(regionList);
    loadRegions();
    setNewAnnotationsAdded(regionList.length > 0);
  };

  const calculateSingleSectionScore = scoresList => {
    if (!scoresList.length) return null;
    return sum(scoresList) / scoresList.length;
  };

  const calculateAllSectionScores = () => {
    const sectionScores = {};
    if (isEmpty(calibrationFormData)) return sectionScores;

    const sectionList = Object.keys(calibrationFormData);
    let currentSection = sectionList[0][0];
    let scoresList = [];

    sectionList.forEach(subsection => {
      if (currentSection != subsection[0]) {
        sectionScores[currentSection] = calculateSingleSectionScore(scoresList);
        scoresList = [];
        currentSection = subsection[0];
      }

      const score = scoreConversion[calibrationFormData[subsection].score];
      scoresList.push(score);
    });

    sectionScores[currentSection] = calculateSingleSectionScore(scoresList);
    return sectionScores;
  };

  const handleSubmitQA = async () => {
    const formattedLocalRegions = localRegions.map(annotation => ({
      id: annotation.id,
      message: annotation.data.note,
      feedbackType: annotation.data.feedbackType,
      startTime: annotation.start,
      endTime: annotation.end,
    }));

    // Disable the Submit QA button so user can't double submit
    setNewAnnotationsAdded(false);

    const sectionScores = calculateAllSectionScores();

    // Send QA data to API
    const response = await addCallQA(
      recordingId,
      formattedLocalRegions,
      calibrationFormData,
      aggregatedScore,
      sectionScores,
      qaTemplate.id
    );

    if (response.errorMessage) {
      console.error(response);
      // Re-enable the Submit QA button
      setNewAnnotationsAdded(true);
    } else {
      toggleUnsubmittedLocalRegions();

      // Display the Delete QA button in place of the Submit QA button
      setIsQAed(true);
      setAuthorPermission(true);

      // Use data in API response to display the QA's metadata
      setAnnotationTimestamp(moment(response.created).format('LLL'));
      setAnnotationAuthor(response.authorName);

      // Display a success message via Toast popup
      setToastMessage('Your QA was been successfully submitted.');
      setSuccessToastOpen(true);

      // Change URL to "view" mode after submission, so QA Author can easily share appropriate link
      history.push(`/call-qas/${recordingId}`);
    }
  };

  const handleDeleteQA = async () => {
    removeCallQA(recordingId);
    setIsQAed(false);
    localStorage.removeItem(`localRegions-QA-${recordingId}`);
    setRegions();
    setAnnotationTimestamp();
    setAnnotationAuthor();
    setToastMessage('Your QA has been successfully deleted.');
    setSuccessToastOpen(true);
  };

  const handleAcknowledgeQA = async () => {
    const acknowledgeResponse = await acknowledgeCallQA(recordingId);
    if (acknowledgeResponse) setAcknowledgedDate(moment(acknowledgeResponse.acknowledgedDate).format('LLL'));
  };

  const toggleUnsubmittedLocalRegions = () => {
    const newList = [];
    localRegions.forEach(regionDetails => {
      if (regionDetails.unsubmitted) {
        regionDetails.unsubmitted = false;
        newList.push(regionDetails);
      } else {
        newList.push(regionDetails);
      }
    });

    updateLocalStorageRegions(newList);
    loadRegions();
  };

  const handleSubmitToLibrary = () => {
    const formattedLocalRegions = localRegions.map(annotation => ({
      id: annotation.id,
      message: annotation.data.note,
      startTime: annotation.start,
      endTime: annotation.end,
    }));

    addCallToLibrary(recordingId, formattedLocalRegions);
    setIsInLibrary(true);
    setNewAnnotationsAdded(false);
    setToastMessage('This call has been successfully submitted to the Call Library.');
    setSuccessToastOpen(true);
    toggleUnsubmittedLocalRegions();
  };

  const handleUpdateAnnotations = () => {
    const formattedLocalRegions = localRegions.map(annotation => ({
      id: annotation.id,
      message: annotation.data.note,
      startTime: annotation.start,
      endTime: annotation.end,
    }));

    updateLibraryAnnotations(recordingId, formattedLocalRegions);
    setNewAnnotationsAdded(false);
    setToastMessage('This Call Library entry has been successfully updated.');
    setSuccessToastOpen(true);
    toggleUnsubmittedLocalRegions();
  };

  const scrollToAnnotation = () => {
    const annotationEl = document.getElementById(`annotation-card-${selectedRegion.id}`);
    if (annotationEl) annotationEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleRegionOut = time => {
    const currentRegion = getRegionByTime(time);

    if (!isModifying && !currentRegion && annotationType === 'library') handleRegionSeek('forward', time);
    else if (!currentRegion) setSelectedRegion();
  };

  // Checks if the current time is located inside an annotated region, and if so, returns that region
  const getRegionByTime = time =>
    localRegions.find(regionDetails => time >= regionDetails.start && time <= regionDetails.end);

  const handleRegionSeek = (event, time = audioTime) => {
    let foundRegion;
    if (event === 'forward') {
      foundRegion = localRegions.find(regionDetails => time < regionDetails.start && time < regionDetails.end);
    } else {
      const reversedRegions = [...localRegions].reverse();
      foundRegion = reversedRegions.find(regionDetails => time > regionDetails.start && time > regionDetails.end);
    }

    // If no next or previous region was found, loop back to the first region instead
    const regionToPlay = foundRegion || localRegions[0];

    setForceUpdateAudio(true);
    setAudioTime(regionToPlay.start);
    setSelectedRegion(regionToPlay);
  };

  return (
    <DocumentTitle title={`SRM Calls | ${annotationType === 'QA' ? 'QA' : 'Library'} ${recordingId}`}>
      {call && (
        <Fragment>
          <ToastContainer>
            {successToastOpen && (
              <Toast
                duration={10000}
                labels={{
                  heading: toastMessage,
                }}
                onRequestClose={() => setSuccessToastOpen(false)}
                variant="success"
              />
            )}
          </ToastContainer>
          <section
            className={`annotated-call ${
              splitChannel && call.dualChannel && call.rawAudioLength < 1800 && 'split-channels'
            } ${annotationTimestamp && 'annotated-header'}`}
          >
            <article className="call-card">
              <header className="slds-card__header">
                <div className="slds-grid">
                  <div className="slds-col">
                    {annotationAuthor && annotationTimestamp && (
                      <p className="annotation-timestamp">
                        QA'ed by {annotationAuthor} (<i>{annotationTimestamp}</i>)
                      </p>
                    )}
                    {qaTemplate && (
                      <p>
                        Using Template: {qaTemplate.name} (<i>v{qaTemplate.version}</i>)
                      </p>
                    )}
                    {call.relatedAccounts.length === 0 && <h3 className="account-name">UNKNOWN</h3>}
                    {call.relatedAccounts.length !== 0 && (
                      <h3 className="account-name">
                        <a href={`/account/${call.relatedAccounts[0].accountId}`} target="_blank">
                          {call.relatedAccounts[0].accountName}
                        </a>
                        {call.relatedAccounts.length > 1 && (
                          <Popover
                            heading="Suppliers"
                            align="bottom left"
                            className="supplier-dropdown"
                            body={
                              <div>
                                {call.relatedAccounts.map((relatedAccount, index) => {
                                  if (index !== 0)
                                    return (
                                      <a
                                        key={index}
                                        href={`/account/${relatedAccount.accountId}`}
                                        target="_blank"
                                        className="supplier-name slds-text-align_left"
                                      >
                                        {relatedAccount.accountName}
                                      </a>
                                    );
                                })}
                              </div>
                            }
                          >
                            <Button
                              variant="base"
                              label={`(+${call.relatedAccounts.length - 1} more)`}
                              className="slds-m-left_xx-small"
                            />
                          </Popover>
                        )}
                      </h3>
                    )}
                    <p>{call.supplierPhone}</p>
                  </div>
                  <div className="slds-col slds-text-align_right">
                    {/* TODO: Will need to bring back similar functionality at some point, when we know which office each call belongs to.
                                            <p>{moment(call.initiatedTime).tz(region === 'EU' ? 'Europe/London' : 'America/Chicago').format('MMMM Do YYYY')}</p>
                                        */}
                    <p>{moment(call.initiatedTime).format('MMMM Do YYYY')}</p>
                  </div>
                </div>
                <section className="badge-list slds-m-top_x-small">
                  {call.tags &&
                    flatTagList.map(
                      tag => call.tags.includes(tag.id) && <Badge key={tag.id} content={tag.displayName} />
                    )}
                  {call.possibleVoicemail &&
                    !(call.tags && call.tags.indexOf('06418046-e7c5-4d78-ae58-470693ee9d72') >= 0) && (
                      <Badge content="Possible Voicemail" />
                    )}
                  {call.dualChannel && <Badge content="Dual Channel" />}
                </section>
              </header>
              <section
                className={`audio-container ${
                  splitChannel && call.dualChannel && call.rawAudioLength < 1800 && 'split-channels'
                }`}
              >
                <div className="audio-wrapper">
                  <WavesurferPlayer
                    audioSrc={call.audioSrc}
                    audioTime={audioTime}
                    audioLength={call.rawAudioLength}
                    transcriptSettingOptions={transcriptSettingOptions}
                    dualChannel={call.dualChannel}
                    onEvent={(event, seekTime = null) => handleWavesurferEvents(event, seekTime)}
                    handleSettingChange={handleSettingChange}
                    splitChannel={splitChannel}
                    setForceUpdateAudioCallback={setForceUpdateAudio}
                    forceUpdateAudio={forceUpdateAudio}
                    regionMode={isModifying && !(isQAed && annotationType === 'QA') ? 'edit' : 'view'}
                    regions={localRegions}
                    onRegionClickEvent={event => setSelectedRegion(event)}
                    onRegionUpdateEvent={event => handleWavesurferRegionEvents(event)}
                    onRegionOutEvent={handleRegionOut}
                    onRegionInEvent={event => setSelectedRegion(event)}
                    onRegionSeek={event => handleRegionSeek(event)}
                  />
                </div>
              </section>
            </article>
            <section className={`annotations-transcript-wrapper ${annotationType === 'QA' && 'calibration-wrapper'}`}>
              <section className={`annotations-container ${splitChannel && call.dualChannel && 'split-channels'}`}>
                <div className="annotations-header">
                  <p>Annotations</p>
                </div>
                <div className="annotations-list slds-scrollable_y">
                  <div className="slds-panel__body">
                    {localRegions &&
                      localRegions.map(regionDetails => (
                        <Annotations
                          key={regionDetails.id}
                          annotationType={annotationType}
                          regionDetails={regionDetails}
                          isSelected={selectedRegion && selectedRegion.id === regionDetails.id}
                          onDelete={regionToDelete => deleteRegion(regionToDelete)}
                          onUpdate={updatedDetails => updateRegion(updatedDetails)}
                          isModifying={isModifying && !(isQAed && annotationType === 'QA')}
                        />
                      ))}
                    {localRegions && localRegions.length <= 0 && (
                      <ScopedNotification theme="light" className="no-records">
                        <>
                          <p>There are no annotations for this call yet.</p>
                          {isModifying && <p>Select a region over the audio to add an annotation.</p>}
                        </>
                      </ScopedNotification>
                    )}
                  </div>
                </div>
                <div className="annotations-button-container">
                  {!isQAed && qaPermission && showQAButtons && (
                    <Button
                      disabled={!(newAnnotationsAdded || !isEmpty(calibrationFormData))}
                      onClick={handleSubmitQA}
                      className="slds-button slds-button_brand"
                    >
                      Submit QA
                    </Button>
                  )}
                  {isQAed && qaPermission && showQAButtons && authorPermission && (
                    <DeleteConfirmation
                      onDelete={handleDeleteQA}
                      body="Are you sure you want to delete this QA?"
                      heading="Delete QA"
                    >
                      <Button variant="destructive">Delete QA</Button>
                    </DeleteConfirmation>
                  )}
                  {isQAed && srmPermission && showQAButtons && !acknowledgedDate && (
                    <Button onClick={handleAcknowledgeQA} className="slds-button slds-button_brand">
                      Acknowledge QA
                    </Button>
                  )}
                  {isQAed && srmPermission && showQAButtons && acknowledgedDate && (
                    <div className="slds-text-align_center">
                      <p>Acknowledged by {call.srmName}</p>
                      <i>{acknowledgedDate}</i>
                    </div>
                  )}
                  {!isInLibrary && isModifying && libraryPermission && (
                    <Button onClick={handleSubmitToLibrary} className="slds-button slds-button_brand">
                      Submit Annotated Call To Library
                    </Button>
                  )}
                  {isInLibrary && isModifying && libraryPermission && (
                    <Button
                      disabled={!newAnnotationsAdded}
                      onClick={handleUpdateAnnotations}
                      className="slds-button slds-button_brand"
                    >
                      Update Annotations
                    </Button>
                  )}
                </div>
              </section>
              <section
                className={`transcript-container ${
                  splitChannel && call.dualChannel && call.rawAudioLength < 1800 && 'split-channels'
                }`}
              >
                <div
                  role="log"
                  className="slds-chat slds-scrollable_y transcript-list"
                  onScroll={temporarilyDisableAutoScroll}
                >
                  <Transcript
                    callId={call.callId}
                    recordingId={recordingId}
                    transcript={call.transcript}
                    editTranscriptMode={editTranscriptMode}
                    dualSpeakers
                    dualChannel={call.dualChannel}
                    srmName={call.srmName}
                    setCallTimeCallback={setCallTime}
                    editTranscriptCallback={editTranscript}
                    disableAutoScrollCallback={disableAutoScroll}
                    singleCall
                  />
                </div>
              </section>
              {annotationType === 'QA' && (
                <section className={`calibration-container ${splitChannel && call.dualChannel && 'split-channels'}`}>
                  <div className="annotations-header">
                    <p>Quality Assurance</p>
                  </div>
                  <div className="calibrations-list slds-scrollable_y">
                    <div className="slds-panel__body">
                      <CalibrationForm
                        formData={calibrationFormData}
                        formTemplate={(qaTemplate || {}).template}
                        setFormData={setCalibrationFormData}
                        clearFormData={clearCalibrationFormData}
                        scoreCount={scoreCount}
                        setScoreCount={setScoreCount}
                        mode={isModifying && !(isQAed && annotationType === 'QA') ? 'edit' : 'view'}
                      />
                    </div>
                  </div>
                  <div className="score-info-container">
                    {isEmpty(calibrationFormData) && !isModifying ? (
                      <p>Quality Assurance form was not completed for this call.</p>
                    ) : (
                      <div className="score-info">
                        <AggregatedScoreGauge score={aggregatedScore} />
                        {Object.keys(scoreCount).map(score => (
                          <div key={score}>
                            <Badge
                              className="score-count"
                              content={scoreCount[score]}
                              color={score === 'Successful' ? 'success' : score === 'Neutral' ? 'warning' : 'error'}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </section>
              )}
            </section>
          </section>
        </Fragment>
      )}
    </DocumentTitle>
  );
};

const CalibrationForm = ({ mode, formData, formTemplate, setFormData, clearFormData, scoreCount, setScoreCount }) => {
  const [expandedPanels, { set: setExpandedPanel }] = useMap({});
  const [expandedSubPanels, { set: setExpandedSubPanel }] = useMap({});

  const handleScoreRadioButton = (score, questionId) => {
    const previousScore = formData[questionId]?.score;
    if (previousScore) setScoreCount(previousScore, (scoreCount[previousScore] -= 1));

    setFormData(questionId, {
      score,
      text: formData[questionId]?.text,
    });

    setScoreCount(score, (scoreCount[score] += 1));
  };

  const handleTextChange = (text, questionId) => {
    setFormData(questionId, {
      score: formData[questionId].score,
      text,
    });
  };

  const handleClearScoreButton = questionId => {
    clearFormData(questionId);
    const previousScore = formData[questionId].score;
    setScoreCount(previousScore, (scoreCount[previousScore] -= 1));
  };

  const togglePanel = item => {
    setExpandedPanel(item.id, !expandedPanels[item.id]);
  };

  const toggleSubPanel = subitem => {
    if (mode === 'view' && !formData[subitem.id]?.score) return;
    setExpandedSubPanel(subitem.id, !expandedSubPanels[subitem.id]);
  };

  return (
    <Accordion>
      {(formTemplate || []).map(item => {
        return (
          <AccordionPanel
            expanded={
              mode === 'view' && item.subitems.some(subitem => formData[subitem.id]?.score)
                ? !expandedPanels[item.id]
                : !!expandedPanels[item.id]
            }
            id={item.id}
            key={item.id}
            onTogglePanel={() => togglePanel(item)}
            summary={item.summary}
          >
            {item.subitems.map(subitem => (
              <Accordion
                key={subitem.id}
                className={mode === 'view' && !formData[subitem.id]?.score ? 'disabled-subpanel' : ''}
              >
                <AccordionPanel
                  expanded={
                    mode === 'view' && formData[subitem.id]?.score
                      ? !expandedSubPanels[subitem.id]
                      : !!expandedSubPanels[subitem.id]
                  }
                  id={subitem.id}
                  onTogglePanel={() => toggleSubPanel(subitem)}
                  summary={subitem.summary}
                >
                  <div className="feedback-button-group">
                    {mode === 'edit' ? (
                      <>
                        <RadioButtonGroup onChange={event => handleScoreRadioButton(event.target.value, subitem.id)}>
                          {scores.map(score => (
                            <Radio
                              key={score.label}
                              labels={{ label: score.label }}
                              value={score.label}
                              variant="button-group"
                              checked={formData[subitem.id]?.score === score.label}
                              className={getScoreButtonClassName(score.label)}
                            />
                          ))}
                        </RadioButtonGroup>
                        {formData[subitem.id] ? (
                          <ClearButton onClear={() => handleClearScoreButton(subitem.id)} />
                        ) : null}
                      </>
                    ) : (
                      <Badge
                        className={getBadgeClassName(formData[subitem.id]?.score)}
                        content={formData[subitem.id]?.score}
                      />
                    )}
                  </div>
                  {mode === 'edit' ? (
                    <Textarea
                      className="annotation-note-input"
                      placeholder={subitem.details}
                      value={formData[subitem.id]?.text}
                      disabled={!formData[subitem.id]}
                      onChange={event => handleTextChange(event.target.value, subitem.id)}
                    />
                  ) : (
                    <p>{formData[subitem.id]?.text}</p>
                  )}
                </AccordionPanel>
              </Accordion>
            ))}
          </AccordionPanel>
        );
      })}
    </Accordion>
  );
};

const ClearButton = ({ onClear }) => (
  <Tooltip content="Remove Score" align="top" position="overflowBoundaryElement">
    <Button variant="icon" onClick={onClear} iconCategory="utility" iconName="clear" />
  </Tooltip>
);

export default AnnotateCall;
