import { PureComponent } from 'react';
import { Badge, Button, Input, Modal, Pill, ScopedNotification, Spinner } from '@salesforce/design-system-react';

import { createKeywordGroup, getKeywordGroups, deleteKeywordGroup, updateKeywordGroup } from '../call-utils';
import DeleteConfirmation from './DeleteConfirmation';

class KeywordGroups extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      wordList: [],
      wordValue: '',
      groupName: '',
      groups: [],
      offset: 0,
      editMode: false,
      selectedGroup: null,
      enableSubmitButton: false,
    };
    window.addEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    const {
      loadKeywordGroups,
      state: { isLoading, canLoadMore },
    } = this;

    if (isLoading || !canLoadMore) return;

    // Checks that the page has scrolled to the bottom
    if (
      Number((window.innerHeight + document.documentElement.scrollTop).toFixed(0)) ===
      document.documentElement.offsetHeight
    )
      loadKeywordGroups();
  };

  componentDidMount = () => this.loadKeywordGroups();
  componentWillUnmount = () => window.removeEventListener('scroll', this.onScroll, false);

  loadKeywordGroups = async () => {
    this.setState({ isLoading: true });
    const keywordGroup = await getKeywordGroups(this.state.offset);
    this.populateKeywordGroup(keywordGroup);
  };

  populateKeywordGroup = keywordGroup => {
    const { groups } = this.state;
    if (!keywordGroup.length) this.setState({ isLoading: false, canLoadMore: false });
    else {
      const newGroups = keywordGroup.map(group => ({
        id: group.id,
        name: group.groupName,
        keywords: group.keywords,
      }));
      this.setState({
        groups: [...groups, ...newGroups],
        isLoading: false,
        canLoadMore: keywordGroup.length === 30,
        offset: this.state.offset + 30,
      });
    }
  };

  closeModal = () =>
    this.setState({
      isModalOpen: false,
      wordList: [],
      wordValue: '',
      groupName: '',
    });

  openModal = () => this.setState({ isModalOpen: true, editMode: false, enableSubmitButton: false });

  addWords = () => {
    const { wordList, wordValue } = this.state;
    if (wordList.includes(wordValue) || wordValue === '') {
      this.setState({ wordValue: '' });
      return false;
    }
    this.setState({ wordList: [...this.state.wordList, wordValue], enableSubmitButton: true }, () =>
      this.setState({ wordValue: '' })
    );
  };

  resetGroups = () =>
    this.setState({
      groups: [],
      offset: 0,
      wordList: [],
      wordValue: '',
      groupName: '',
    });

  addGroup = async () => {
    const { groupName, wordList, editMode, selectedGroup } = this.state;
    this.setState({ isModalOpen: false, isLoading: true });
    if (editMode) await updateKeywordGroup(selectedGroup.id, wordList, groupName);
    else await createKeywordGroup(groupName, wordList);
    await this.resetGroups();
    this.loadKeywordGroups();
  };

  handleDelete = async groupID => {
    this.setState({ isLoading: true });
    await deleteKeywordGroup(groupID);
    await this.resetGroups();
    this.loadKeywordGroups();
  };

  editGroup = group => {
    this.setState({
      wordValue: '',
      groupName: group.name,
      wordList: group.keywords,
      selectedGroup: group,
      editMode: true,
      isModalOpen: true,
      enableSubmitButton: false,
    });
  };

  onRemoveKeyword = wordIndex =>
    this.setState({
      wordList: this.state.wordList.filter((value, index) => index !== wordIndex),
      enableSubmitButton: true,
    });

  render() {
    const { wordList, wordValue, isModalOpen, groupName, groups, isLoading, enableSubmitButton, editMode } = this.state;
    return (
      <main className="settings-page-container keyword-group-page">
        <div className="settings-page">
          <div className="slds-card">
            <div className="slds-panel__header">
              <h2 className="slds-panel__header-title slds-text-heading_large slds-truncate">Keyword Groups</h2>
              <button
                className="slds-button slds-button_neutral slds-button_brand"
                type="submit"
                onClick={this.openModal}
              >
                Add&nbsp;Group
              </button>
            </div>
            <div className="slds-panel__body">
              {groups.length > 0 && (
                <table className="slds-table slds-table_cell-buffer slds-table_bordered ">
                  <thead>
                    <tr className="slds-line-height_reset">
                      <th scope="col">
                        <div className="slds-truncate">Group Name</div>
                      </th>
                      <th scope="col" colSpan="2">
                        <div className="slds-truncate">Keywords</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.map((group, index) => (
                      <tr className="slds-hint-parent" key={group.name + index}>
                        <td width="250">
                          <div className="slds-truncate">{group.name}</div>
                        </td>
                        <td>
                          {group.keywords.map((keyword, keywordIndex) => (
                            <Badge key={keywordIndex} content={keyword} />
                          ))}
                        </td>
                        <td data-label="Contact" width="100">
                          <div className="slds-truncate">
                            <Button
                              iconCategory="utility"
                              iconName="edit"
                              iconPosition="left"
                              variant="brand"
                              onClick={() => this.editGroup(group)}
                              className="slds-m-right_x-small slds-button-utility"
                            />
                            <DeleteConfirmation
                              onDelete={() => this.handleDelete(group.id)}
                              body="Are you sure you want to delete this group?"
                              heading="Delete Group"
                              buttonVariant="destructive"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!isLoading && groups.length === 0 && (
                <ScopedNotification theme="light" className="no-records">
                  <p>Sorry, no groups to show.</p>
                </ScopedNotification>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Spinner />}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.closeModal}
          heading={editMode ? 'Edit Group' : 'Add Group'}
          className="add-keyword-group-modal"
        >
          <section className="slds-p-around_large">
            <div className="slds-grid slds-gutters">
              <div className="slds-col">
                <Input
                  id="keyword-group"
                  label="Group Name"
                  placeholder="Enter group name"
                  value={groupName}
                  onChange={value => this.setState({ groupName: value.target.value, enableSubmitButton: true })}
                />
              </div>
              <div className="slds-col">
                <label className="slds-form-element__label">Keywords</label>
                <form
                  className="slds-grid"
                  onSubmit={event => {
                    event.preventDefault();
                    this.addWords();
                  }}
                >
                  <div className="text-area-wrapper">
                    <Input
                      placeholder="Keyword/Phrase"
                      value={wordValue}
                      onChange={value => this.setState({ wordValue: value.target.value })}
                    />
                  </div>
                  <div className="slds-publisher__actions slds-grid slds-grid_align-spread">
                    <Button
                      className="slds-button_brand"
                      label="Add"
                      disabled={wordValue.length === 0}
                      onClick={this.addWords}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="slds-m-top_medium">
              <label>Group Words</label>
              <div>
                {wordList.map((word, index) => (
                  <Pill
                    key={word + index}
                    labels={{ label: word, removeTitle: 'Remove' }}
                    onRemove={() => this.onRemoveKeyword(index)}
                  />
                ))}
              </div>
            </div>
            <div className="slds-m-top_medium">
              <Button
                label={editMode ? 'Save Group' : 'Add Group'}
                onClick={this.addGroup}
                variant="brand"
                disabled={(wordList.length && groupName.length) === 0 || !enableSubmitButton}
              />
            </div>
          </section>
        </Modal>
      </main>
    );
  }
}

export default KeywordGroups;
