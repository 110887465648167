import { useState, useEffect, useRef } from 'react';
import { Dropdown, Popover, Button, Textarea, DropdownTrigger } from '@salesforce/design-system-react';

const boundOptions = [
  { label: 'Left', value: 1 },
  { label: 'Right', value: 2 },
];

const EditTranscript = ({ isOpen, transcript, dualChannel, speakerBound, editTranscript, disableAutoScroll }) => {
  const [isEditTranscriptPopoverOpen, setIsEditTranscriptPopoverOpen] = useState(isOpen);
  const [currentTranscript, setCurrentTranscript] = useState(transcript);
  const [bound, setBound] = useState(transcript.speakerTag === 1 ? 'Left' : 'Right');
  const [selectedSpeakerBound, setSelectedSpeakerBound] = useState(transcript.speakerTag);
  const transcriptMessageTextArea = useRef();

  useEffect(() => {
    if (transcriptMessageTextArea && transcriptMessageTextArea.current) {
      const el = document.getElementById(transcriptMessageTextArea.current.generatedId);
      el.focus();

      // Moves the cursor to the end of the value
      el.value = '';
      el.value = currentTranscript.message;
    }
  }, [isEditTranscriptPopoverOpen]);

  const handleApply = () => {
    setIsEditTranscriptPopoverOpen(false);
    editTranscript(currentTranscript);
  };

  const handleClose = () => {
    setIsEditTranscriptPopoverOpen(false);
    setCurrentTranscript(transcript);
    setBound(transcript.speakerTag === 1 ? 'Left' : 'Right');
  };

  const handleOpen = () => {
    setIsEditTranscriptPopoverOpen(true);
    disableAutoScroll();
  };

  const handleMessageChange = event => setCurrentTranscript({ ...currentTranscript, message: event.target.value });

  const handleSpeakerBoundSelect = ({ label, value }) => {
    setCurrentTranscript({ ...currentTranscript, speakerTag: value });
    setBound(label);
    setSelectedSpeakerBound(value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleApply();
    }
  };

  const editTranscriptBody = (
    <div className="slds-form slds-p-around_none edit-transcript-form">
      <p>Message</p>
      <Textarea
        value={currentTranscript.message}
        onChange={handleMessageChange}
        ref={transcriptMessageTextArea}
        onKeyDown={handleKeyDown}
      />
      {!dualChannel && (
        <div className="slds-m-top_x-small">
          <p>Speaker</p>
          <Dropdown
            align="right"
            value={selectedSpeakerBound}
            options={boundOptions}
            onSelect={handleSpeakerBoundSelect}
          >
            <DropdownTrigger>
              <Button iconCategory="utility" iconName="down" iconPosition="right" label={bound} />
            </DropdownTrigger>
          </Dropdown>
        </div>
      )}
    </div>
  );

  const footer = (
    <div className="slds-text-align_right">
      <Button label="Cancel" onClick={handleClose} />
      <Button variant="brand" label="Save" onClick={handleApply} />
    </div>
  );

  return (
    <div className="edit-transcript">
      <Popover
        isOpen={isEditTranscriptPopoverOpen}
        heading="Edit Transcript"
        body={editTranscriptBody}
        onRequestClose={handleClose}
        position="absolute"
        align={speakerBound === 'outbound' ? 'bottom right' : 'bottom left'}
        footer={footer}
      >
        <Button
          className="edit-button"
          onClick={handleOpen}
          assistiveText={{ icon: 'Edit Transcript' }}
          iconCategory="action"
          iconName="edit"
          iconSize="small"
          iconVariant="bare"
          variant="icon"
        />
      </Popover>
    </div>
  );
};

export default EditTranscript;
