export const AUTO_SCROLL_INDEX_BUFFER = 1;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const NUMBER_OF_TAGS = 7;

export const CALL_LIST_OFFSET = 50;

// Settings page
export const SPEECH_HINTS_OFFSET = 30;

// Lists (for dropdowns)
export const CALL_LANGUAGES = [
  { label: 'Cantonese', value: 'Cantonese' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English', value: 'English' },
  { label: 'French', value: 'French' },
  { label: 'German', value: 'German' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Mandarin', value: 'Mandarin' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Turkish', value: 'Turkish' },
];

export const CALL_DISPOSITIONS = [
  {
    label: 'N/A (No Disposition)',
    value: 'N/A',
  },
  {
    label: 'Attempting Contact',
    value: 'Attempting Contact',
  },
  {
    label: 'Call Rejected',
    value: 'Call Rejected',
  },
  {
    label: 'Connected - Decision Maker',
    value: 'Connected - Decision Maker',
  },
  {
    label: 'Connected - Non-DM',
    value: 'Connected - Non-DM',
  },
  {
    label: "Don't Log to Salesforce",
    value: "Don't Log to Salesforce",
  },
  {
    label: 'Left Voicemail',
    value: 'Left Voicemail',
  },
  {
    label: 'No Answer',
    value: 'No Answer',
  },
  {
    label: 'Not Found',
    value: 'Not Found',
  },
  {
    label: 'Not Interested',
    value: 'Not Interested',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Post Funding Follow Up Call',
    value: 'Post Funding Follow Up Call',
  },
  {
    label: 'Post Participation Follow Up Call',
    value: 'Post Participation Follow Up Call',
  },
  {
    label: 'Presented Solution/Walkthrough - DM',
    value: 'Presented Solution/Walkthrough - DM',
  },
  {
    label: 'Presented Solution/Walkthrough - Non-DM',
    value: 'Presented Solution/Walkthrough - Non-DM',
  },
  {
    label: 'Successful Call',
    value: 'Successful Call',
  },
  {
    label: 'Supplier Feedback',
    value: 'Supplier Feedback',
  },
  {
    label: 'Supplier Support - General',
    value: 'Supplier Support - General',
  },
  {
    label: 'Supplier Support - Proxy Offer',
    value: 'Supplier Support - Proxy Offer',
  },
  {
    label: 'Vishing',
    value: 'Vishing',
  },
  {
    label: 'Wrong Number',
    value: 'Wrong Number',
  },
];

// Languages currently supported by Google's Speech API
export const TRANSCRIPTION_LANGUAGES = [
  'English',
  'Danish',
  'French',
  'German',
  'Italian',
  'Japanese',
  'Mandarin',
  'Spanish',
  'Swedish',
  'Turkish',
];
