import { useCallback, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { debounce, every, isEqual, sortBy, round } from 'lodash';
import queryString from 'query-string';
import { ScopedNotification, Spinner } from '@salesforce/design-system-react';

import {
  getStatsOverview,
  getStatsOverviewSrm,
  getStatsOverviewSrmCsv,
  getGroupKeywords,
  getTagList,
  getQAMetrics,
  getQATeamBreakdown,
} from '../call-utils';
import parseFilters from '../common-utils';
import { getQueryParam } from '../url-utils';
import { checkUserRole } from '../auth';

import DocumentTitle from './DocumentTitle';
import Filters from './Filters';
import OverviewGraph from './OverviewGraph';
import QAGraph from './QAGraph';
import SrmStats from './SrmStats';

const isSubset = (aSubset, aSuperset) => every(aSubset, (val, key) => isEqual(val, aSuperset[key]));

const getDayDiff = (startDate, endDate) => {
  if (startDate) endDate = endDate || new Date().toISOString().slice(0, 10);
  if (endDate) startDate = startDate || '2014-09-11';
  return Math.floor(moment(endDate).diff(startDate, 'days', true));
};

const defaultFilters = {
  selectedAgent: null,
  selectedTags: [],
  selectedCountry: null,
  selectedLanguage: null,
  selectedDirection: null,
  selectedAnswerStatus: null,
  minAudioLength: 0,
  startDate: undefined,
  endDate: undefined,
  startTime: '',
  endTime: '',
  hideUnknownSuppliers: false,
  hideVoicemails: false,
  hideUntranscribed: false,
  dualChannel: false,
  keywordSearchBy: 'Both',
  keywordSearchText: '',
  keywordGroupName: '',
  groupedKeywords: [],
  supplierSearchText: '',
  phoneSearchText: '',
  selectedSrmTeamName: '',
  selectedSrmTeamMembers: [],
  selectedDisposition: null,
};

const createInitialFilters = () => parseFilters({ ...defaultFilters, ...queryString.parse(location.search) });

const StatsPage = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [keywordSearchTextUrl, setKeywordSearchTextUrl] = useState(
    useMemo(() => getQueryParam('keywordSearchText'), [])
  );
  const [phoneSearchTextUrl, setPhoneSearchTextUrl] = useState(useMemo(() => getQueryParam('phoneSearchText'), []));
  const [supplierSearchTextUrl, setSupplierSearchTextUrl] = useState(
    useMemo(() => getQueryParam('supplierSearchText'), [])
  );

  const [srmTableData, setSrmTableData] = useState([]);
  const [isSrmTableLoading, setIsSrmTableLoading] = useState(false);

  const [overviewGraphData, setOverviewGraphData] = useState([]);
  const [isOverviewGraphLoading, setIsOverviewGraphLoading] = useState(false);

  const qaPermission = checkUserRole('call_transcriber_review');

  const [QATeamBreakdownGraphData, setQATeamBreakdownGraphData] = useState([]);
  const [isQATeamBreakdownGraphLoading, setIsQATeamBreakdownGraphLoading] = useState(false);

  const [QAMetricsGraphData, setQAMetricsGraphData] = useState([]);
  const [isQAMetricsGraphLoading, setIsQAMetricsGraphLoading] = useState(false);

  const [sortDirection, setSortDirection] = useState('ASC');
  const [sortColumn, setSortColumn] = useState('localName');

  const [srmCSVStats, setSrmCSVStats] = useState([]);
  const [srmCSVHeader, setSrmCSVHeader] = useState(null);

  const [currentFilters, setCurrentFilters] = useState(useCallback(() => createInitialFilters(), []));
  const [showClearFilterButton, setShowClearFilterButton] = useState(false);

  const [tagList, setTagList] = useState([]);

  const loadTagList = async () => {
    const tags = await getTagList();
    setTagList(tags);
  };

  useEffect(() => {
    // When currentFilters changes, reflect those changes in the URL query string
    // ex: calls.c2fo-lab.com/stats?selectedDirection=INBOUND
    const filters = {};
    Object.entries(defaultFilters).map(([filterName, filterValue]) => {
      if (filterValue !== currentFilters[filterName]) filters[filterName] = currentFilters[filterName];
    });
    history.push({ search: queryString.stringify(filters) });

    // decides whether the clear filter button should display, based on current state of filter combinations
    setShowClearFilterButton(!isSubset(defaultFilters, currentFilters));
  }, [currentFilters]);

  useEffect(() => {
    // load list of tags on page load
    loadTagList();
  }, []);

  const loadAllStats = async () => {
    setCurrentFilters({
      ...currentFilters,
      keywordSearchText: keywordSearchTextUrl,
      phoneSearchText: phoneSearchTextUrl,
      supplierSearchText: supplierSearchTextUrl,
    });
    await loadOverviewGraph();
    await loadSrmTableData();
    await loadQAMetricsGraph();
    if (currentFilters.selectedSrmTeamMembers != '') await loadQATeamBreakdownGraph();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) loadAllStats();
  }, [isLoading]);

  const loadOverviewGraph = async () => {
    const intervalType = calculateIntervalType(currentFilters.startDate, currentFilters.endDate);
    let data = await loadData('overviewGraph');

    if (data.length > 0) {
      if (intervalType === 'day')
        data = data.map(item => ({ ...item, interval: moment.utc(item.interval).format('MMM DD YYYY') }));
      else if (intervalType === 'month') data = sortBy(data, [item => new Date(item.interval)]);
      setOverviewGraphData(data);
    }
    setIsOverviewGraphLoading(false);
  };

  const loadQATeamBreakdownGraph = async () => {
    if (currentFilters.selectedSrmTeamMembers === '') {
      setQATeamBreakdownGraphData([]);
      return;
    }
    let data = await loadData('QATeamBreakdownGraph');

    if (data.length) {
      let teamBreakdownByDate = [
        ...new Map(data.map(graphData => [graphData.date, { date: graphData.date }])).values(),
      ];

      // Get all unique SRM names from the graph data
      const teamMembers = [...new Set(data.map(graphData => graphData.name))];

      // Format data, so that it's only grouped by day (instead of day and name)
      let i = 0;
      data.forEach(graphData => {
        if (graphData.date != teamBreakdownByDate[i].date) {
          teamMembers.forEach(srm => {
            if (teamBreakdownByDate[i][srm] === undefined) teamBreakdownByDate[i][srm] = null;
          });

          i += 1;
        }

        teamBreakdownByDate[i][graphData.name] = round(graphData.overallScore, 2);
      });

      teamMembers.forEach(srm => {
        if (teamBreakdownByDate[i][srm] === undefined) teamBreakdownByDate[i][srm] = null;
      });

      data = teamBreakdownByDate;
    }

    setQATeamBreakdownGraphData(data);
    setIsQATeamBreakdownGraphLoading(false);
  };

  const loadQAMetricsGraph = async () => {
    let data = await loadData('QAMetricsGraph');

    if (data.length > 0) {
      data = data.map(item => ({ ...item, overallScore: round(item.overallScore, 3) }));
    }

    setQAMetricsGraphData(data);
    setIsQAMetricsGraphLoading(false);
  };

  const calculateIntervalType = (startDate, endDate) => {
    const dayDiff = getDayDiff(startDate, endDate);
    if (!startDate && !endDate) return 'year';
    else if (dayDiff <= 0) return 'hour';
    else if (dayDiff > 0 && dayDiff < 30) return 'day';
    else if (dayDiff > 30 && dayDiff < 730) return 'month';
    else return 'year';
  };

  const getCsvWeeklyHeaders = stats => {
    const days = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };
    stats.forEach(item => {
      days.monday += Number(item.mondayCount);
      days.tuesday += Number(item.tuesdayCount);
      days.wednesday += Number(item.wednesdayCount);
      days.thursday += Number(item.thursdayCount);
      days.friday += Number(item.fridayCount);
      days.saturday += Number(item.saturdayCount);
      days.sunday += Number(item.sundayCount);
    });
    const csvWeeklyHeaders = [{ label: 'Name', key: 'localName' }];
    for (let i = moment(currentFilters.startDate); i.diff(currentFilters.endDate, 'days') <= 0; i.add(1, 'days')) {
      if (days[i.format('dddd').toLowerCase()] > 0)
        csvWeeklyHeaders.push({ label: i.format('dddd'), key: `${i.format('dddd').toLowerCase()}Count` });
    }
    csvWeeklyHeaders.push(
      { label: 'Total Calls', key: 'totalCount' },
      { label: 'Total TT', key: 'totalMinutes' },
      { label: 'Avg. TT', key: 'avgMinutes' },
      { label: 'I/B', key: 'inboundCount' },
      { label: 'I/B TT', key: 'inboundMinutes' },
      { label: 'O/B', key: 'outboundCount' },
      { label: 'O/B TT', key: 'outboundMinutes' }
    );
    return csvWeeklyHeaders;
  };

  const loadSrmTableData = async (sortColumn, sortDirection) => {
    const dayDiff = getDayDiff(currentFilters.startDate, currentFilters.endDate);
    const data = await loadData('overviewSrm', sortColumn, sortDirection);
    if (data.length) {
      let newSrmCSVStats = data;
      let newSrmCSVHeader = [
        { label: 'SRM / Ring Group', key: 'localName' },
        { label: 'Total Calls', key: 'totalCount' },
        { label: '<1 min', key: 'mins1Count' },
        { label: '1 - 5 min', key: 'mins5Count' },
        { label: '5 - 10 min', key: 'mins10Count' },
        { label: '10 - 20 min', key: 'mins20Count' },
        { label: '>20 min', key: 'mins20PCount' },
        { label: 'Total TT', key: 'totalMinutes' },
        { label: 'Avg. TT', key: 'avgMinutes' },
        { label: 'I/B', key: 'inboundCount' },
        { label: 'I/B TT', key: 'inboundMinutes' },
        { label: 'O/B', key: 'outboundCount' },
        { label: 'O/B TT', key: 'outboundMinutes' },
      ];
      if (dayDiff > 0 && dayDiff < 7) {
        newSrmCSVStats = await loadData('overviewSrmCsv', sortColumn, sortDirection);
        newSrmCSVHeader = getCsvWeeklyHeaders(newSrmCSVStats);
      }

      setSrmTableData(data);
      setIsSrmTableLoading(false);
      setSrmCSVStats(newSrmCSVStats);
      setSrmCSVHeader(newSrmCSVHeader);
    }
    setIsSrmTableLoading(false);
  };

  const sortSrmStats = newSortColumn => {
    let newSortDirection = 'ASC';
    if (sortColumn === newSortColumn) newSortDirection = sortDirection === 'DESC' ? 'ASC' : 'DESC';

    setSrmTableData([]);
    setSortDirection(newSortDirection);
    setSortColumn(newSortColumn);
    loadSrmTableData(newSortColumn, newSortDirection);
  };

  const loadData = async (action, sortColumn = 'localName', sortDirection = 'ASC') => {
    const payload = {
      intervalType: calculateIntervalType(currentFilters.startDate, currentFilters.endDate),
      sortColumn,
      sortDirection,
      startDate: currentFilters.startDate || '2014-09-11',
      endDate: currentFilters.endDate || new Date().toISOString().slice(0, 10),
      srmAgent: currentFilters.selectedAgent,
      tagIds: currentFilters.selectedTags,
      audioLength: currentFilters.minAudioLength,
      hideUnknownAccounts: currentFilters.hideUnknownSuppliers,
      hideVoicemails: currentFilters.hideVoicemails,
      supplier: supplierSearchTextUrl,
      keyword: keywordSearchTextUrl,
      hideUntranscribed: currentFilters.hideUntranscribed,
      dualChannel: currentFilters.dualChannel,
      answerStatus: currentFilters.selectedAnswerStatus,
      country: currentFilters.selectedCountry,
      language: currentFilters.selectedLanguage,
      direction: currentFilters.selectedDirection,
      startTime: currentFilters.startTime || currentFilters.endTime || '',
      endTime: currentFilters.endTime || currentFilters.startTime || '',
      keywordSearchBy: currentFilters.keywordSearchBy,
      groupedKeywords: currentFilters.keywordGroupName ? currentFilters.groupedKeywords : '',
      phoneNumber: phoneSearchTextUrl ? phoneSearchTextUrl.replace(/[^0-9]/g, '') : '',
      srmTeam: currentFilters.selectedSrmTeamName ? currentFilters.selectedSrmTeamMembers : '',
      teamName: currentFilters.selectedSrmTeamName || '',
      disposition: currentFilters.selectedDisposition,
    };

    switch (action) {
      case 'QATeamBreakdownGraph':
        setIsQATeamBreakdownGraphLoading(true);
        return getQATeamBreakdown(payload);
      case 'QAMetricsGraph':
        setIsQAMetricsGraphLoading(true);
        return getQAMetrics(payload);
      case 'overviewGraph':
        setIsOverviewGraphLoading(true);
        return getStatsOverview(payload);
      case 'overviewSrm':
        setIsSrmTableLoading(true);
        return getStatsOverviewSrm(payload);
      case 'overviewSrmCsv':
        setIsSrmTableLoading(true);
        return getStatsOverviewSrmCsv(payload);
    }
  };

  const onChangeFilterValue = async (value, stateName, debounced) => {
    switch (stateName) {
      case 'selectedDate':
        setCurrentFilters({
          ...currentFilters,
          startDate: value.startDate,
          endDate: value.endDate,
        });
        break;
      case 'selectedTime':
        setCurrentFilters({
          ...currentFilters,
          startTime: value.startTime,
          endTime: value.endTime,
        });
        break;
      case 'keywordGroupName':
        if (!value) {
          setCurrentFilters({
            ...currentFilters,
            keywordGroupName: '',
            groupedKeywords: [],
          });
        } else {
          const selectedGroupKeywords = await getGroupKeywords(value);
          setCurrentFilters({
            ...currentFilters,
            keywordGroupName: value,
            keywordSearchText: '',
            groupedKeywords: selectedGroupKeywords[0].keywords,
          });
          setKeywordSearchTextUrl('');
        }
        break;
      case 'keywordSearchText':
        setKeywordSearchTextUrl(value);
        break;
      case 'supplierSearchText':
        setSupplierSearchTextUrl(value);
        break;
      case 'phoneSearchText':
        setPhoneSearchTextUrl(value);
        break;
      case 'selectedSrmTeamName':
        setCurrentFilters({
          ...currentFilters,
          selectedSrmTeamMembers: value.teamMembers,
          selectedSrmTeamName: value.teamName,
        });
        break;
      case 'selectedTags':
        setCurrentFilters({
          ...currentFilters,
          selectedTags: value,
        });
        break;
      default:
        setCurrentFilters({
          ...currentFilters,
          [stateName]: value,
        });
        break;
    }

    if (
      stateName === 'keywordSearchBy' &&
      currentFilters.keywordSearchTextUrl === '' &&
      currentFilters.groupedKeywords.length === 0
    )
      return false;
    if (debounced) resetStatsDebounced();
    else resetStats();
  };

  const resetStats = () => {
    setOverviewGraphData([]);
    setSrmTableData([]);
    setQATeamBreakdownGraphData([]);
    setQAMetricsGraphData([]);
    setIsLoading(true);
  };

  // Limits the resetStats() firing by waiting for input to end for 2 seconds
  const resetStatsDebounced = useCallback(debounce(resetStats, 2000), []);

  // Cleans up url (removes filter query params), returns filters to default state
  const clearFilters = () => {
    history.push({ search: '' });

    setCurrentFilters(defaultFilters);
    setKeywordSearchTextUrl('');
    setSupplierSearchTextUrl('');
    setPhoneSearchTextUrl('');
    setIsLoading(true);
  };

  return (
    <DocumentTitle title="SRM Calls | Stats">
      <main>
        <section>
          <Filters
            onChangeValue={onChangeFilterValue}
            currentPageName="stats"
            currentFilters={currentFilters}
            showClearFilterButton={showClearFilterButton}
            clearFiltersCallback={clearFilters}
            tagList={tagList}
          />
          <section className="slds-grid slds-wrap stats-grid">
            <section className="slds-col srm-graph-container">
              <article className="slds-card">
                <div className="info-tab">
                  {overviewGraphData.length > 0 && <OverviewGraph overviewGraphData={overviewGraphData} />}
                  {!isOverviewGraphLoading && overviewGraphData.length <= 0 && (
                    <ScopedNotification theme="light" className="no-records">
                      <p>Sorry, no data found with the selected filter parameters.</p>
                    </ScopedNotification>
                  )}
                  {isOverviewGraphLoading && <Spinner />}
                </div>
              </article>
            </section>
            {qaPermission && (
              <section className="slds-col srm-graph-container">
                <article className="slds-card">
                  <div className="info-tab">
                    {QAMetricsGraphData.length > 0 && <QAGraph QAGraphData={QAMetricsGraphData} />}
                    {!isQAMetricsGraphLoading && QAMetricsGraphData.length <= 0 && (
                      <ScopedNotification theme="light" className="no-records">
                        <p>Sorry, no data found with the selected filter parameters.</p>
                      </ScopedNotification>
                    )}
                    {isQAMetricsGraphLoading && <Spinner />}
                  </div>
                </article>
              </section>
            )}
            {qaPermission && currentFilters.selectedSrmTeamName !== '' && (
              <section className="slds-col srm-graph-container">
                <article className="slds-card">
                  <div className="info-tab">
                    {QATeamBreakdownGraphData.length > 0 && (
                      <QAGraph teamName={currentFilters.selectedSrmTeamName} QAGraphData={QATeamBreakdownGraphData} />
                    )}
                    {!isQATeamBreakdownGraphLoading && QATeamBreakdownGraphData.length <= 0 && (
                      <ScopedNotification theme="light" className="no-records">
                        <p>Sorry, no data found with the selected filter parameters.</p>
                      </ScopedNotification>
                    )}
                    {isQATeamBreakdownGraphLoading && <Spinner />}
                  </div>
                </article>
              </section>
            )}
            <section className="slds-col srm-table-container">
              <article className="slds-card">
                <div className="info-tab">
                  {srmTableData.length > 0 && (
                    <SrmStats
                      srmCSVStats={srmCSVStats}
                      srmCSVHeader={srmCSVHeader}
                      srmStats={srmTableData}
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      sortSrmStats={sortSrmStats}
                    />
                  )}
                  {!isSrmTableLoading && srmTableData.length <= 0 && (
                    <ScopedNotification theme="light" className="no-records">
                      <p>Sorry, no data found with the selected filter parameters.</p>
                    </ScopedNotification>
                  )}
                  {isSrmTableLoading && <Spinner />}
                </div>
              </article>
            </section>
          </section>
        </section>
      </main>
    </DocumentTitle>
  );
};

export default StatsPage;
