import { PureComponent, Fragment } from 'react';
import { Badge, Button, Dropdown, DropdownTrigger, Popover, Slider, Spinner } from '@salesforce/design-system-react';
import { map, random } from 'lodash';
import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

const oneHour = 3600;
const speedDropdownValues = [
  { label: '0.5x', value: 0.5 },
  { label: '1x', value: 1 },
  { label: '1.5x', value: 1.5 },
  { label: '2x', value: 2 },
  { label: '2.5x', value: 2.5 },
];

class WavesurferPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAudioPlaying: false,
      isAudioLoaded: false,
      playbackRate: 1,
      audioVolume: 0.5,
      formattedCurrentTime: props.audioLength < oneHour ? '0:00' : '0:00:00',
    };
  }

  componentDidMount = () => this.loadPlayer();
  componentWillUnmount = () => this.wavesurfer.destroy();
  componentDidUpdate = prevProps => {
    if (prevProps.regions !== this.props.regions) this.loadRegions();
    if (prevProps.splitChannel !== this.props.splitChannel) this.resetPlayer();
    if (prevProps.audioTime !== this.props.audioTime && this.props.forceUpdateAudio && this.state.isAudioLoaded) {
      this.wavesurfer.setCurrentTime(this.props.audioTime);
      this.props.setForceUpdateAudioCallback(false);
    }
    // After submitting a review, disable ability to create new regions
    if (prevProps.regionMode !== this.props.regionMode && this.props.regionMode === 'view')
      this.wavesurfer.disableDragSelection();
  };

  resetPlayer = () => {
    this.wavesurfer.destroy();
    this.setState({ isAudioLoaded: false });
    this.loadPlayer();
  };

  formatTime = (time, includeNotes) => {
    const formattedTimestamp = moment
      .duration(time, 'seconds')
      .format('h:mm:ss', { stopTrim: this.props.audioLength < oneHour ? 'm' : 'h' });
    if (!includeNotes) return formattedTimestamp;
    else {
      const currentRegion = this.getRegionByTime(time);
      if (!currentRegion) return formattedTimestamp;
      else if (currentRegion.data.note.length > 45)
        return `${formattedTimestamp} [${currentRegion.data.note.substring(0, 46)}...]`;
      else return `${formattedTimestamp} [${currentRegion.data.note}]`;
    }
  };

  getRegionByTime = time =>
    this.props.regions &&
    this.props.regions.find(regionDetails => time >= regionDetails.start && time <= regionDetails.end);

  loadPlayer = () => {
    const {
      splitChannel,
      dualChannel,
      audioSrc,
      audioLength,
      onRegionClickEvent,
      onRegionInEvent,
      regionMode,
    } = this.props;
    if (WaveSurfer) {
      const wavesurferBaseConfig = {
        backend: 'MediaElement',
        container: document.querySelector('#wavesurfer-player'),
        splitChannels: splitChannel && dualChannel,
        skipLength: 10,
        barWidth: 1,
        barRadius: 2,
        fillParent: true,
        normalize: true,
        responsive: true,
        waveColor: '#999999',
        progressColor: '#565656',
        plugins: [
          CursorPlugin.create({
            showTime: true,
            opacity: 1,
            formatTimeCallback: time => this.formatTime(time, true),
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '6px 8px',
              'font-size': '14px',
              visibility: 'visible',
              'border-radius': '0 0.3rem 0.3rem 0',
            },
          }),
        ],
      };

      if (regionMode) wavesurferBaseConfig.plugins.push(RegionsPlugin.create());
      // Processing of wavefile is limited to 1 hour call length due to performance load
      // Display a placeholder wavefile instead for longer calls
      if (audioLength < oneHour) {
        this.wavesurfer = WaveSurfer.create(wavesurferBaseConfig);
        this.wavesurfer.load(audioSrc);
        this.wavesurfer.on('waveform-ready', () => this.handleWavesurferEvent('ready'));
      } else {
        this.wavesurfer = WaveSurfer.create(wavesurferBaseConfig);
        this.wavesurfer.load(audioSrc, this.buildPlaceholderWaveform(), 'auto', audioLength);
        this.wavesurfer.on('ready', () => this.handleWavesurferEvent('ready'));
      }

      this.wavesurfer.on('play', () => this.handleWavesurferEvent('play'));
      this.wavesurfer.on('pause', () => this.handleWavesurferEvent('pause'));
      this.wavesurfer.on('audioprocess', () => this.handleWavesurferEvent('audioprocess'));
      this.wavesurfer.on('seek', () => this.handleWavesurferEvent('seek'));
      this.wavesurfer.on('finish', () => this.handleWavesurferEvent('finish'));

      if (regionMode) {
        this.wavesurfer.on('region-click', onRegionClickEvent);
        this.wavesurfer.on('region-update-end', event => this.handleWavesurferEvent('region-update-end', event));
        this.wavesurfer.on('region-in', onRegionInEvent);
        this.wavesurfer.on('region-out', () => this.handleWavesurferEvent('region-out'));
      }
    }
  };

  playAudio = () => {
    this.setState({ isAudioPlaying: !this.state.isAudioPlaying });
    this.wavesurfer.playPause();
  };

  setPlaybackRate = ({ value }) => {
    this.wavesurfer.setPlaybackRate(value);
    this.setState({ playbackRate: value });
  };

  setAudioVolume = value => {
    this.setState({ audioVolume: value });
    this.wavesurfer.setVolume(value);
  };

  handleWavesurferEvent = (eventKey, event) => {
    const { onEvent, audioTime, regions, regionMode, onRegionOutEvent, onRegionUpdateEvent } = this.props;
    switch (eventKey) {
      case 'play':
      case 'pause':
        onEvent(eventKey);
        break;
      case 'audioprocess':
      case 'seek':
        {
          const time = this.wavesurfer.getCurrentTime();
          this.setState({ formattedCurrentTime: this.formatTime(time) });
          onEvent(eventKey, Math.round(time * 100) / 100);
        }
        break;
      case 'finish':
        this.setState({ isAudioPlaying: false });
        break;
      case 'ready':
        this.setState({ isAudioLoaded: true }, () => {
          onEvent('ready');
          if (audioTime) this.wavesurfer.skipForward(audioTime);
          if (this.state.isAudioPlaying) this.wavesurfer.play();
        });
        if (regionMode === 'edit') this.wavesurfer.enableDragSelection({ color: 'rgba(0, 0, 0, 0.3)' });
        if (regions) this.loadRegions();
        break;
      case 'region-out':
        onRegionOutEvent(this.wavesurfer.getCurrentTime() + 0.1);
        break;
      case 'region-update-end':
        onRegionUpdateEvent(event);
        break;
      default:
        break;
    }
  };

  loadRegions = () => {
    this.wavesurfer.clearRegions();
    const regions = this.props.regions || [];
    regions.forEach(region => {
      if (this.props.regionMode !== 'edit') {
        region.drag = false;
        region.resize = false;
      }
      this.wavesurfer.addRegion(region);
    });
  };

  buildPlaceholderWaveform = () => {
    const numberOfPeaks = 450;
    const peakMaxHeight = 350;
    const peakMinHeight = 0;
    return map(Array(numberOfPeaks), () => random(peakMinHeight, peakMaxHeight));
  };

  render() {
    const { isAudioPlaying, isAudioLoaded, playbackRate, audioVolume, formattedCurrentTime } = this.state;
    const { onRegionSeek, regions, audioLength } = this.props;

    return (
      <Fragment>
        <div id="wavesurfer-player" />
        {!isAudioLoaded && <Spinner />}
        <div className="audio-control-container slds-grid slds-grid_vertical-align-center">
          <div className="slds-col slds-size_4-of-12">
            <ul className="slds-list_horizontal slds-has-inline-block-links_space slds-grid_vertical-align-center secondary-audio-control-container">
              <li className="slds-item">
                <Dropdown
                  buttonVariant="icon"
                  length="7"
                  iconName="settings"
                  iconCategory="utility"
                  iconSize="large"
                  iconVariant="more"
                  align="right"
                  onSelect={this.props.handleSettingChange}
                  options={this.props.transcriptSettingOptions}
                />
              </li>
              <li className="slds-item">
                <Dropdown onSelect={this.setPlaybackRate} options={speedDropdownValues}>
                  <DropdownTrigger className="speed-dropdown-container">
                    <Button label={`${playbackRate}x`} />
                  </DropdownTrigger>
                </Dropdown>
              </li>
              <li className="slds-item">
                <Popover
                  body={
                    <Slider
                      step={0.025}
                      max={1}
                      value={audioVolume}
                      size="x-small"
                      onChange={(event, { value }) => this.setAudioVolume(value)}
                    />
                  }
                  heading="Audio Control"
                  align="right"
                >
                  <Button
                    assistiveText={{ icon: 'Volume' }}
                    iconCategory="utility"
                    iconName="volume_high"
                    iconSize="small"
                    iconVariant="bare"
                    variant="icon"
                    className="volume-button"
                  />
                </Popover>
              </li>
            </ul>
          </div>
          <div className="slds-col slds-size_4-of-12 slds-text-align_center">
            <Button
              assistiveText={{ icon: 'Go Previous' }}
              iconCategory="utility"
              iconName="jump_to_top"
              iconSize="small"
              iconVariant="bare"
              onClick={() => this.wavesurfer.skipBackward()}
              variant="icon"
              className="audio-control go-prev"
              disabled={!isAudioLoaded}
            />
            <Button
              assistiveText={{ icon: 'Play/Pause' }}
              iconCategory="utility"
              iconName={isAudioPlaying ? 'pause' : 'play'}
              iconSize="small"
              iconVariant="bare"
              onClick={() => this.playAudio()}
              variant="icon"
              className="audio-control play-pause"
            />
            <Button
              assistiveText={{ icon: 'Go Forward' }}
              iconCategory="utility"
              iconName="jump_to_top"
              iconSize="small"
              iconVariant="bare"
              onClick={() => this.wavesurfer.skipForward()}
              variant="icon"
              className="audio-control go-next"
              disabled={!isAudioLoaded}
            />
          </div>
          <div className="slds-col slds-size_4-of-12">
            <div className="slds-grid slds-grid_align-end slds-grid_vertical-align-center">
              {regions && regions.length > 0 && (
                <div className="slds-grid slds-wrap slds-grid--align-center slds-grid_vertical-align-center">
                  <div className="slds-col slds-p-right_xx-small">
                    <p className="region-label">Region:</p>
                  </div>
                  <div className="slds-col slds-grow-none slds-p-right_xx-small">
                    <Button
                      assistiveText={{ icon: 'Previous Region' }}
                      iconCategory="utility"
                      iconName="arrowup"
                      iconSize="small"
                      iconVariant="bare"
                      onClick={() => onRegionSeek('backward')}
                      variant="icon"
                      className="audio-control go-prev"
                      disabled={!isAudioLoaded}
                    />
                    <Button
                      assistiveText={{ icon: 'Next Region' }}
                      iconCategory="utility"
                      iconName="arrowup"
                      iconSize="small"
                      iconVariant="bare"
                      onClick={() => onRegionSeek('forward')}
                      variant="icon"
                      className="audio-control go-next"
                      disabled={!isAudioLoaded}
                    />
                  </div>
                </div>
              )}
              <div className="slds-col slds-grow-none">
                <Badge content={`${formattedCurrentTime}/${this.formatTime(audioLength)}`} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WavesurferPlayer;
