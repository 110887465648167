import { round } from 'lodash';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more.js';
import HighchartsReact from 'highcharts-react-official';

const AggregatedScoreGauge = ({ score }) => {
  highchartsMore(Highcharts);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 40,
      width: 80,
    },
    title: {
      text: null,
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '77%'],
      background: null,
      size: '283%',
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          enabled: true,
        },
        dial: {
          baseLength: '0%',
          baseWidth: 2,
          radius: score >= 0 ? '100%' : '0%',
          rearLength: '0%',
          topWidth: 1,
        },
        pivot: {
          radius: score >= 0 ? '1%' : '0%',
        },
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      min: 0,
      max: 100,
      tickLength: 0,
      plotBands: [
        {
          from: 0,
          to: 33.33,
          color: 'rgb(192, 0, 0)', // red
          thickness: '50%',
        },
        {
          from: 33.33,
          to: 66.66,
          color: 'rgb(255, 192, 0)', // yellow
          thickness: '50%',
        },
        {
          from: 66.66,
          to: 100,
          color: 'rgb(155, 187, 89)', // green
          thickness: '50%',
        },
      ],
    },
    series: [
      {
        name: 'Call Score',
        data: [round(score * 100, 1)],
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="aggregated-score-gauge">
      <HighchartsReact highcharts={Highcharts} options={options} />
      <p className="aggregated-score-label">{score >= 0 ? round(score * 100, 1) + '%' : ' '}</p>
    </div>
  );
};

export default AggregatedScoreGauge;
