import { createRef, PureComponent } from 'react';
import { Icon, Button } from '@salesforce/design-system-react';
import { CSVLink } from 'react-csv';

class SrmStats extends PureComponent {
  constructor(props) {
    super(props);
    this.srmStats = createRef();
  }

  render() {
    const { srmStats, sortSrmStats, sortColumn, sortDirection, srmCSVStats, srmCSVHeader } = this.props;
    const sortIconName = sortDirection === 'DESC' ? 'arrowdown' : 'arrowup';
    return (
      <div className="srm-stats">
        <CSVLink data={srmCSVStats} headers={srmCSVHeader} filename={`${new Date().toISOString()}-srmStats.csv`}>
          <Button
            className="download-csv-button"
            variant="base"
            iconCategory="doctype"
            iconName="csv"
            iconSize="large"
          />
        </CSVLink>
        <table className="slds-table slds-table_bordered srm-stats-table slds-table--fixed-layout">
          <thead>
            <tr className="slds-line-height_reset">
              <th
                width="160"
                scope="col"
                className={`slds-is-sortable slds-is-sorted ${sortColumn === 'localName' ? 'sortActive' : ''}`}
                onClick={() => sortSrmStats('localName')}
              >
                <div className="slds-truncate" title="SRM Name">
                  <span>SRM / Ring Group</span>
                  <Icon
                    category="utility"
                    name={`${sortColumn === 'localName' ? sortIconName : 'sort'}`}
                    size="xx-small"
                    className="slds-is-sortable__icon"
                  />
                </div>
              </th>
              <th
                width="130"
                scope="col"
                className={`slds-is-sortable slds-is-sorted ${sortColumn === 'totalCount' ? 'sortActive' : ''}`}
                onClick={() => sortSrmStats('totalCount')}
              >
                <div className="slds-truncate" title="Total # of Calls">
                  <span>Total # of Calls</span>
                  <Icon
                    category="utility"
                    name={`${sortColumn === 'totalCount' ? sortIconName : 'sort'}`}
                    size="xx-small"
                    className="slds-is-sortable__icon"
                  />
                </div>
              </th>
              <th
                width="100"
                scope="col"
                className={`slds-is-sortable slds-is-sorted ${sortColumn === 'avgMinutes' ? 'sortActive' : ''}`}
                onClick={() => sortSrmStats('avgMinutes')}
              >
                <div title="Avg Length of Call">
                  <span className="slds-truncate">Avg. TT</span>
                  <Icon
                    category="utility"
                    name={`${sortColumn === 'avgMinutes' ? sortIconName : 'sort'}`}
                    size="xx-small"
                    className="slds-is-sortable__icon"
                  />
                </div>
              </th>
              <th
                width="100"
                scope="col"
                className={`slds-is-sortable slds-is-sorted slds-text-align_left ${
                  sortColumn === 'totalMinutes' ? 'sortActive' : ''
                }`}
                onClick={() => sortSrmStats('totalMinutes')}
              >
                <div title="Total Length of Calls">
                  <span className="slds-truncate">Total TT</span>
                  <Icon
                    category="utility"
                    name={`${sortColumn === 'totalMinutes' ? sortIconName : 'sort'}`}
                    size="xx-small"
                    className="slds-is-sortable__icon"
                  />
                </div>
              </th>
              <th scope="col">
                <div>
                  <div className="slds-truncate" title="# of Calls by Length">
                    <div># of Calls by Length</div>
                    <hr />
                    <div className="total-call-length-header">
                      <div>&lt;1m</div>
                      <div>1m-5m</div>
                      <div>5m-10m</div>
                      <div>10m-20m</div>
                      <div>20m+</div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <div className="slds-scrollable" ref={this.srmStats}>
          <table className="slds-table slds-table_bordered srm-stats-table slds-table--fixed-layout">
            <tbody>
              {srmStats.map((stat, index) => (
                <tr key={`${stat.localName.replace(/ /g, '')}-${index}`}>
                  <td width="160">
                    <div className="slds-truncate" title="SRM Name">
                      {stat.localName}
                    </div>
                  </td>
                  <td width="130">
                    <div className="slds-truncate" title="Total # Of Calls">
                      {stat.totalCount}
                    </div>
                  </td>
                  <td width="100">
                    <div className="slds-truncate" title="Avg Length Of Call">
                      {stat.avgMinutes}m
                    </div>
                  </td>
                  <td width="100">
                    <div className="slds-truncate slds-text-align_right" title="Total Length Of Calls">
                      {stat.totalMinutes}m
                    </div>
                  </td>
                  <td title="# Of Calls By Length">
                    <div className="total-call-length">
                      <div>{stat.mins1Count}</div>
                      <div>{stat.mins5Count}</div>
                      <div>{stat.mins10Count}</div>
                      <div>{stat.mins20Count}</div>
                      <div>{stat.mins20PCount}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SrmStats;
