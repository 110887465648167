import { cloneElement, useState } from 'react';
import { Button, Popover } from '@salesforce/design-system-react';

const DeleteConfirmation = ({ onDelete, heading, body, buttonVariant, clearType, hideType, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleCancel = () => setIsOpen(false);
  const handleDelete = () => {
    setIsOpen(false);
    onDelete();
  };

  return (
    <Popover
      isOpen={isOpen}
      variant="warning"
      body={body}
      heading={heading}
      position="overflowBoundaryElement"
      onClose={handleCancel}
      onRequestClose={handleCancel}
      footer={
        <div className="slds-text-align_center">
          <Button variant="brand" label="Yes" onClick={handleDelete} />
          <Button label="No" onClick={handleCancel} />
        </div>
      }
    >
      {children ? (
        cloneElement(children, { onClick: handleOpen })
      ) : (
        <Button
          className="slds-button-utility"
          assistiveText={{ icon: clearType ? 'Clear' : 'Delete' }}
          iconCategory="utility"
          iconName={clearType ? 'clear' : hideType ? 'hide' : 'delete'}
          iconSize="small"
          onClick={handleOpen}
          variant={buttonVariant}
          title="Delete"
        />
      )}
    </Popover>
  );
};

export default DeleteConfirmation;
