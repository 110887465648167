import { Route, Switch } from 'react-router-dom';

import Nav from './components/Nav';
import NoMatch from './components/NoMatch';
import CallList from './components/CallList';
import SingleCall from './components/SingleCall';
import AnnotateCall from './components/AnnotateCall';
import LocalhostRedirect from './components/LocalhostRedirect';
import StatsPage from './components/StatsPage';
import SpeechHints from './components/SpeechHints';
import KeywordGroups from './components/KeywordGroups';
import SrmTeams from './components/SrmTeams';
import BlockedNumbers from './components/BlockedNumbers';
import QATemplates from './components/QATemplates';

const Routes = () => (
  <section>
    <Nav />
    <Switch>
      <Route exact path="/">
        <CallList key="home" listType="home" listTitle="All Calls" />
      </Route>
      <Route path="/my-calls">
        <CallList key="my-calls" listType="my-calls" listTitle="My Calls" />
      </Route>
      <Route path="/account/:accountId">
        <CallList key="account" listType="account" />
      </Route>
      <Route path="/call/:recordingId">
        <SingleCall />
      </Route>
      <Route path="/call-library" exact>
        <CallList key="call-library" listType="call-library" listTitle="Call Library" />
      </Route>
      <Route path="/call-library/:recordingId" exact>
        <AnnotateCall annotationType="library" />
      </Route>
      <Route path="/call-library/:recordingId/edit">
        <AnnotateCall annotationType="library" isModifying />
      </Route>
      <Route path="/call-qas" exact>
        <CallList key="call-qas" listType="call-qas" listTitle="Call QAs" />
      </Route>
      <Route path="/call-qas/:recordingId" exact>
        <AnnotateCall annotationType="QA" />
      </Route>
      <Route path="/call-qas/:recordingId/edit">
        <AnnotateCall annotationType="QA" isModifying />
      </Route>
      <Route path="/stats">
        <StatsPage />
      </Route>
      <Route path="/srm-teams">
        <SrmTeams />
      </Route>
      <Route path="/speech-hints">
        <SpeechHints />
      </Route>
      <Route path="/keyword-groups">
        <KeywordGroups />
      </Route>
      <Route path="/blocked-numbers">
        <BlockedNumbers />
      </Route>
      <Route path="/qa-templates">
        <QATemplates />
      </Route>
      <Route path="/localhost">
        <LocalhostRedirect />
      </Route>
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  </section>
);

export default Routes;
