import { createRef, PureComponent } from 'react';
import { Combobox, Button, Icon } from '@salesforce/design-system-react';

class TimePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startTime: this.props.startTime || '',
      endTime: this.props.endTime || '',
      time: this.makeTimeList(),
      prev_startTime: '',
      prev_endTime: '',
      disableOnblurEvent: true,
      openStartTimePicker: false,
      openEndTimePicker: false,
      clearStartTimePicker: false,
      clearEndTimePicker: false,
    };
    this.startTimePickerReference = createRef();
    this.endTimePickerReference = createRef();
  }

  componentDidUpdate(prevProps) {
    const { startTime, endTime } = this.props;
    if (prevProps.startTime !== startTime || prevProps.endTime !== endTime) this.updateTimeField(startTime, endTime);
  }

  onTimeChange = (event, timeValue, stateName) => {
    timeValue = timeValue.replace(/[^0-9: aApPmM]/, '');
    this.setState({
      [stateName]: timeValue,
      disableOnblurEvent: false,
      clearStartTimePicker: !!(timeValue.length === 0 && stateName === 'endTime'),
      clearEndTimePicker: !!(timeValue.length === 0 && stateName === 'startTime'),
    });
  };

  onSelect = (time, stateName) => {
    this.setState(
      {
        [stateName]: time,
        [`prev_${stateName}`]: time,
        disableOnblurEvent: true,
        clearStartTimePicker: stateName !== 'endTime',
        clearEndTimePicker: stateName !== 'startTime',
      },
      this.applyFilter
    );
  };

  onClear = () => {
    this.setState(
      {
        endTime: '',
        startTime: '',
        prev_endTime: '',
        prev_startTime: '',
        clearEndTimePicker: true,
        clearStartTimePicker: true,
        disableOnblurEvent: true,
      },
      this.applyFilter
    );
  };

  applyFilter = () => {
    const { startTime, endTime, clearStartTimePicker, clearEndTimePicker } = this.state;

    if (startTime.length < 1 && !clearStartTimePicker) {
      this.setState({ openStartTimePicker: true, disableOnblurEvent: false, clearStartTimePicker: true });

      // TODO: This... is not great. Need to figure out a different way to handle this. Had to change to this mess after a recent update to SLDS React.
      // Old version: this.startTimePickerReference.current.instanceRef.inputRef.focus();
      // eslint-disable-next-line no-underscore-dangle
      this.startTimePickerReference.current._reactInternalFiber.child.child.stateNode.getInstance().inputRef.focus();
      return false;
    } else if (endTime.length < 1 && !clearEndTimePicker) {
      this.setState({ openEndTimePicker: true, disableOnblurEvent: false, clearEndTimePicker: true });
      // TODO: Same as above.
      // eslint-disable-next-line no-underscore-dangle
      this.endTimePickerReference.current._reactInternalFiber.child.child.stateNode.getInstance().inputRef.focus();
      return false;
    }
    this.props.onChangeValue({ startTime, endTime }, 'selectedTime');
  };

  validateTime = stateName => {
    if (this.state.disableOnblurEvent) return false;
    let time = this.state[stateName];
    let isValidTime = /^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]( [aApP][mM])$/.test(time);
    let isValidTimeWithoutSpace = /^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]( ?[aApP][mM])$/.test(time);
    let isValidHourMinute = /^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]$/.test(time);
    let isValidHourTime = /^([0-1]?[0-9]|2[0-3])( [aApP][mM])$/.test(time);
    let isValidHourTimeWithoutSpace = /^([0-1]?[0-9]|2[0-3])( ?[aApP][mM])$/.test(time);

    let prevState = this.state[`prev_${stateName}`];
    const timeArray = time.split(':');

    if (timeArray.length > 0) {
      const hour = timeArray.includes(':') ? timeArray[0] : timeArray[0].slice(0, 2).replace(/[^0-9]/, '');
      const minutes = timeArray[1] ? timeArray[1].slice(0, 2).replace(/[^0-9]/, '') : 0;

      if (Number(hour) > 12 || Number(minutes) >= 60) {
        isValidTime = false;
        isValidHourMinute = false;
        isValidTimeWithoutSpace = false;
        isValidHourTime = false;
        isValidHourTimeWithoutSpace = false;
      }
    }

    if (isValidTime || time.length === 0 || isValidHourTime) prevState = time;
    else if (isValidTimeWithoutSpace || isValidHourTimeWithoutSpace) {
      const ap = this.state[stateName].slice(-2);
      time = `${time.slice(0, time.length - 2)} ${ap}`;
      prevState = `${time.slice(0, time.length - 2)} ${ap}`;
    } else if (isValidHourMinute && prevState.length > 0) {
      const ap = prevState.slice(-2);
      time = `${time} ${ap}`;
      prevState = `${time} ${ap}`;
    } else time = prevState;

    this.setState(
      {
        [stateName]: time,
        [`prev_${stateName}`]: prevState,
        disableOnblurEvent: true,
      },
      this.applyFilter
    );
  };

  updateTimeField = (startTime, endTime) =>
    this.setState({
      startTime,
      endTime,
      prev_startTime: startTime,
      prev_endTime: endTime,
      disableOnblurEvent: true,
    });

  makeTimeList = () => {
    const minuteInterval = 30;
    const times = []; // time array
    let startTime = 0;
    const ap = ['AM', 'PM']; // AM-PM

    // loop to increment the time and push results in array
    for (let i = 0; startTime < 24 * 60; i++) {
      const hour = Math.floor(startTime / 60); // getting hours of day
      const hourFormat = hour % 12 === 0 ? 12 : hour % 12; // 24 hours format
      const minutes = startTime % 60; // getting minutes of the hour in 0-55 format
      times.push({
        id: i.toString(),
        label: `${`0${hourFormat}`.slice(-2)}:${`0${minutes}`.slice(-2)} ${ap[Math.floor(hour / 12)]}`,
        key: i,
      });
      startTime += minuteInterval;
    }
    return times;
  };

  render() {
    const { time, startTime, endTime, openStartTimePicker, openEndTimePicker } = this.state;

    return (
      <div className="slds-grid slds-gutters time-picker">
        <div className="slds-col time-picker">
          <Combobox
            events={{
              onChange: (event, { value }) => this.onTimeChange(event, value, 'startTime'),
              onSelect: (event, data) => {
                this.setState({ openStartTimePicker: false });
                this.onSelect(data.selection[0].label, 'startTime');
              },
              onFocus: () => this.setState({ disableOnblurEvent: true }),
              onBlur: () => this.validateTime('startTime'),
              onRequestOpen: () => this.setState({ openStartTimePicker: true, openEndTimePicker: false }),
              onRequestClose: () => this.setState({ openStartTimePicker: false }),
            }}
            options={time}
            value={startTime}
            variant="inline-listbox"
            labels={{ placeholder: 'From' }}
            isOpen={openStartTimePicker}
            predefinedOptionsOnly
            ref={this.startTimePickerReference}
          />
          <Icon category="action" name="defer" size="x-small" />
          <Button
            className="clear-button"
            iconCategory="utility"
            iconName="clear"
            iconSize="small"
            variant="icon"
            disabled={startTime === ''}
            onClick={this.onClear}
          />
        </div>
        <div className="slds-col time-picker">
          <Combobox
            events={{
              onChange: (event, { value }) => this.onTimeChange(event, value, 'endTime'),
              onSelect: (event, data) => {
                this.setState({ openEndTimePicker: false });
                this.onSelect(data.selection[0].label, 'endTime');
              },
              onFocus: () => this.setState({ disableOnblurEvent: true }),
              onBlur: () => this.validateTime('endTime'),
              onRequestOpen: () => this.setState({ openEndTimePicker: true, openStartTimePicker: false }),
              onRequestClose: () => this.setState({ openEndTimePicker: false }),
            }}
            options={time}
            value={endTime}
            variant="inline-listbox"
            labels={{ placeholder: 'To' }}
            isOpen={openEndTimePicker}
            ref={this.endTimePickerReference}
          />
          <Icon category="action" name="defer" size="x-small" />
          <Button
            className="clear-button"
            iconCategory="utility"
            iconName="clear"
            iconSize="small"
            variant="icon"
            disabled={endTime === ''}
            onClick={this.onClear}
          />
        </div>
      </div>
    );
  }
}

export default TimePicker;
