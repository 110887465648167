import { Component, createRef, Fragment, PureComponent } from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {
  InputIcon,
  Input,
  Checkbox,
  Button,
  Dropdown,
  DropdownTrigger,
  Icon,
  Popover,
  Combobox,
  comboboxFilterAndLimit,
} from '@salesforce/design-system-react';

import { checkUserRole } from '../auth';
import {
  getAgents,
  getCountries,
  getValidDates,
  getKeywordGroups,
  getSrmTeams,
  listCallQAAuthors,
} from '../call-utils';
import DayPickerHeader from './DayPickerHeader';
import { DATE_FORMAT, CALL_LANGUAGES, CALL_DISPOSITIONS } from '../constants';
import TimePicker from './TimePicker';

const qaPermission = checkUserRole('call_transcriber_review');

class Filters extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      agents: [],
      qaAuthors: [],
      validDates: [],
      numberOfDaysValue: '',
      lastDaysOptions: [7, 30, 60, 90, 180, 365],
      fromMonth: null,
      toMonth: null,
      selectedDates: {
        startDate: undefined,
        endDate: undefined,
      },
      audioLengths: [
        { label: 'No minimum', value: 0 },
        { label: '1 Minute', value: 60 },
        { label: '3 Minutes', value: 180 },
        { label: '5 Minutes', value: 300 },
        { label: '10 Minutes', value: 600 },
        { label: '30 Minutes', value: 1800 },
        { label: '60 Minutes', value: 3600 },
      ],
      orders: [
        { label: 'Most Recent', value: 'initiatedTime DESC' },
        { label: 'Oldest', value: 'initiatedTime ASC' },
        { label: 'Longest', value: 'audioLength DESC' },
        { label: 'Shortest', value: 'audioLength ASC' },
      ],
      countries: [],
      languages: [{ label: 'All Languages', value: null }, ...CALL_LANGUAGES],
      dispositions: [{ label: 'All Dispositions', value: null }, ...CALL_DISPOSITIONS],
      directions: [
        { label: 'All Directions', value: null },
        { label: 'Outbound', value: 'OUTBOUND' },
        { label: 'Inbound', value: 'INBOUND' },
      ],
      answerStatuses: [
        { label: 'All Statuses', value: null },
        { label: 'Answered', value: 'ANSWERED' },
        { label: 'No Answer', value: 'NOANSWER' },
      ],
      keywordSearchOptions: [
        { label: 'Both Speakers', value: 'Both' },
        { type: 'divider' },
        { label: 'SRM', value: 'SRM' },
        { type: 'divider' },
        { label: 'Supplier', value: 'Supplier' },
      ],
      srmTeams: [
        {
          label: 'All Teams',
          value: '',
          created: '',
          createdBy: '',
          id: '',
          teamBembers: [],
          teamName: '',
        },
      ],
      showDateFilter: true,
      showSrmAgentFilter: true,
      showCallLengthFilter: true,
      showTagFilter: true,
      showSupplierFilter: true,
      showKeywordFilter: true,
      showOrderByFilter: true,
      showHideUnknownSuppliersFilter: true,
      showHideVoicemailsFilter: false,
      showDualChannelFilter: true,
      showUntranscribedFilter: true,
      showCountryFilter: false,
      showLanguageFilter: false,
      showDirectionFilter: false,
      showAnswerStatusFilter: false,
      showAdvancedFilters: false,
      showPhoneNumberFilter: true,
      showSrmTeamFilter: true,
      isDatePickerOpen: false,
      showKeywordFilterOptions: true,
      groupList: [],
      groupSelection: [],
      phoneSearchText: this.props.currentFilters.phoneSearchText || '',
      keywordSearchText: this.props.currentFilters.keywordSearchText || '',
      supplierSearchText: this.props.currentFilters.supplierSearchText || '',
    };
    this.startDayPickerReference = createRef();
    this.endDayPickerReference = createRef();
  }

  componentDidMount() {
    this.hideFilterOptions();

    this.loadAgents();
    this.loadSrmTeams();
    this.loadCountries();
    this.loadValidDates();
    this.loadKeywordGroups();
    this.loadQAAuthors();

    const { startDate, endDate } = this.props.currentFilters;
    this.assignCalendarDate(startDate, endDate);
    this.selectKeywordGroup();
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, keywordGroupName, phoneSearchText, keywordSearchText, supplierSearchText } =
      this.props.currentFilters;

    if (prevProps.currentFilters.startDate !== startDate || prevProps.currentFilters.endDate !== endDate)
      this.assignCalendarDate(startDate, endDate);
    if (prevProps.currentFilters.keywordGroupName !== keywordGroupName) this.selectKeywordGroup();
    if (prevProps.currentFilters.keywordSearchText !== keywordSearchText) this.initFilterByProps('keywordSearchText');
    if (prevProps.currentFilters.phoneSearchText !== phoneSearchText) this.initFilterByProps('phoneSearchText');
    if (prevProps.currentFilters.supplierSearchText !== supplierSearchText)
      this.initFilterByProps('supplierSearchText');
  }

  initFilterByProps = propName => this.setState({ [propName]: this.props.currentFilters[propName] });

  assignCalendarDate = async (startDate, endDate) => {
    const selectedDates = {
      startDate: startDate ? new Date(startDate.replace(/-/g, '/')) : undefined,
      endDate: endDate ? new Date(endDate.replace(/-/g, '/')) : undefined,
    };
    await this.setState({ selectedDates });
  };

  loadAgents = async () => {
    if (this.props.currentPageName === 'my-calls') return;
    const agents = await getAgents(this.props.accountId, this.props.currentPageName);
    this.setState({ agents });
  };

  loadQAAuthors = async () => {
    if (this.props.currentPageName !== 'call-qas') return;
    const qaAuthors = await listCallQAAuthors();
    this.setState({ qaAuthors });
  };

  loadSrmTeams = async () => {
    const srmTeams = await getSrmTeams();
    srmTeams.map(team => {
      team.label = team.teamName;
      team.value = team.teamName;
    });
    this.setState({ srmTeams: [...this.state.srmTeams, ...srmTeams] });
  };

  loadCountries = async () => {
    const countries = await getCountries(this.props.accountId, this.props.currentPageName);
    this.setState({ countries });
  };

  loadValidDates = async () => {
    const { accountId, currentPageName } = this.props;
    const { validDates, fromMonth, toMonth } = await getValidDates(currentPageName, accountId);
    this.setState({ validDates, fromMonth, toMonth });
  };

  loadKeywordGroups = async () => {
    this.setState({ isLoading: true });
    const keywordGroup = await getKeywordGroups(0);
    if (keywordGroup.length > 0) {
      this.setState(
        {
          groupList: keywordGroup.map(group => ({
            id: group.id,
            label: group.groupName,
            subTitle: group.keywords.join(', '),
          })),
        },
        this.selectKeywordGroup
      );
    }
  };

  selectKeywordGroup = () =>
    this.setState({
      groupSelection: this.state.groupList.filter(group => group.label === this.props.currentFilters.keywordGroupName),
    });

  updateDate = (date, isStartDate, forceUpdate) => {
    if (forceUpdate) {
      if (isStartDate) {
        this.setState({ selectedDates: { ...this.state.selectedDates, startDate: date } }, () => {
          if (this.state.selectedDates.endDate) this.applyDateFilter();
          else if (this.state.selectedDates.startDate)
            this.endDayPickerReference.current.setState({ month: date, showOverlay: true });
        });
      } else this.setState({ selectedDates: { ...this.state.selectedDates, endDate: date } }, this.applyDateFilter);
    } else {
      let newDate = null;
      this.state.validDates.every(day => {
        const tempDate = new Date(day);
        if (tempDate.getFullYear() === date.getFullYear() && tempDate.getMonth() === date.getMonth()) {
          newDate = new Date(day);
          return false;
        }
        return true;
      });
      if (!newDate) newDate = new Date(this.state.validDates[this.state.validDates.length - 1]);
      if (isStartDate) this.startDayPickerReference.current.setState({ month: newDate });
      else this.endDayPickerReference.current.setState({ month: newDate });
      this.forceUpdate();
    }
  };

  applyDateFilter = () => {
    this.setState({ isDatePickerOpen: false });
    const { startDate, endDate } = this.state.selectedDates;
    const selectedDates = {
      startDate: startDate ? moment(startDate).format(DATE_FORMAT) : undefined,
      endDate: endDate ? moment(endDate).format(DATE_FORMAT) : undefined,
    };
    this.props.onChangeValue(selectedDates, 'selectedDate');
  };

  getLabel = (labelFor, value) => {
    const elementInList = this.state[labelFor].find(x => x.value === value);
    if (elementInList) return elementInList.label;
    else return 'No Tag Selected';
  };

  highlightSelectedDate = currentDay => {
    const { startDate, endDate } = this.state.selectedDates;
    if (
      (startDate && currentDay.toDateString() === startDate.toDateString()) ||
      (endDate && currentDay.toDateString() === endDate.toDateString())
    )
      return true;
    else if (currentDay >= startDate && currentDay <= endDate) return true;
    return false;
  };

  clearDateInput = endDate => {
    if (endDate)
      this.setState({ selectedDates: { ...this.state.selectedDates, endDate: undefined } }, this.applyDateFilter);
    else this.setState({ selectedDates: { ...this.state.selectedDates, startDate: undefined } }, this.applyDateFilter);
  };

  hideLoiteringDayPicker = isStart => {
    if (isStart && this.endDayPickerReference.current.state.showOverlay)
      this.endDayPickerReference.current.setState({ showOverlay: false });
    else if (!isStart && this.startDayPickerReference.current.state.showOverlay)
      this.startDayPickerReference.current.setState({ showOverlay: false });
    if (!isStart && this.state.selectedDates.startDate && !this.state.selectedDates.startDate)
      this.endDayPickerReference.current.setState({ month: this.state.selectedDates.startDate });
  };

  hideFilterOptions = () => {
    const { currentPageName } = this.props;
    const filterOptions = {
      showDateFilter: true,
      showSrmAgentFilter: true,
      showCallLengthFilter: true,
      showTagFilter: true,
      showSupplierFilter: true,
      showKeywordFilter: true,
      showOrderByFilter: true,
      showHideUnknownSuppliersFilter: true,
      showHideVoicemailsFilter: false,
      showDualChannelFilter: true,
      showUntranscribedFilter: true,
      showCountryFilter: true,
      showLanguageFilter: true,
      showDirectionFilter: true,
      showAnswerStatusFilter: true,
      showKeywordFilterOptions: true,
      showSrmTeamFilter: true,
      showTimeFilter: true,
      showQAAuthorFilter: false,
      showDispositionFilter: true,
    };

    switch (currentPageName) {
      case 'my-calls':
        filterOptions.showSrmAgentFilter = false;
        filterOptions.showSrmTeamFilter = false;
        break;
      case 'account':
        filterOptions.showSupplierFilter = false;
        filterOptions.showHideUnknownSuppliersFilter = false;
        break;
      case 'stats':
        filterOptions.showOrderByFilter = false;
        break;
      case 'call-library':
        filterOptions.showCallLengthFilter = false;
        filterOptions.showOrderByFilter = false;
        filterOptions.showHideUnknownSuppliersFilter = false;
        filterOptions.showHideVoicemailsFilter = false;
        filterOptions.showDualChannelFilter = false;
        filterOptions.showUntranscribedFilter = false;
        filterOptions.showKeywordFilterOptions = false;
        filterOptions.showSrmTeamFilter = false;
        filterOptions.showTimeFilter = false;
        break;
      case 'call-qas':
        filterOptions.showQAAuthorFilter = true;
        filterOptions.showSrmAgentFilter = qaPermission;
        filterOptions.showSrmTeamFilter = qaPermission;
        break;
      default:
        break;
    }

    this.setState({ ...filterOptions });
  };

  toggleAdvancedFilters = () => this.setState({ showAdvancedFilters: !this.state.showAdvancedFilters });

  setNumberOfDays = async days => {
    const endDate = this.state.selectedDates.endDate || new Date();
    const startDate =
      days === 0 ? new Date(this.endDayPickerReference.current.state.month.getFullYear(), 0, 1) : endDate;
    await this.assignCalendarDate(
      moment(startDate)
        .subtract(days === 0 ? days : days - 1, 'days')
        .format(DATE_FORMAT),
      moment(endDate).format(DATE_FORMAT)
    );
    this.applyDateFilter();
  };

  createDayPicker = (validDates, endDate, startDate, fromMonth, toMonth, isStart) => (
    <DayPickerInput
      dayPickerProps={{
        disabledDays: day => {
          if (validDates) {
            if (isStart) {
              if (endDate) {
                if (day <= endDate || day.toDateString() === endDate.toDateString())
                  return validDates.indexOf(day.toDateString()) === -1;
                else return true;
              } else return validDates.indexOf(day.toDateString()) === -1;
            } else if (startDate) {
              if (day >= startDate) {
                return validDates.indexOf(day.toDateString()) === -1;
              } else return true;
            } else return validDates.indexOf(day.toDateString()) === -1;
          } else return true;
        },
        fromMonth: validDates ? fromMonth : new Date(),
        toMonth: validDates ? toMonth : new Date(),
        initialMonth: (isStart ? startDate : endDate) || toMonth,
        modifiers: { start: startDate, end: endDate },
        numberOfMonths: 1,
        selectedDays: this.highlightSelectedDate.bind(isStart),
        captionElement: ({ date, localeUtils }) => (
          <DayPickerHeader
            date={date}
            localeUtils={localeUtils}
            onChange={day => this.updateDate(day, isStart, false)}
            yearStart={validDates ? fromMonth : new Date()}
            yearEnd={validDates ? toMonth : new Date()}
          />
        ),
      }}
      onDayPickerShow={() => this.hideLoiteringDayPicker(isStart)}
      placeholder={isStart ? 'From' : 'To'}
      onDayChange={day => this.updateDate(day, isStart, true)}
      value={isStart ? startDate : endDate}
      ref={isStart ? this.startDayPickerReference : this.endDayPickerReference}
      component={DayPickerSLDSInput}
      inputProps={{
        clearDisabled: isStart ? !startDate : !endDate,
        clearDateInput: () => this.clearDateInput(!isStart),
      }}
    />
  );

  onPhoneNumberChange = phoneNumber => {
    this.setState({ phoneSearchText: phoneNumber.trim().replace(/[^0-9( )[\]+-]/g, '') }, () =>
      this.props.onChangeValue(this.state.phoneSearchText, 'phoneSearchText', true)
    );
  };

  render() {
    const {
      agents,
      audioLengths,
      countries,
      languages,
      answerStatuses,
      directions,
      qaAuthors,
      validDates,
      fromMonth,
      toMonth,
      orders,
      selectedDates: { startDate, endDate },
      showDateFilter,
      showCallLengthFilter,
      showSrmAgentFilter,
      showTagFilter,
      showSupplierFilter,
      showKeywordFilter,
      showOrderByFilter,
      showHideUnknownSuppliersFilter,
      showHideVoicemailsFilter,
      showDualChannelFilter,
      showUntranscribedFilter,
      showCountryFilter,
      showLanguageFilter,
      showDirectionFilter,
      showAnswerStatusFilter,
      showAdvancedFilters,
      lastDaysOptions,
      numberOfDaysValue,
      isDatePickerOpen,
      showKeywordFilterOptions,
      keywordSearchOptions,
      groupList,
      groupSelection,
      phoneSearchText,
      keywordSearchText,
      showPhoneNumberFilter,
      supplierSearchText,
      srmTeams,
      showSrmTeamFilter,
      showTimeFilter,
      showQAAuthorFilter,
      showDispositionFilter,
      dispositions,
    } = this.state;

    const { onChangeValue, showClearFilterButton, clearFiltersCallback, tagList } = this.props;

    const {
      selectedAgent,
      hideUnknownSuppliers,
      hideUntranscribed,
      selectedQAAuthor,
      hideVoicemails,
      minAudioLength,
      selectedTags,
      selectedOrder,
      dualChannel,
      selectedCountry,
      selectedAnswerStatus,
      selectedDirection,
      selectedLanguage,
      startTime,
      endTime,
      keywordSearchBy,
      keywordGroupName,
      selectedSrmTeamName,
      selectedDisposition,
    } = this.props.currentFilters;

    const multiSelect = (
      <div className="date-filter-popover">
        <div className="slds-grid slds-gutters slds-grid_vertical-align-center slds-grid_align-center slds-m-bottom_x-small slds-m-horizontal_none">
          <p className="slds-m-right_x-small">Last: </p>
          {lastDaysOptions.map((day, i) => (
            <Button variant="base" label={day} key={i} onClick={() => this.setNumberOfDays(day)} />
          ))}
          <Button variant="base" label="YTD" onClick={() => this.setNumberOfDays(0)} />
          <form
            onSubmit={event => {
              event.preventDefault();
              this.setNumberOfDays(numberOfDaysValue);
            }}
          >
            <Input
              id="days-input"
              required
              type="number"
              value={numberOfDaysValue}
              placeholder="X"
              onChange={event =>
                this.setState({ numberOfDaysValue: event.target.value <= 0 ? '' : event.target.value })
              }
            />
          </form>
          <p> days</p>
        </div>
        <div className="multi-day-picker-wrapper">
          <div className="multi-day-picker day-from">
            {this.createDayPicker(validDates, endDate, startDate, fromMonth, toMonth, true)}
            <Icon category="utility" name="event" size="x-small" className="calendar-icon" />
          </div>
          <div className="multi-day-picker day-to">
            {this.createDayPicker(validDates, endDate, startDate, fromMonth, toMonth, false)}
            <Icon category="utility" name="event" size="x-small" className="calendar-icon" />
          </div>
        </div>
      </div>
    );

    return (
      <div className="filters">
        <section className="main-filters">
          <div className="slds-grid slds-gutters">
            <div className="slds-col slds-grid slds-wrap slds-gutters slds-m-bottom_none">
              {showKeywordFilter && (
                <div
                  className={`slds-col slds-grid keyword-search ${showKeywordFilterOptions ? 'has-filter-option' : ''}`}
                >
                  {showKeywordFilterOptions && (
                    <Dropdown
                      align="right"
                      iconCategory="utility"
                      iconName="down"
                      iconPosition="right"
                      label={this.getLabel('keywordSearchOptions', keywordSearchBy)}
                      value={keywordSearchBy}
                      onSelect={keywordSearchOption => onChangeValue(keywordSearchOption.value, 'keywordSearchBy')}
                      options={keywordSearchOptions}
                    />
                  )}

                  {groupList.length === 0 && (
                    <Input
                      className="keyword-search-input"
                      id="keyword-input"
                      placeholder="Keyword/Phrase"
                      value={keywordSearchText}
                      onChange={value => {
                        this.setState({ keywordSearchText: value });
                        onChangeValue(value.target.value, 'keywordSearchText', true);
                      }}
                      iconRight={
                        <InputIcon
                          name="clear"
                          category="utility"
                          disabled={!keywordSearchText}
                          onClick={() => {
                            this.setState({ keywordSearchText: '' });
                            onChangeValue('', 'keywordSearchText');
                          }}
                        />
                      }
                    />
                  )}

                  {groupList.length > 0 && (
                    <Combobox
                      className="keyword-search-group-dropdown"
                      options={comboboxFilterAndLimit({ options: groupList, selection: groupSelection })}
                      value={keywordSearchText}
                      variant="inline-listbox"
                      labels={{ placeholder: 'Keyword/Phrase' }}
                      selection={this.state.groupSelection}
                      events={{
                        onChange: (event, { value }) => {
                          this.setState({ keywordSearchText: value });
                          onChangeValue(value, 'keywordSearchText', true);
                        },
                        onSelect: (event, data) => {
                          this.setState({ keywordSearchText: '', groupSelection: data.selection });
                          onChangeValue(data.selection[0].label, 'keywordGroupName');
                        },
                        onRequestRemoveSelectedOption: (event, data) => {
                          this.setState({ groupSelection: data.selection, keywordSearchText: '' });
                          onChangeValue('', 'keywordGroupName');
                        },
                      }}
                    />
                  )}

                  {!keywordGroupName && (
                    <InputIcon
                      name="clear"
                      category="utility"
                      disabled={!keywordGroupName && groupList.length > 0 && !keywordSearchText}
                      className="clear-button"
                      onClick={() => {
                        this.setState({ keywordSearchText: '' });
                        onChangeValue('', keywordGroupName ? 'keywordGroupName' : 'keywordSearchText');
                      }}
                    />
                  )}
                </div>
              )}

              {showDateFilter && (
                <div className="date-filter slds-col">
                  <Popover
                    body={multiSelect}
                    align="bottom left"
                    heading="Select Date Range"
                    isOpen={isDatePickerOpen}
                    onRequestClose={() => {
                      this.setState({ isDatePickerOpen: false });
                    }}
                  >
                    <Button
                      label={`${startDate ? moment(startDate).format(DATE_FORMAT) : ''} ${
                        startDate && endDate ? '-' : ''
                      } ${endDate ? moment(endDate).format(DATE_FORMAT) : ''}${!startDate && !endDate ? 'Date' : ''}`}
                      className={`btn-date-input ${!startDate && !endDate ? 'placeholder' : ''}`}
                      onClick={() => {
                        this.setState({ isDatePickerOpen: true });
                      }}
                      iconCategory="utility"
                      iconName="event"
                      iconPosition="left"
                    />
                  </Popover>
                  <InputIcon
                    name="clear"
                    category="utility"
                    onClick={() =>
                      this.setState(
                        { selectedDates: { ...this.state.selectedDates, startDate: undefined, endDate: undefined } },
                        this.applyDateFilter
                      )
                    }
                    disabled={!startDate && !endDate}
                    className="clear-button"
                  />
                </div>
              )}

              {showOrderByFilter && (
                <div className="slds-col">
                  <Dropdown
                    align="right"
                    value={selectedOrder}
                    onSelect={order => onChangeValue(order.value, 'selectedOrder')}
                    options={orders}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('orders', selectedOrder)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
            </div>

            <div className="slds-col slds-text-align_right advanced-filters-option-container">
              <Button
                label="Clear Filters"
                variant="base"
                onClick={clearFiltersCallback}
                iconCategory="utility"
                iconName="close"
                iconPosition="left"
                disabled={!showClearFilterButton}
                className="slds-text-color_destructive"
              />
              <Button
                label={showAdvancedFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                iconName={showAdvancedFilters ? 'up' : 'down'}
                variant="base"
                iconCategory="utility"
                iconPosition="right"
                onClick={this.toggleAdvancedFilters}
              />
            </div>
          </div>
        </section>

        {showAdvancedFilters && (
          <section className="advanced-filters">
            <h5>Advanced Filters</h5>
            <div className="slds-grid slds-gutters slds-wrap">
              {showTimeFilter && (
                <div className="slds-col srm-filter time-picker-col">
                  <p>Time</p>
                  <TimePicker onChangeValue={onChangeValue} startTime={startTime} endTime={endTime} />
                </div>
              )}
              {showSrmAgentFilter && (
                <div className="slds-col srm-filter">
                  <p>SRM / Ring Group</p>
                  <Dropdown
                    align="right"
                    value={selectedAgent}
                    onSelect={agent => onChangeValue(agent.value, 'selectedAgent')}
                    options={agents}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('agents', selectedAgent)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showSrmTeamFilter && (
                <div className="slds-col srm-filter">
                  <p>SRM Team</p>
                  <Dropdown
                    align="right"
                    value={selectedSrmTeamName}
                    onSelect={team => onChangeValue(team, 'selectedSrmTeamName')}
                    options={srmTeams}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('srmTeams', selectedSrmTeamName)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showCallLengthFilter && (
                <div className="slds-col">
                  <p>Minimum Length</p>
                  <Dropdown
                    align="right"
                    value={minAudioLength}
                    onSelect={audioLength => onChangeValue(audioLength.value, 'minAudioLength')}
                    options={audioLengths}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('audioLengths', minAudioLength)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showTagFilter && (
                <div className="slds-col">
                  <p>Tags</p>
                  <TagFilterDropdown
                    tagList={tagList}
                    selectedTags={selectedTags}
                    onClose={tags => onChangeValue(tags, 'selectedTags')}
                  />
                </div>
              )}
              {showCountryFilter && (
                <div className="slds-col">
                  <p>Country</p>
                  <Dropdown
                    value={selectedCountry}
                    onSelect={country => onChangeValue(country.value, 'selectedCountry')}
                    options={countries}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('countries', selectedCountry)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showLanguageFilter && (
                <div className="slds-col">
                  <p>Language</p>
                  <Dropdown
                    value={selectedLanguage}
                    onSelect={language => onChangeValue(language.value, 'selectedLanguage')}
                    options={languages}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('languages', selectedLanguage)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showDirectionFilter && (
                <div className="slds-col">
                  <p>Direction</p>
                  <Dropdown
                    value={selectedDirection}
                    onSelect={direction => onChangeValue(direction.value, 'selectedDirection')}
                    options={directions}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('directions', selectedDirection)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showAnswerStatusFilter && (
                <div className="slds-col">
                  <p>Answer Status</p>
                  <Dropdown
                    value={selectedAnswerStatus}
                    onSelect={answerStatus => onChangeValue(answerStatus.value, 'selectedAnswerStatus')}
                    options={answerStatuses}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('answerStatuses', selectedAnswerStatus)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showDispositionFilter && (
                <div className="slds-col">
                  <p>Disposition</p>
                  <Dropdown
                    value={selectedDisposition}
                    onSelect={disposition => onChangeValue(disposition.value, 'selectedDisposition')}
                    options={dispositions}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('dispositions', selectedDisposition)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
              {showSupplierFilter && (
                <div className="slds-col">
                  <p>Supplier</p>
                  <Input
                    placeholder="Account Name/ID"
                    value={supplierSearchText}
                    onChange={value => {
                      this.setState({ supplierSearchText: value.target.value });
                      onChangeValue(value.target.value, 'supplierSearchText', true);
                    }}
                    iconRight={
                      <InputIcon
                        name="clear"
                        category="utility"
                        disabled={!supplierSearchText}
                        onClick={() => {
                          this.setState({ supplierSearchText: '' });
                          onChangeValue('', 'supplierSearchText');
                        }}
                      />
                    }
                  />
                </div>
              )}
              {showPhoneNumberFilter && (
                <div className="slds-col">
                  <p>Supplier Phone Number</p>
                  <Input
                    placeholder="Phone Number"
                    value={phoneSearchText}
                    onChange={value => this.onPhoneNumberChange(value.target.value)}
                    iconRight={
                      <InputIcon
                        name="clear"
                        category="utility"
                        disabled={!phoneSearchText}
                        onClick={() => {
                          this.setState({ phoneSearchText: '' });
                          onChangeValue('', 'phoneSearchText');
                        }}
                      />
                    }
                  />
                </div>
              )}
              {showQAAuthorFilter && (
                <div className="slds-col">
                  <p>QA Author</p>
                  <Dropdown
                    value={selectedQAAuthor}
                    onSelect={qaAuthor => onChangeValue(qaAuthor.value, 'selectedQAAuthor')}
                    options={qaAuthors}
                  >
                    <DropdownTrigger>
                      <Button
                        iconCategory="utility"
                        iconName="down"
                        iconPosition="right"
                        label={this.getLabel('qaAuthors', selectedQAAuthor)}
                      />
                    </DropdownTrigger>
                  </Dropdown>
                </div>
              )}
            </div>

            <div className="slds-grid slds-gutters slds-wrap">
              {showHideUnknownSuppliersFilter && (
                <div className="slds-col">
                  <Checkbox
                    className="filter-toggle"
                    id="unknown-toggle"
                    checked={hideUnknownSuppliers}
                    onChange={(event, { checked }) => onChangeValue(checked, 'hideUnknownSuppliers')}
                    labels={{ label: 'Hide Unknown Suppliers' }}
                  />
                </div>
              )}
              {showHideVoicemailsFilter && (
                <div className="slds-col">
                  <Checkbox
                    className="filter-toggle"
                    id="voicemail-toggle"
                    checked={hideVoicemails}
                    onChange={(event, { checked }) => onChangeValue(checked, 'hideVoicemails')}
                    labels={{ label: 'Hide Voicemails' }}
                  />
                </div>
              )}
              {showUntranscribedFilter && (
                <div className="slds-col">
                  <Checkbox
                    className="filter-toggle"
                    id="untranscribe-toggle"
                    checked={hideUntranscribed}
                    onChange={(event, { checked }) => onChangeValue(checked, 'hideUntranscribed')}
                    labels={{ label: 'Hide Untranscribed' }}
                  />
                </div>
              )}
              {showDualChannelFilter && (
                <div className="slds-col">
                  <Checkbox
                    className="filter-toggle"
                    id="dualchannel-toggle"
                    checked={dualChannel}
                    onChange={(event, { checked }) => onChangeValue(checked, 'dualChannel')}
                    labels={{ label: 'Only Dual Channel' }}
                  />
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }
}

class DayPickerSLDSInput extends Component {
  render() {
    const { clearDisabled, clearDateInput } = this.props;
    return (
      <Input
        {...this.props}
        iconRight={<InputIcon disabled={clearDisabled} name="clear" category="utility" onClick={clearDateInput} />}
      />
    );
  }
}

class TagFilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: props.selectedTags ? props.selectedTags.map(selectedTag => ({ id: selectedTag })) : [],
      checked: props.selectedTags ? props.selectedTags.map(selectedTag => ({ id: selectedTag })) : [],
    };
    this.state.inputValue = this.getInputString(this.state.checked);
    this.handleClose = this.handleClose.bind(this);
  }

  getIsChecked = tagId => this.state.checked.findIndex(el => el.id === tagId) > -1;

  getInputString = checked => {
    if (checked.length === 0) return 'No Tags Selected';
    else if (checked.length === 1) return '1 Tag Selected';
    else return `${checked.length} Tags Selected`;
  };

  handleCheckboxChange(targetChecked, tagId) {
    const { checked } = this.state;
    if (targetChecked) checked.push({ id: tagId });
    else
      checked.splice(
        checked.findIndex(el => el.id === tagId),
        1
      );

    const inputValue = this.getInputString(checked);
    this.setState({ inputValue, checked });
  }

  handleClose(event) {
    if (event && event.target.innerText === 'Done') {
      const checked = this.state.checked.length > 0 ? this.state.checked.slice(0) : [];
      this.setState({ selection: checked });
      this.props.onClose(checked.map(el => el.id));
    } else {
      const inputValue = this.getInputString(this.state.selection);
      const selection = this.state.selection.length > 0 ? this.state.selection.slice(0) : [];
      this.setState({ checked: selection, inputValue });
    }
  }

  render() {
    const { tagList } = this.props;
    const { inputValue } = this.state;

    return (
      <Combobox
        popover={
          <Popover
            body={
              <div>
                {tagList &&
                  tagList.map(tag => (
                    <Fragment key={tag.id}>
                      <Checkbox
                        className="maintag-checkbox"
                        id={tag.id}
                        labels={{ label: tag.displayName }}
                        onChange={(e, { checked }) => this.handleCheckboxChange(checked, tag.id)}
                        checked={this.getIsChecked(tag.id)}
                      />

                      {tag.subTags && (
                        <div className="subtag-list">
                          {tag.subTags.map(subtag => (
                            <Checkbox
                              className="subtag-checkbox"
                              id={subtag.id}
                              key={subtag.id}
                              labels={{ label: subtag.display_name }}
                              onChange={(e, { checked }) => this.handleCheckboxChange(checked, subtag.id)}
                              checked={this.getIsChecked(subtag.id)}
                            />
                          ))}
                        </div>
                      )}
                    </Fragment>
                  ))}
              </div>
            }
          />
        }
        events={{ onClose: this.handleClose }}
        value={inputValue}
        variant="popover"
        className="tag-filter-combobox"
      />
    );
  }
}

export default Filters;
