import { DataTableCell } from '@salesforce/design-system-react';
import DeleteConfirmation from './DeleteConfirmation';

const HintDeleteConfirmation = ({ onDelete, item }) => (
  <DataTableCell>
    <DeleteConfirmation
      onDelete={() => onDelete(item.id)}
      body="Are you sure you want to delete this phrase?"
      heading="Delete Phrase"
      buttonVariant="destructive"
    />
  </DataTableCell>
);

export default HintDeleteConfirmation;
