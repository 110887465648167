import moment from 'moment';
import { Badge, ScopedNotification, Spinner } from '@salesforce/design-system-react';

const MarketActivity = ({ activity, initiatedTime }) => (
  <section>
    {activity && activity.length > 0 && (
      <table className="slds-table slds-table_cell-buffer slds-table_bordered activity-table">
        <thead>
          <tr className="slds-line-height_reset">
            <th className="slds-text-title_caps" scope="col">
              <div title="Supplier Name">Supplier Name</div>
            </th>
            <th className="slds-text-title_caps" scope="col">
              <div title="Market">Market</div>
            </th>
            <th className="slds-text-title_caps" scope="col">
              <div title="Offer Type" />
            </th>
            <th className="slds-text-title_caps" scope="col">
              <div title="Offer">Offer</div>
            </th>
            <th className="slds-text-title_caps" scope="col">
              <div title="Status" />
            </th>
            <th className="slds-text-title_caps" scope="col">
              <div title="Time">Time After Call</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {activity.map((activityItem, index) => (
            <tr key={index} className="slds-hint-parent">
              <th data-label="Supplier Name" scope="row">
                <div className="slds-truncate">
                  <a
                    target="_blank"
                    href={`https://admin.c2fo.com/org/${activityItem.orgUuid}/division/${activityItem.divisionId}`}
                  >
                    {activityItem.divisionName}
                  </a>
                </div>
              </th>
              <td data-label="Market">
                <div className="slds-truncate">
                  <a target="_blank" href={`https://admin.c2fo.com/market/${activityItem.marketUuid}`}>
                    {activityItem.marketName}
                  </a>
                </div>
              </td>
              <td data-label="Offer Type">
                <div className="badge-cell">
                  {activityItem.isDiscountBidding ? (
                    <Badge className="offer-badge-disc" content="DISC" />
                  ) : (
                    <Badge className="offer-badge-apr" content="APR" />
                  )}
                </div>
              </td>
              <td data-label="Offer">
                <div className="slds-truncate">
                  {activityItem.isDiscountBidding
                    ? `${Number(activityItem.maxDiscount).toFixed(2)}%`
                    : `${Number(activityItem.maxApr).toFixed(2)}%`}
                </div>
              </td>
              <td data-label="Status">
                <div className="badge-cell">
                  {activityItem.isEnabled ? (
                    <Badge className="offer-badge-on" content="ON" />
                  ) : (
                    <Badge className="offer-badge-off" content="OFF" />
                  )}
                </div>
              </td>
              <td data-label="Time">
                <div className="slds-truncate">
                  {calculateMarketActivityTimeDiff(activityItem.created, initiatedTime)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
    {activity && activity.length <= 0 && (
      <div className="no-activity-info">
        <ScopedNotification theme="light">
          <p>No activity found in the 72 hours following this call</p>
        </ScopedNotification>
      </div>
    )}
    {!activity && (
      <div className="loading-activity">
        <Spinner />
        <div className="loading-activity-info">Loading activity...</div>
      </div>
    )}
  </section>
);

const calculateMarketActivityTimeDiff = (created, initiatedCallTime) => {
  // TECHDEBT: Use moment library for time formatting here

  const dayDiff = Math.floor(moment(created).diff(initiatedCallTime, 'days', true));
  const hourDiff = Math.floor(moment(created).diff(initiatedCallTime, 'hours', true)) - 24 * dayDiff;
  const minuteDiff =
    Math.floor(moment(created).diff(initiatedCallTime, 'minutes', true)) - 60 * 24 * dayDiff - 60 * hourDiff;
  const secondDiff =
    Math.floor(moment(created).diff(initiatedCallTime, 'seconds', true)) -
    60 * 60 * 24 * dayDiff -
    60 * 60 * hourDiff -
    60 * minuteDiff;

  const formattedTimeDiff = [];
  if (dayDiff >= 1) formattedTimeDiff.push(`${dayDiff} day${dayDiff > 1 ? 's' : ''}`);
  if (hourDiff >= 1) formattedTimeDiff.push(`${hourDiff} hour${hourDiff > 1 ? 's' : ''}`);
  if (minuteDiff >= 1) formattedTimeDiff.push(`${minuteDiff} minute${minuteDiff > 1 ? 's' : ''}`);
  if (secondDiff >= 1 && formattedTimeDiff.length < 1)
    formattedTimeDiff.push(`${secondDiff} second${secondDiff > 1 ? 's' : ''}`);

  return formattedTimeDiff.join(', ');
};

export default MarketActivity;
