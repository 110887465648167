import { useCallback, useEffect, useState, useMemo } from 'react';
import { find, throttle } from 'lodash';
import moment from 'moment';
import {
  Badge,
  Button,
  Dropdown,
  DropdownTrigger,
  Icon,
  Modal,
  Popover,
  ScopedNotification,
  Tabs,
  TabsPanel,
  Toast,
  ToastContainer,
} from '@salesforce/design-system-react';

import {
  getSentenceIndexByTime,
  getActivity,
  requestTranscript,
  hideCall,
  removeCallFromLibrary,
  updateTranscript,
  getCallQAData,
  getLibraryAnnotations,
  attachTag,
  detachTag,
  flattenTagList,
} from '../call-utils';
import { checkUserRole } from '../auth';
import { AUTO_SCROLL_INDEX_BUFFER, TRANSCRIPTION_LANGUAGES } from '../constants';
import useInterval from '../hooks/useInterval';

import CallInfo from './CallInfo';
import DeleteConfirmation from './DeleteConfirmation';
import LinkButton from './LinkButton';
import MarketActivity from './MarketActivity';
import TagList from './TagList';
import Transcript from './Transcript';
import WavesurferPlayer from './WavesurferPlayer';

const libraryPermission = checkUserRole('lab_prototypes');
const qaPermission = checkUserRole('call_transcriber_review');

const CallCard = ({
  listType,
  singleCall,
  call,
  time,
  loadingTranscript,
  hideCurrentCallCallback,
  updateLanguageCallback,
  updateAccountCallback,
  toggleLibraryCallback,
  toggleTagCallback,
  tagList,
  keywordSearchText,
  keywordSearchBy,
  groupedKeywords,
}) => {
  const {
    recordingId,
    callId,
    isQAed,
    audioSrc,
    srmName,
    audioLength,
    rawAudioLength,
    relatedAccounts,
    supplierPhone,
    notes,
    talkListenRatio,
    tags,
    initiatedTime,
    dualChannel,
    transcribed,
    supplierName,
    supplierId,
    callDirection,
    answerStatus,
    callLanguage,
    callCountry,
    possibleVoicemail,
    s3Key,
    s3Bucket,
  } = call;

  const [transcript, setTranscript] = useState(call.transcript);
  const [originalTranscript, setOriginalTranscript] = useState({});
  const [editTranscriptMode, setEditTranscriptMode] = useState(false);
  const [transcriptionInProgress, setTranscriptionInProgress] = useState(call.transcriptionInProgress);

  const flatTagList = useMemo(() => (tagList ? flattenTagList(tagList) : []), [tagList]);

  const [transcriptSettingOptions] = useState([
    { label: 'Download Audio', value: 'downloadAudio' },
    { type: 'divider' },
    {
      label: 'Redo Transcription',
      value: 'redoTranscription',
      disabled: call.transcriptionInProgress,
    },
    { type: 'divider' },
    { label: 'Combine Speakers', value: 'dualSpeakers' },
    { type: 'divider' },
    { label: 'Disable Auto-Scroll', value: 'autoScroll' },
    { type: 'divider' },
    { label: 'Enable Edit Mode', value: 'editMode' },
    { type: 'divider' },
    { label: 'Hide Call', value: 'hideCall' },
  ]);

  const [autoScroll, setAutoScroll] = useState(false);
  const [pauseAutoScroll, setPauseAutoScroll] = useState(true);
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(true);
  const [disableAutoScrollTimeout, setDisableAutoScrollTimeout] = useState(null);

  const [splitChannel, setSplitChannel] = useState(dualChannel);
  const [dualSpeakers, setDualSpeakers] = useState(true);
  const [hideCallModalOpen, setHideCallModalOpen] = useState(false);
  const [hideCallDescription, setHideCallDescription] = useState({
    label: 'Personal Call',
    value: 'personal_call',
  });

  const [activity, setActivity] = useState(null);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [audioTime, setAudioTime] = useState(time || 0);
  const [forceUpdateAudio, setForceUpdateAudio] = useState(false);

  const [qaAuthor, setQAAuthor] = useState();
  const [qaCreationTime, setQACreationTime] = useState();
  const [isInLibrary, setIsInLibrary] = useState(call.isInLibrary);
  const [libraryToastOpen, setLibraryToastOpen] = useState(false);
  const [regions, setRegions] = useState();
  const [, setSelectedRegion] = useState();

  const handleWavesurferAudioProcess = eventTime => setAudioTime(eventTime);
  const handleWavesurferAudioProcessThrottled = useCallback(throttle(handleWavesurferAudioProcess, 500), []);

  useEffect(() => {
    if (time) setCallTime(time);
    if (dualChannel) setSplitChannelOption();
    if (isQAed && listType === 'call-qas') loadQAAnnotations();
    if (isInLibrary && listType === 'call-library') loadLibraryAnnotations();
    if (relatedAccounts.length > 0 && singleCall) loadActivity();
    return () => clearTimeout(disableAutoScrollTimeout);
  }, []);

  useEffect(() => {
    if (call.transcript) {
      if (initiatedTime > '2019-05-28' && initiatedTime < '2019-08-29') {
        transcriptSettingOptions.push(
          ...[
            { type: 'divider' },
            {
              label: 'Show Transciption Errors',
              value: 'transcriptionErrors',
            },
          ]
        );

        setOriginalTranscript(call.transcript);
        const tempTranscript = call.transcript.filter(message => !['No.', 'no.', 'Huh?'].includes(message.message));
        setTranscript(tempTranscript);
      } else setTranscript(call.transcript);

      if (!autoScroll) enableAutoScroll();
      if (!singleCall && ((keywordSearchText && keywordSearchText.trim().length > 0) || groupedKeywords.length > 0))
        findCurrentSentence(call.transcript);
    }
  }, [call.transcript]);

  const setSplitChannelOption = () => {
    const label =
      localStorage.splitChannel && !JSON.parse(localStorage.splitChannel)
        ? 'Enable Split Channel'
        : 'Disable Split Channel';
    transcriptSettingOptions.push({ type: 'divider' }, { label, value: 'splitChannel' });
    if (localStorage.splitChannel) setSplitChannel(JSON.parse(localStorage.splitChannel));
  };

  const hideTranscriptionErrors = () => {
    find(transcriptSettingOptions, ['value', 'transcriptionErrors']).label = 'Show Transciption Errors';
    setOriginalTranscript(transcript);
    setTranscript(transcript.filter(message => !['No.', 'no.', 'Huh?'].includes(message.message)));
  };

  const showTranscriptionErrors = () => {
    find(transcriptSettingOptions, ['value', 'transcriptionErrors']).label = 'Hide Transciption Errors';
    setTranscript(originalTranscript);
  };

  const toggleTranscriptionErrors = label =>
    label === 'Show Transciption Errors' ? showTranscriptionErrors() : hideTranscriptionErrors();

  const setCallTime = callTime => {
    goToCurrentSentence(callTime);
    setAudioTime(callTime);
    setForceUpdateAudio(true);
  };

  const findCurrentSentence = newTranscript => {
    for (let i = 0; i < newTranscript.length; i++) {
      let isSearchedText;
      if (
        keywordSearchBy &&
        dualChannel &&
        (keywordSearchText || (groupedKeywords.length > 0 && !Array.isArray(groupedKeywords)))
      ) {
        isSearchedText =
          newTranscript[i].message
            .toLowerCase()
            .includes(keywordSearchText.toLowerCase() || groupedKeywords.toLowerCase()) &&
          ((keywordSearchBy === 'Supplier' && newTranscript[i].speakerTag === 1) ||
            (keywordSearchBy === 'SRM' && newTranscript[i].speakerTag === 2) ||
            keywordSearchBy === 'Both');
      } else if (
        keywordSearchBy &&
        !dualChannel &&
        (keywordSearchText || (groupedKeywords.length > 0 && !Array.isArray(groupedKeywords)))
      ) {
        isSearchedText = newTranscript[i].message
          .toLowerCase()
          .includes(keywordSearchText.toLowerCase() || groupedKeywords.toLowerCase());
      } else if (groupedKeywords.length > 0 && Array.isArray(groupedKeywords) && dualChannel) {
        for (let j = 0; j < groupedKeywords.length; j++) {
          isSearchedText =
            newTranscript[i].message.toLowerCase().includes(groupedKeywords[j].toLowerCase()) &&
            ((keywordSearchBy === 'Supplier' && newTranscript[i].speakerTag === 1) ||
              (keywordSearchBy === 'SRM' && newTranscript[i].speakerTag === 2) ||
              keywordSearchBy === 'Both');
          if (isSearchedText) break;
        }
      } else if (groupedKeywords.length > 0 && Array.isArray(groupedKeywords) && !dualChannel) {
        for (let j = 0; j < groupedKeywords.length; j++) {
          isSearchedText = newTranscript[i].message.toLowerCase().includes(groupedKeywords[j].toLowerCase());
          if (isSearchedText) break;
        }
      }

      if (isSearchedText) {
        const seekIndex = i - AUTO_SCROLL_INDEX_BUFFER;
        const seekTime = newTranscript[seekIndex >= 0 ? seekIndex : 0].startTime;
        setCallTime(seekTime >= 0 ? seekTime : 0);
        break;
      }
    }
  };

  const loadActivity = async () => {
    const tempActivity = await getActivity(recordingId, initiatedTime, relatedAccounts);
    setActivity(tempActivity);
  };

  const loadLibraryAnnotations = async () => {
    const annotationList = await getLibraryAnnotations(recordingId);
    const formattedAnnotationList = annotationList.notes.map(annotation => ({
      id: annotation.id,
      start: annotation.startTime,
      end: annotation.endTime,
      data: {
        note: annotation.message,
      },
    }));
    setRegions(formattedAnnotationList);
  };

  const loadQAAnnotations = async () => {
    const annotationList = await getCallQAData(recordingId);
    const formattedAnnotationList = annotationList.notes.map(annotation => ({
      id: annotation.id,
      start: annotation.startTime,
      end: annotation.endTime,
      data: {
        note: annotation.message,
        feedbackType: annotation.feedbackType,
      },
    }));
    setRegions(formattedAnnotationList);

    if (annotationList.authorName) setQAAuthor(annotationList.authorName);
    if (annotationList.created) setQACreationTime(moment(annotationList.created).format('LLL'));
  };

  const requestCallTranscript = async () => {
    // Hide the Request Transcript button, display transcriptionInProgress message
    setTranscriptionInProgress(true);

    // Send call directly to call-transcriber Lambda function
    requestTranscript(recordingId, s3Key, s3Bucket, callLanguage, callCountry);
  };

  const goToCurrentSentence = currentTime => {
    if (!transcript || (!currentTime && !isAudioReady)) return;
    else if (!currentTime && isAudioReady) currentTime = audioTime;

    const sentenceIndex = getSentenceIndexByTime(transcript, currentTime);
    const sentenceEl = document.getElementById(`${callId}-sentence-${sentenceIndex}`);
    if (!sentenceEl) return;

    sentenceEl.parentElement.parentElement.scrollTop =
      sentenceEl.offsetTop - (splitChannel && call.rawAudioLength < 1800 ? 325 : 195);
    setIgnoreScrollEvent(true);
  };

  // Implements auto-scrolling behavior with an interval hook
  useInterval(() => {
    if (isAudioPlaying && autoScroll && !pauseAutoScroll) goToCurrentSentence();
  }, 250);

  const toggleDualSpeakers = () => {
    find(transcriptSettingOptions, ['value', 'dualSpeakers']).label = dualSpeakers
      ? 'Dual Speakers'
      : 'Combine Speakers';
    setDualSpeakers(!dualSpeakers);
  };

  const enableAutoScroll = () => {
    find(transcriptSettingOptions, ['value', 'autoScroll']).label = 'Disable Auto-Scroll';
    setAutoScroll(true);
    setPauseAutoScroll(false);
  };

  const disableAutoScroll = () => {
    find(transcriptSettingOptions, ['value', 'autoScroll']).label = 'Enable Auto-Scroll';
    setAutoScroll(false);
  };

  const toggleAutoScroll = () => (autoScroll ? disableAutoScroll() : enableAutoScroll());

  const temporarilyDisableAutoScroll = () => {
    if (autoScroll && !ignoreScrollEvent) {
      if (pauseAutoScroll) clearTimeout(disableAutoScrollTimeout);
      setPauseAutoScroll(true);
      setDisableAutoScrollTimeout(setTimeout(enableAutoScroll, 5000));
    } else if (ignoreScrollEvent) setIgnoreScrollEvent(false);
  };

  const toggleHideCallModal = () => setHideCallModalOpen(!hideCallModalOpen);

  const handleHideCall = () => {
    hideCall(recordingId, hideCallDescription.value);
    setHideCallModalOpen(false);
    if (!singleCall) hideCurrentCallCallback();
    else window.location = '/';
  };

  const updateDescription = updatedDescription => setHideCallDescription(updatedDescription);

  const handleSettingChange = setting => {
    switch (setting.value) {
      case 'redoTranscription':
        handleRedoTranscription();
        break;
      case 'dualSpeakers':
        toggleDualSpeakers();
        break;
      case 'autoScroll':
        toggleAutoScroll();
        break;
      case 'editMode':
        toggleEditMode();
        break;
      case 'transcriptionErrors':
        toggleTranscriptionErrors(setting.label);
        break;
      case 'hideCall':
        toggleHideCallModal();
        break;
      case 'splitChannel':
        toggleSplitChannel();
        break;
      case 'downloadAudio':
        downloadAudio();
        break;
      default:
        break;
    }
  };

  const downloadAudio = () => {
    const link = document.createElement('a');
    link.href = audioSrc;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRedoTranscription = async () => {
    if (!transcriptionInProgress) {
      setTranscriptionInProgress(true);
      setTranscript(null);
      requestTranscript(recordingId, s3Key, s3Bucket, callLanguage, callCountry);
    }
  };

  const handleRemoveCallFromLibrary = async () => {
    await removeCallFromLibrary(recordingId);
    setIsInLibrary(false);
    setLibraryToastOpen(listType === 'call-library' || singleCall);
    localStorage.removeItem(`localRegions-library-${recordingId}`);
    if (!singleCall) toggleLibraryCallback(recordingId, false);
  };

  const handleWavesurferEvents = (event, currentTime) => {
    if (event === 'play' || event === 'pause') setIsAudioPlaying(event === 'play');
    if (event === 'ready') setIsAudioReady(true);
    if (event === 'seek' && isAudioReady && !isAudioPlaying) goToCurrentSentence(currentTime);
    if (event === 'audioprocess') handleWavesurferAudioProcessThrottled(currentTime);
  };

  // Upon loading of region data, jump to first region's start time
  useEffect(() => {
    if (listType === 'call-library' && regions?.length) {
      setCallTime(regions[0].start);
      setSelectedRegion(regions[0]);
    }
  }, [regions]);

  const handleWavesurferRegionOut = eventTime => {
    const currentRegion = getRegionByTime(eventTime);
    if (!currentRegion && listType === 'call-library') handleRegionSeek('forward', eventTime);
    else if (!currentRegion) setSelectedRegion();
  };

  // Checks if the current time is located inside an annotated region, and if so, returns that region
  const getRegionByTime = eventTime =>
    regions.find(regionDetails => eventTime >= regionDetails.start && eventTime <= regionDetails.end);

  const handleRegionSeek = (event, eventTime = audioTime) => {
    let foundRegion;
    if (event === 'forward') {
      foundRegion = regions.find(regionDetails => eventTime < regionDetails.start && eventTime < regionDetails.end);
    } else {
      const reversedRegions = [...regions].reverse();
      foundRegion = reversedRegions.find(
        regionDetails => eventTime > regionDetails.start && eventTime > regionDetails.end
      );
    }

    // If no next or previous region was found, loop back to the first region instead
    const regionToPlay = foundRegion || regions[0];

    setForceUpdateAudio(true);
    setAudioTime(regionToPlay.start);
    setSelectedRegion(regionToPlay);
  };

  const onChangeTagValue = async (event, tagId) => {
    event.persist();
    if (event.target.checked) await attachTag(recordingId, tagId);
    else await detachTag(recordingId, tagId);

    await toggleTagCallback(recordingId, tagId, event.target.checked);
  };

  const editTranscript = (index, updatedUtterance) => {
    const originalUtterance = transcript[index];

    if (updatedUtterance.message.length === 0) transcript.splice(index, 1);
    else transcript[index] = updatedUtterance;
    setTranscript([...transcript]);
    updateTranscript(transcript, recordingId);

    if (updatedUtterance.message.includes('?') || originalUtterance.message.includes('?'))
      recalculateQuestionCount(transcript);
  };

  const recalculateQuestionCount = updatedTranscript => {
    let newQuestionCount = 0;
    updatedTranscript.forEach(utterance => {
      if (utterance.speakerTag === 2 && utterance.message.includes('?')) newQuestionCount++;
    });
    call.questionCount = newQuestionCount;
  };

  const toggleEditMode = () => {
    find(transcriptSettingOptions, ['value', 'editMode']).label = editTranscriptMode
      ? 'Enable Edit Mode'
      : 'Disable Edit Mode';
    setEditTranscriptMode(!editTranscriptMode);
  };

  const toggleSplitChannel = () => {
    find(transcriptSettingOptions, ['value', 'splitChannel']).label = splitChannel
      ? 'Enable Split Channel'
      : 'Disable Split Channel';
    setSplitChannel(!splitChannel);
    localStorage.setItem('splitChannel', !splitChannel);
  };

  const getNewTabLink = () => {
    if (['call-qas', 'call-library'].includes(listType)) return `/${listType}/${recordingId}`;
    else return `/call/${recordingId}`;
  };

  return (
    <section className="slds-col">
      <article className="slds-card call-card slds-size_5-of-12 slds-large-size_4-of-12">
        <header className="slds-card__header">
          {!singleCall && (
            <div className="slds-grid slds-grid_vertical-align-center">
              <div className="slds-col">
                {/* TODO: Will need to bring back similar functionality at some point, when we know which office each call belongs to.
                                    <p>{moment(initiatedTime).tz(region === 'EU' ? 'Europe/London' : 'America/Chicago').format('MMMM Do YYYY')}</p>
                                */}
                <p>{moment(initiatedTime).format('MMMM Do YYYY')}</p>
              </div>
              <div className="slds-col slds-text-align_right">
                <a href={getNewTabLink()} target="_blank">
                  Open in New Tab <Icon category="utility" name="new_window" size="xx-small" />
                </a>
              </div>
            </div>
          )}
          <div className="slds-grid">
            <div className="slds-col">
              {relatedAccounts.length === 0 && <h3 className="account-name">UNKNOWN</h3>}
              {relatedAccounts.length !== 0 && (
                <h3 className="account-name">
                  <a href={`/account/${relatedAccounts[0].accountId}`} target="_blank">
                    {relatedAccounts[0].accountName}
                  </a>
                  {relatedAccounts.length > 1 && (
                    <Popover
                      heading="Suppliers"
                      align={singleCall ? 'bottom left' : 'bottom right'}
                      className="supplier-dropdown"
                      body={
                        <div>
                          {relatedAccounts.map((relatedAccount, index) => {
                            if (index !== 0)
                              return (
                                <a
                                  key={index}
                                  href={`/account/${relatedAccount.accountId}`}
                                  target="_blank"
                                  className="supplier-name slds-text-align_left"
                                >
                                  {relatedAccount.accountName}
                                </a>
                              );
                          })}
                        </div>
                      }
                    >
                      <Button
                        variant="base"
                        label={`(+${relatedAccounts.length - 1} more)`}
                        className="slds-m-left_xx-small"
                      />
                    </Popover>
                  )}
                </h3>
              )}
              <p>{supplierPhone}</p>
            </div>
            {singleCall && (
              <div className="slds-col slds-text-align_right">
                {/* TODO: Will need to bring back similar functionality at some point, when we know which office each call belongs to.
                                    <p>{moment(initiatedTime).tz(region === 'EU' ? 'Europe/London' : 'America/Chicago').format('MMMM Do YYYY')}</p>
                                */}
                <p>{moment(initiatedTime).format('MMMM Do YYYY')}</p>
              </div>
            )}
          </div>
          {singleCall && (
            <section className="badge-list slds-m-top_x-small">
              {tags &&
                flatTagList.map(tag => tags.includes(tag.id) && <Badge key={tag.id} content={tag.displayName} />)}
              {possibleVoicemail && !(tags && tags.indexOf('06418046-e7c5-4d78-ae58-470693ee9d72') >= 0) && (
                <Badge content="Possible Voicemail" />
              )}
              {dualChannel && <Badge content="Dual Channel" />}
            </section>
          )}
        </header>

        <Tabs>
          <TabsPanel label="Transcript">
            <section className={`audio-container ${splitChannel && rawAudioLength < 1800 && 'split-channels'}`}>
              <div className="audio-wrapper">
                <WavesurferPlayer
                  audioSrc={audioSrc}
                  audioTime={audioTime}
                  audioLength={rawAudioLength}
                  transcriptSettingOptions={transcriptSettingOptions}
                  dualChannel={dualChannel}
                  onEvent={(event, seekTime = null) => handleWavesurferEvents(event, seekTime)}
                  handleSettingChange={handleSettingChange}
                  splitChannel={splitChannel}
                  setForceUpdateAudioCallback={setForceUpdateAudio}
                  forceUpdateAudio={forceUpdateAudio}
                  regionMode="view"
                  regions={regions}
                  onRegionClickEvent={event => setSelectedRegion(event)}
                  onRegionOutEvent={handleWavesurferRegionOut}
                  onRegionInEvent={event => setSelectedRegion(event)}
                  onRegionSeek={event => handleRegionSeek(event)}
                />
              </div>
            </section>
            {transcript && (
              <div className={`transcript-container ${splitChannel && rawAudioLength < 1800 && 'split-channels'}`}>
                <section
                  role="log"
                  className="slds-chat slds-scrollable_y transcript-list"
                  onScroll={temporarilyDisableAutoScroll}
                >
                  <Transcript
                    callId={callId}
                    recordingId={recordingId}
                    transcript={transcript}
                    editTranscriptMode={editTranscriptMode}
                    dualSpeakers={dualSpeakers}
                    dualChannel={dualChannel}
                    srmName={srmName}
                    setCallTimeCallback={setCallTime}
                    editTranscriptCallback={editTranscript}
                    disableAutoScrollCallback={disableAutoScroll}
                    singleCall={singleCall}
                    keywordSearchText={keywordSearchText}
                    keywordSearchBy={keywordSearchBy}
                    groupedKeywords={groupedKeywords}
                  />
                </section>
              </div>
            )}
            {!transcript && (
              <div>
                <section className="request-transcript-tab">
                  {!transcribed && !transcriptionInProgress && TRANSCRIPTION_LANGUAGES.includes(callLanguage) && (
                    <Button label="Request Transcript" variant="brand" onClick={requestCallTranscript} />
                  )}
                  {!transcribed && !transcriptionInProgress && !TRANSCRIPTION_LANGUAGES.includes(callLanguage) && (
                    <div className="disabled-request-transcript-language">
                      <ScopedNotification theme="light">
                        <p>Transcription of {callLanguage} calls is not currently supported.</p>
                      </ScopedNotification>
                      <Button label="Request Transcript" variant="brand" disabled />
                    </div>
                  )}
                  {transcribed && !transcriptionInProgress && !loadingTranscript && (
                    <ScopedNotification theme="light">
                      <p>Empty</p>
                    </ScopedNotification>
                  )}
                  {transcribed && !transcriptionInProgress && loadingTranscript && (
                    <ScopedNotification theme="light">
                      <p>Loading...</p>
                    </ScopedNotification>
                  )}
                  {transcriptionInProgress && (
                    <ScopedNotification theme="light">
                      <p>Transcription in progress. Please check back later.</p>
                    </ScopedNotification>
                  )}
                </section>
              </div>
            )}
          </TabsPanel>
          <TabsPanel label="Info">
            <CallInfo
              recordingId={recordingId}
              relatedAccounts={relatedAccounts}
              supplierId={supplierId}
              supplierName={supplierName}
              supplierPhone={supplierPhone}
              initiatedTime={initiatedTime}
              srmName={srmName}
              callDirection={callDirection}
              audioLength={audioLength}
              callCountry={callCountry}
              callLanguage={callLanguage}
              answerStatus={answerStatus}
              transcript={transcript}
              questionCount={call.questionCount}
              talkListenRatio={talkListenRatio}
              qaAuthor={qaAuthor}
              qaCreationTime={qaCreationTime}
              disposition={call.disposition}
              updateAccountCallback={updateAccountCallback}
              updateLanguageCallback={updateLanguageCallback}
            />
          </TabsPanel>
          {relatedAccounts.length > 0 && singleCall && (
            <TabsPanel label="Market Activity">
              <MarketActivity activity={activity} initiatedTime={initiatedTime} />
            </TabsPanel>
          )}
          <TabsPanel label="Tags">
            <section className="tags-tab">
              {tagList &&
                tagList.map((tag, index) => (
                  <div className="slds-form-element" key={index}>
                    <TagList
                      tag={tag}
                      callTags={call.tags}
                      recordingId={recordingId}
                      onChangeTagValueCallback={onChangeTagValue}
                    />
                  </div>
                ))}
            </section>
            <section className="tags-tab-options">
              {isInLibrary && libraryPermission && (
                <LinkButton to={`/call-library/${recordingId}/edit`}>Modify Annotations</LinkButton>
              )}
              {isInLibrary && libraryPermission && (
                <DeleteConfirmation
                  onDelete={handleRemoveCallFromLibrary}
                  body={
                    <p>
                      Your annotations will be lost forever if you continue. Are you sure you want to remove this call
                      from the library?
                    </p>
                  }
                  heading={<p>Remove Call From Library</p>}
                >
                  <Button>Remove From Library</Button>
                </DeleteConfirmation>
              )}
              {!isInLibrary && libraryPermission && (
                <LinkButton to={`/call-library/${recordingId}/edit`}>Add To Library</LinkButton>
              )}
              {!isQAed && qaPermission && <LinkButton to={`/call-qas/${recordingId}/edit`}>QA Call</LinkButton>}
            </section>
            <ToastContainer>
              {libraryToastOpen && (
                <Toast
                  duration={10000}
                  labels={{
                    heading: 'This recording has been removed from the Call Library.',
                    details: !singleCall && 'It will remain in the list until the page is refreshed.',
                  }}
                  onRequestClose={() => setLibraryToastOpen(false)}
                />
              )}
            </ToastContainer>
          </TabsPanel>
          {notes && (
            <TabsPanel label="Notes">
              <section className="request-transcript-tab">{notes}</section>
            </TabsPanel>
          )}
        </Tabs>
      </article>
      <Modal
        footer={[
          <Button key="cancel" label="Cancel" onClick={toggleHideCallModal} />,
          <Button key="proceed" label="Proceed" variant="destructive" onClick={handleHideCall} />,
        ]}
        isOpen={hideCallModalOpen}
        onRequestClose={toggleHideCallModal}
        prompt="error"
        size="medium"
        heading={<span>Hide Call Recording</span>}
      >
        <div className="slds-m-around_medium hide-call-modal">
          <p>Clicking proceed will hide this call recording for all users.</p>
          <p>
            This is an appropriate action to take when a recording provides no value because it was a personal call,
            silent, accidental, etc.
          </p>
          <p>Before proceeding, please choose a reason for hiding the recording from the dropdown below:</p>

          <Dropdown
            onSelect={updateDescription}
            align="right"
            options={[
              { label: 'Personal Call', value: 'personal_call' },
              { label: 'Silent', value: 'silent' },
              { label: 'Internal Meeting', value: 'meeting' },
              { label: 'Corrupted Audio', value: 'corrupted' },
            ]}
          >
            <DropdownTrigger>
              <Button iconCategory="utility" iconName="down" iconPosition="right" label={hideCallDescription.label} />
            </DropdownTrigger>
          </Dropdown>
        </div>
      </Modal>
    </section>
  );
};

export default CallCard;
