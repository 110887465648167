import { useEffect, useMemo } from 'react';
import { Badge, Button, Checkbox, Icon, Tooltip } from '@salesforce/design-system-react';
import moment from 'moment';

import { CALL_LIST_OFFSET } from '../constants';
import { getUserEmail } from '../auth';
import { flattenTagList } from '../call-utils';
import Stats from './Stats';

const CallListTable = ({
  calls,
  listType,
  activeCallIndex,
  accountId,
  selectCall,
  accountName,
  hideCurrent,
  toggleMeta,
  keywordSearchText,
  groupedKeywords,
  keywordSearchBy,
  overallStats,
  filteredStats,
  tagList,
}) => {
  const loggedInUserEmail = useMemo(() => getUserEmail(), []);
  const flatTagList = useMemo(() => (tagList ? flattenTagList(tagList) : []), [tagList]);

  useEffect(() => {
    if (
      calls &&
      !hideCurrent &&
      !toggleMeta &&
      activeCallIndex >= calls.length - CALL_LIST_OFFSET &&
      calls[activeCallIndex]
    )
      selectCall(calls[activeCallIndex], activeCallIndex);
    else if (hideCurrent && calls[activeCallIndex]) selectCall(calls[activeCallIndex], activeCallIndex);
    else if (hideCurrent && !calls[activeCallIndex] && calls.length)
      selectCall(calls[activeCallIndex - 1], activeCallIndex - 1);
  }, [calls, hideCurrent, toggleMeta, activeCallIndex]);

  const getSearchedPhrase = call => {
    let matchedKeyword;
    let messageIndex = -1;
    let searchedResult;
    for (let i = 0; i < call.transcript.length; i++) {
      if (keywordSearchText)
        messageIndex = call.transcript[i].message.toLowerCase().indexOf(keywordSearchText.toLowerCase());
      else if (!Array.isArray(groupedKeywords))
        messageIndex = call.transcript[i].message.toLowerCase().indexOf(groupedKeywords.toLowerCase());
      else {
        matchedKeyword = call.transcript[i].message.match(new RegExp(groupedKeywords.join('|'), 'gi'));
        for (let j = 0; j < groupedKeywords.length; j++) {
          if (messageIndex === -1)
            messageIndex = call.transcript[i].message.toLowerCase().indexOf(groupedKeywords[j].toLowerCase());
        }
      }
      if (
        messageIndex >= 0 &&
        (!keywordSearchBy ||
          (keywordSearchBy === 'Supplier' && call.transcript[i].speakerTag === 1) ||
          (keywordSearchBy === 'SRM' && call.transcript[i].speakerTag === 2) ||
          keywordSearchBy === 'Both')
      ) {
        searchedResult = call.transcript[i];
        break;
      }
    }
    if (searchedResult) {
      return (
        <div
          className={`slds-chat-message__text slds-m-horizontal_none slds-p-vertical_xxx-small ${
            searchedResult.speakerTag === 2 ? 'slds-chat-message__text_outbound' : 'slds-chat-message__text_inbound'
          }`}
        >
          {searchedResult.message
            .split(
              new RegExp(
                keywordSearchText || (Array.isArray(groupedKeywords) ? groupedKeywords.join('|') : groupedKeywords),
                'gi'
              )
            )
            .reduce((prev, current, i) => {
              if (!i) return [current];
              return prev.concat(
                <span
                  key={
                    (keywordSearchText || (Array.isArray(groupedKeywords) ? matchedKeyword[i - 1] : groupedKeywords)) +
                    current +
                    i
                  }
                  className="highlight-text"
                >
                  {keywordSearchText || (Array.isArray(groupedKeywords) ? matchedKeyword[i - 1] : groupedKeywords)}
                </span>,
                current
              );
            }, [])}
        </div>
      );
    }
  };

  return (
    <div className="call-list-wrapper">
      {listType !== 'account' && (
        <h1 className="slds-text-heading_medium slds-m-bottom_small">
          <strong>Calls</strong>
        </h1>
      )}
      {listType === 'account' && (
        <div className="slds-m-bottom_small">
          <h1 className="slds-text-heading_medium">
            <strong>{accountName} Calls</strong>
          </h1>
          <a className="admin-link" href={`https://admin.c2fo.com/account/${accountId}`} target="_blank">
            C2FO Admin <Icon category="utility" name="new_window" size="xx-small" />
          </a>
          <a
            className="admin-link"
            href={`https://c2fo.lightning.force.com/lightning/r/${accountId}/view`}
            target="_blank"
          >
            Salesforce <Icon category="utility" name="new_window" size="xx-small" />
          </a>
        </div>
      )}

      {calls.length !== 0 && <Stats overallStats={overallStats} filteredStats={filteredStats} />}
      {calls.map((call, index) => (
        <div
          className={`slds-grid slds-gutters slds-wrap slds-grid_vertical-align-center call-row ${
            activeCallIndex === index && 'selected-row'
          } ${call.hide && 'slds-hide'}`}
          onClick={() => selectCall(call, index)}
          key={`${call.recordingId}_${index}`}
        >
          <div className="slds-col slds-grid slds-gutters slds-grid_vertical-align-center slds-wrap">
            <div className="slds-col date-time">
              {/*
                  TODO: Will need to bring back similar functionality at some point, when we know which office each call belongs to.
                  <p><strong>{moment(call.initiatedTime).format('h:mm A')}</strong></p>
                  <p>{moment(call.initiatedTime).tz(region === 'EU' ? 'Europe/London' : 'America/Chicago').format('MMM Do YYYY')}</p>
              */}
              <p>
                <strong>{moment(call.initiatedTime).format('h:mm A')}</strong>
              </p>
              <p>{moment(call.initiatedTime).format('MMM Do YYYY')}</p>
            </div>

            <div className="slds-col account-srm-names">
              {call.relatedAccounts.length === 0 ? (
                <p className="unknown-account">UNKNOWN</p>
              ) : (
                <p className="account-name slds-truncate">
                  {call.relatedAccounts.length !== 0 && call.relatedAccounts[0].accountName}
                </p>
              )}
              <p>{call.srmName}</p>
            </div>

            <div className="slds-col supplier-phone">
              <p className="slds-truncate">{call.supplierPhone}</p>
              <p>{call.audioLength}</p>
            </div>

            <div className="slds-col language-country">
              <p className="call-language">{call.callLanguage}</p>
              <p className="slds-truncate">{call.callCountry}</p>
            </div>

            <div className="slds-col tags">
              <section className="badge-list">
                {call.tags &&
                  flatTagList.map(
                    tag => call.tags.includes(tag.id) && <Badge key={tag.id} content={tag.displayName} />
                  )}
                {call.possibleVoicemail &&
                  !(call.tags && call.tags.indexOf('06418046-e7c5-4d78-ae58-470693ee9d72') >= 0) && (
                    <Badge content="Possible Voicemail" />
                  )}
                {!call.dualChannel && <Badge content="Mono Channel" />}
              </section>
            </div>
            <div className="transcript-status">
              {call.acknowledged && (
                <Tooltip
                  content={`Acknowledged on ${moment(call.acknowledgedDate).format('LLL')}`}
                  align="top right"
                  position="overflowBoundaryElement"
                >
                  <Button variant="icon" iconCategory="utility" iconName="answer" />
                </Tooltip>
              )}
              {!call.transcribed && (
                <Tooltip
                  content="No Transcript Available"
                  align="top right"
                  position="overflowBoundaryElement"
                  dialogClassName="transcript-status-tooltip"
                >
                  <Button variant="icon" iconCategory="utility" iconName="cancel_file_request" />
                </Tooltip>
              )}
            </div>
            {call.transcript && (keywordSearchText || groupedKeywords.length > 0) && (
              <div className="slds-col slds-chat-message slds-size_4-of-4">{getSearchedPhrase(call)}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CallListTable;
