import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './index.scss';
import App from './App';

ReactDOM.render(
  <HelmetProvider>
    <Router>
      <App />
    </Router>
  </HelmetProvider>,
  document.getElementById('root')
);
