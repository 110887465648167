import { NavLink, useLocation } from 'react-router-dom';

const QueryLink = ({ to, title }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete('time');

  return (
    <NavLink
      to={{ pathname: to, search: searchParams.toString() }}
      exact
      className="slds-context-bar__item"
      activeClassName="slds-is-active"
    >
      <span className="slds-context-bar__label-action">{title}</span>
    </NavLink>
  );
};

export default QueryLink;
