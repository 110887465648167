import { PureComponent } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Combobox,
  Icon,
  Input,
  Modal,
  Pill,
  ScopedNotification,
  Spinner,
  Tooltip,
} from '@salesforce/design-system-react';
import { find } from 'lodash';

import { createSrmTeam, deleteSrmTeam, getAgents, getQATemplates, getSrmTeams, updateSrmTeam } from '../call-utils';

import DeleteConfirmation from './DeleteConfirmation';

const selectedDefaultTeam = {
  created: '',
  createdBy: '',
  id: '',
  teamName: '',
  teamMembers: [],
  qaTemplateId: null,
};

class SrmTeams extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      selectedTeam: JSON.parse(JSON.stringify(selectedDefaultTeam)),
      allAgents: [],
      agents: [],
      qaTemplates: [],
      selectedQATemplate: null,
      showAllSrmAgents: false,
      openAddUpdateTeam: false,
      modifyType: 'add',
      isLoading: true,
      duplicateTeam: false,
    };
  }

  componentDidMount = async () => {
    await this.loadQATemplates();
    await this.loadSrmTeams();
    this.loadAgents();
  };

  loadQATemplates = async () => {
    const qaTemplates = await getQATemplates();
    qaTemplates.map(template => {
      // This num->string conversion is required for compatibility with the Combobox component:
      template.id = template.id.toString();
      // This label is used on the list table, as well as in the Combobox dropdown:
      template.label = `${template.name} (v${template.version})`;
    });
    this.setState({ qaTemplates });
  };

  loadSrmTeams = async () => {
    await this.setState({ teams: [], isLoading: true });
    const teams = await getSrmTeams();
    this.setState({ teams, isLoading: false });
  };

  loadAgents = async () => {
    const allAgents = await getAgents(this.props.accountId);
    if (!allAgents[0].value) allAgents.shift();
    allAgents.map(agent => {
      agent.checked = false;
    });
    this.setState({ allAgents, agents: allAgents });
  };

  filterSrmAgents = value =>
    this.setState({
      agents: this.state.allAgents.filter(agent => agent.value.toLowerCase().includes(value.toLowerCase())),
    });

  showAllSrmAgents = team => this.setState({ selectedTeam: team }, this.toggleAllMembersModal);

  toggleAllMembersModal = () => {
    this.setState({ showAllSrmAgents: !this.state.showAllSrmAgents }, () => {
      if (!this.state.showAllSrmAgents) this.resetSelectedTeam();
    });
  };

  toggleAddUpdateTeamModal = async (modifyType = 'add') => {
    const { openAddUpdateTeam, allAgents } = this.state;
    if (modifyType === 'add' || openAddUpdateTeam) await this.resetSelectedTeam();
    this.setState({ openAddUpdateTeam: !openAddUpdateTeam, modifyType, agents: allAgents });
  };

  /**
   * @param agent {string}
   * @returns {{ teamName: (string), agent: string } | false}
   */
  getSrmAgentTeam = (agent) => {
    const { teams } = this.state;
    if (!agent) return false;
    const team = teams.find(t => (t.teamMembers || []).indexOf(`${agent}`) !== -1);
    return team ? { teamName: team.teamName, agent } : false;
  };

  toggleSrmAgent = (add, agent) => {
    const { selectedTeam, allAgents } = this.state;
    const agentExist = selectedTeam.teamMembers.find(member => member.value === agent.value);

    // toggle checkbox
    allAgents.find(item => item.value === agent.value).checked = add;

    // toggle team members
    if (add && !agentExist) selectedTeam.teamMembers.push(agent);
    else if (!add && agentExist)
      selectedTeam.teamMembers = selectedTeam.teamMembers.filter(member => member.value !== agent.value);

    this.setState({ selectedTeam: { ...selectedTeam }, allAgents });
  };

  changeTeamName = value => {
    const { selectedTeam } = this.state;
    selectedTeam.teamName = value;
    this.setState({ selectedTeam: { ...selectedTeam }, duplicateTeam: false });
  };

  addTeam = async () => {
    const { selectedTeam, teams, selectedQATemplate } = this.state;
    if (teams.find(team => team.teamName === selectedTeam.teamName)) {
      this.setState({ duplicateTeam: true });
      return false;
    }
    const newTeamMembers = selectedTeam.teamMembers.map(member => member.value);
    await createSrmTeam(selectedTeam.teamName, newTeamMembers, selectedQATemplate.id);
    await this.resetSelectedTeam();
    this.loadSrmTeams();
    this.setState({ openAddUpdateTeam: false });
  };

  updateTeam = async () => {
    const { selectedTeam } = this.state;
    const teamMembers = selectedTeam.teamMembers.map(member => member.value);
    await updateSrmTeam(selectedTeam.id, teamMembers, selectedTeam.teamName, selectedTeam.qaTemplateId);
    await this.resetSelectedTeam();
    this.loadSrmTeams();
    this.setState({ openAddUpdateTeam: false });
  };

  deleteTeam = async team => {
    await deleteSrmTeam(team.id);
    await this.resetSelectedTeam();
    this.loadSrmTeams();
  };

  resetSelectedTeam = () => {
    const { agents, qaTemplates } = this.state;
    const selectedTeam = JSON.parse(JSON.stringify(selectedDefaultTeam));
    agents.map(agent => {
      agent.checked = false;
      return agent;
    });
    this.setState({ selectedTeam, agents, selectedQATemplate: qaTemplates[0] });
  };

  handleUpdateClick = team => {
    const { selectedTeam, agents, qaTemplates } = this.state;
    agents.map(agent => {
      if (team.teamMembers.includes(agent.value)) agent.checked = true;
    });
    selectedTeam.teamName = team.teamName;
    selectedTeam.id = team.id;
    selectedTeam.qaTemplateId = team.qaTemplateId;
    selectedTeam.teamMembers = team.teamMembers.map(member => ({ label: member, value: member, checked: true }));

    this.setState(
      {
        selectedTeam,
        agents,
        selectedQATemplate: find(qaTemplates, ['id', selectedTeam.qaTemplateId.toString()]),
      },
      () => this.toggleAddUpdateTeamModal('update')
    );
  };

  changeQATemplate = template => {
    const { selectedTeam } = this.state;
    selectedTeam.qaTemplateId = template.id;
    this.setState({ selectedQATemplate: template, selectedTeam });
  };

  getQATemplateLabel = value => {
    const qaTemplate = find(this.state.qaTemplates, ['id', value.toString()]);
    return qaTemplate.label;
  };

  render() {
    const {
      teams,
      selectedTeam,
      showAllSrmAgents,
      openAddUpdateTeam,
      modifyType,
      isLoading,
      agents,
      duplicateTeam,
      qaTemplates,
      selectedQATemplate,
    } = this.state;
    return (
      <main className="settings-page-container teams-page">
        <div className="settings-page">
          <div className="slds-card">
            <div className="slds-panel__header">
              <h2 className="slds-panel__header-title slds-text-heading_large slds-truncate">SRM Teams</h2>
              <button
                className="slds-button slds-button_neutral slds-button_brand"
                type="submit"
                onClick={() => this.toggleAddUpdateTeamModal('add')}
              >
                Add&nbsp;Team
              </button>
            </div>
            <div className="slds-panel__body">
              {teams.length > 0 && (
                <table className="slds-table slds-table_cell-buffer slds-table_bordered ">
                  <thead>
                    <tr className="slds-line-height_reset">
                      <th scope="col">
                        <div className="slds-truncate">Team Name</div>
                      </th>
                      <th scope="col">
                        <div className="slds-truncate">SRM Agents</div>
                      </th>
                      <th scope="col" colSpan="2">
                        <div className="slds-truncate">QA Template</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {teams.map((team, index) => (
                      <tr className="slds-hint-parent" key={team.teamName + index}>
                        <td width="200">
                          <div className="slds-truncate">{team.teamName}</div>
                        </td>
                        <td>
                          {team.teamMembers.map(
                            (member, memberIndex) =>
                              memberIndex <= 2 && <Badge content={member} key={member + memberIndex} color="light" />
                          )}
                          {team.teamMembers.length > 3 && (
                            <Badge
                              content={
                                <a href="#" onClick={() => this.showAllSrmAgents(team)}>
                                  {team.teamMembers.length - 3}+
                                </a>
                              }
                            />
                          )}
                        </td>
                        <td width="150">
                          <div className="slds-truncate">{this.getQATemplateLabel(team.qaTemplateId)}</div>
                        </td>
                        <td width="100">
                          <div className="slds-truncate">
                            <Button
                              iconCategory="utility"
                              iconName="edit"
                              iconPosition="left"
                              variant="brand"
                              onClick={() => this.handleUpdateClick(team)}
                              className="slds-m-right_x-small slds-button-utility"
                            />
                            <DeleteConfirmation
                              onDelete={() => this.deleteTeam(team)}
                              body="Are you sure you want to delete this team?"
                              heading="Delete Team"
                              buttonVariant="destructive"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!isLoading && teams.length === 0 && (
                <ScopedNotification theme="light" className="no-records">
                  <p>Sorry, no groups to show.</p>
                </ScopedNotification>
              )}
            </div>
          </div>

          <Modal
            isOpen={showAllSrmAgents}
            onRequestClose={this.toggleAllMembersModal}
            heading={`Team ${selectedTeam ? selectedTeam.teamName : ''}`}
            className="selected-team-modal"
            align="top"
          >
            <div>
              {selectedTeam.teamMembers && (
                <ul className="slds-has-dividers_bottom-space">
                  {selectedTeam.teamMembers.map((member, memberIndex) => (
                    <li key={`selectedTeam${member}${memberIndex}`} className="slds-item">
                      {member}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Modal>

          <Modal
            isOpen={openAddUpdateTeam}
            onRequestClose={this.toggleAddUpdateTeamModal}
            heading={`${modifyType === 'add' ? 'Add Team' : 'Edit Team'}`}
            className="srm-team-modal"
            align="top"
            footer={
              <div className="slds-m-top_medium">
                <Button
                  label={`${modifyType === 'add' ? 'Add Team' : 'Save Team'}`}
                  onClick={modifyType !== 'update' ? this.addTeam : this.updateTeam}
                  variant="brand"
                  disabled={selectedTeam.teamMembers.length === 0 || !selectedTeam.teamName}
                />
              </div>
            }
          >
            <section className="slds-p-around_large">
              <div className="slds-grid slds-gutters input-box-wrapper">
                <div className="slds-col">
                  <Input
                    label="Team Name"
                    placeholder="Enter Team Name..."
                    value={selectedTeam.teamName}
                    onChange={e => this.changeTeamName(e.target.value)}
                  />
                </div>
                <div className="slds-col">
                  <Input
                    label="Search SRM"
                    placeholder="Filter list of SRM…"
                    onChange={e => this.filterSrmAgents(e.target.value)}
                  />
                </div>
                <div className="slds-col">
                  <Combobox
                    events={{ onSelect: (_, data) => this.changeQATemplate(data.selection[0]) }}
                    labels={{ label: 'QA Template' }}
                    options={qaTemplates}
                    className="template-dropdown"
                    menuPosition="relative"
                    selection={[selectedQATemplate]}
                    variant="readonly"
                  />
                </div>
              </div>
              {selectedTeam.teamMembers.length !== 0 && (
                <div className="slds-m-top_medium">
                  <label>Team Members</label>
                  <div>
                    {selectedTeam.teamMembers.map((agent, newTeamIndex) => (
                      <Pill
                        key={`add-update-team-members${agent.value}${newTeamIndex}`}
                        labels={{ label: agent.value, removeTitle: 'Remove' }}
                        onRemove={() => this.toggleSrmAgent(false, agent)}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="slds-m-top_medium srm-agent-list">
                <label>SRM Agents</label>
                <div className="slds-grid slds-gutters slds-wrap">
                  {agents.map((agent, agentIndex) => (
                    <div
                      className="slds-col slds-size_4-of-12 slds-truncate"
                      key={`add-update-srm-agent-${agent.label}${agentIndex}`}
                    >
                      <SrmAgentCheckbox agent={agent}
                                        agentTeamName={(this.getSrmAgentTeam(agent.value) || {}).teamName}
                                        onChange={(e) => this.toggleSrmAgent(e.target.checked, agent)}/>
                    </div>
                  ))}
                </div>
              </div>
              {duplicateTeam && (
                <p className="slds-m-top_medium slds-text-color_error slds-align_absolute-center">
                  <Icon
                    assistiveText={{ label: 'Warning' }}
                    category="utility"
                    colorVariant="error"
                    name="warning"
                    size="small"
                    className="error-icon"
                  />
                  Team name already exists.
                </p>
              )}
            </section>
          </Modal>
        </div>
        {isLoading && <Spinner />}
      </main>
    );
  }
}

/**
 * A special checkbox for SRM agent list.  Agent Checkboxes with a team are disabled and
 * include a tooltip with the assigned team's name.
 * @param agent {{label, checked, value}}
 * @param agentTeamName {string|undefined}
 * @param onChange {function}
 * @returns {JSX.Element}
 * @constructor
 */
const SrmAgentCheckbox = ({ agent, agentTeamName, onChange }) => {
  const checkbox =
    <span tabIndex={0}>
      <Checkbox
      className={agentTeamName ? 'slds-text-color_weak' : ''}
      assistiveText={{ label: agent.value }}
      labels={{ label: agent.value }}
      checked={agent.checked}
      disabled={!!agentTeamName}
      onChange={e => onChange(e)}
    />
    </span>;

  return !agentTeamName ? checkbox : <Tooltip
    id="SrmAgentCheckbox"
    content={`Assigned to team "${agentTeamName}"!`}
    variant="base"
    children={checkbox}
    theme="error" />;
}

export default SrmTeams;
