import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dropdown,
  GlobalNavigationBar,
  GlobalNavigationBarRegion,
  DropdownTrigger,
  Button,
  GlobalNavigationBarDropdown,
} from '@salesforce/design-system-react';

import QueryLink from './QueryLink';
import { getUserEmail, checkUserRole, logout } from '../auth';

const userOptions = [
  {
    label: 'Logout',
    rightIcon: {
      category: 'utility',
      name: 'logout',
    },
    value: null,
  },
];

const settingOptions = [
  { label: 'Speech Hints', value: 'speechHints', href: '/speech-hints' },
  { type: 'divider' },
  { label: 'Keyword Groups', value: 'keywordGroups', href: '/keyword-groups' },
  { type: 'divider' },
  { label: 'SRM Teams', value: 'srmTeams', href: '/srm-teams' },
  { type: 'divider' },
  { label: 'Blocked Numbers', value: 'blockedNumbers', href: '/blocked-numbers' },
  { type: 'divider' },
  { label: 'QA Templates', value: 'qaTemplates', href: '/qa-templates' },
];

const Nav = () => {
  const history = useHistory();
  const userEmail = useMemo(getUserEmail, []);
  const srmManagerPermission = checkUserRole('call_transcriber_review');

  return (
    <GlobalNavigationBar>
      <GlobalNavigationBarRegion region="primary">
        <div className="c2fo-logo">
          <img src="/assets/images/c2fo-logo-white.svg" alt="company logo" />
        </div>
        <div className="app-name">Call Transcriber</div>
      </GlobalNavigationBarRegion>
      <GlobalNavigationBarRegion region="secondary" navigation>
        <QueryLink to="/" exact title="All Calls" />
        <QueryLink to="/my-calls" exact title="My Calls" />
        <QueryLink to="/call-library" exact title="Call Library" />
        <QueryLink to="/call-qas" exact title="Call QA" />
        <QueryLink to="/stats" title="Stats" />
        {srmManagerPermission && (
          <GlobalNavigationBarDropdown
            label="Settings"
            options={settingOptions}
            className="settings-menu"
            onSelect={e => history.push(e.href)}
          />
        )}
      </GlobalNavigationBarRegion>
      <GlobalNavigationBarRegion region="tertiary">
        <div className="user-options-dropdown">
          <Dropdown align="right" options={userOptions} onSelect={logout}>
            <DropdownTrigger>
              <Button
                iconCategory="utility"
                iconName="down"
                iconPosition="right"
                iconSize="x-small"
                label={userEmail}
                variant="base"
              />
            </DropdownTrigger>
          </Dropdown>
        </div>
      </GlobalNavigationBarRegion>
    </GlobalNavigationBar>
  );
};

export default Nav;
