import { PureComponent } from 'react';
import moment from 'moment';
import { Button, DataTable, DataTableColumn, DataTableCell, Input, Spinner } from '@salesforce/design-system-react';

import { SPEECH_HINTS_OFFSET } from '../constants';
import { getHints, createHint, deleteHint } from '../call-utils';

import DocumentTitle from './DocumentTitle';
import HintDeleteConfirmation from './HintDeleteConfirmation';

HintDeleteConfirmation.displayName = DataTableCell.displayName;

class SpeechHints extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hintList: [],
      offset: 0,
      phrase: '',
      sortDirection: 'desc',
      sortColumn: 'created',
      emptyField: false,
      formSubmitError: false,
    };
    window.addEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    const {
      loadHints,
      state: { isLoading, canLoadMore },
    } = this;

    if (isLoading || !canLoadMore) return;

    // Checks that the page has scrolled to the bottom
    if (
      Number((window.innerHeight + document.documentElement.scrollTop).toFixed(0)) ===
      document.documentElement.offsetHeight
    )
      loadHints();
  };

  componentDidMount = () => this.loadHints();
  componentWillUnmount = () => window.removeEventListener('scroll', this.onScroll, false);

  loadHints = async () => {
    await this.setState({ isLoading: true });
    // const order = `${this.state.sortColumn} ${this.state.sortDirection.toUpperCase()}`;
    const hints = await getHints(this.state.offset, this.state.sortColumn, this.state.sortDirection);
    this.populateHints(hints);
  };

  populateHints = hints => {
    if (!hints.length) this.setState({ isLoading: false, canLoadMore: false });
    else {
      hints = hints.map(hint => {
        hint.created = moment(hint.created).format('MMMM Do YYYY @ h:mm A');
        return hint;
      });
      this.setState({
        phrase: '',
        hintList: [...this.state.hintList, ...hints],
        isLoading: false,
        canLoadMore: hints.length === SPEECH_HINTS_OFFSET,
        offset: this.state.offset + SPEECH_HINTS_OFFSET,
      });
    }
  };

  resetHints = () => this.setState({ hintList: [], offset: 0 });

  handleChange = e => {
    this.setState({
      phrase: e.target.value,
      formSubmitError: false,
      emptyField: false,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.state.phrase.length <= 0) {
      this.setState({ emptyField: true });
      return false;
    } else this.setState({ isLoading: true });

    const res = await createHint(this.state.phrase.trim().replace(/ +(?= )/g, ''));
    if (!res.errorType) {
      await this.setState({ sortColumn: 'created', sortDirection: 'desc' });
      await this.resetHints();
      this.loadHints();
    } else this.setState({ formSubmitError: true, isLoading: false, phrase: '' });
  };

  handleDelete = async phraseID => {
    await deleteHint(phraseID);
    await this.resetHints();
    this.loadHints();
  };

  handleSort = async sortColumn => {
    await this.resetHints();
    await this.setState({ sortColumn: sortColumn.property, sortDirection: sortColumn.sortDirection });
    this.loadHints();
  };

  render() {
    const { isLoading, hintList, formSubmitError, emptyField, phrase, sortColumn, sortDirection } = this.state;

    const columns = [
      <DataTableColumn
        key="account-name"
        label="Phrase"
        property="phrase"
        sortable
        isSorted={sortColumn === 'phrase'}
      />,
      <DataTableColumn
        key="close-date"
        label="Author"
        property="createdBy"
        sortable
        isSorted={sortColumn === 'createdBy'}
      />,
      <DataTableColumn
        key="stage"
        label="Created"
        property="created"
        sortable
        isSorted={sortColumn === 'created'}
        sortDirection={sortDirection}
      />,
      <DataTableColumn key="stage" width="50px">
        <HintDeleteConfirmation onDelete={this.handleDelete} />
      </DataTableColumn>,
    ];
    return (
      <DocumentTitle title="SRM Calls | Speech Hints">
        <main className="settings-page-container">
          <div className="settings-page">
            <section className="slds-card">
              <div className="slds-panel__header">
                <h2 className="slds-panel__header-title slds-text-heading_large slds-truncate" title="Speech Hints">
                  Speech Hints
                </h2>
              </div>
              <div className="slds-panel__body">
                {formSubmitError && <div className="form-error">The phrase you entered already exists.</div>}
                <form className="slds-grid" onSubmit={this.handleSubmit} noValidate>
                  <div className="text-area-wrapper">
                    <Input
                      placeholder="Enter a phrase..."
                      required
                      value={phrase}
                      errorText={emptyField ? 'Please enter a phrase' : ''}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="slds-publisher__actions slds-grid slds-grid_align-spread">
                    <Button className="slds-button_brand" label="Add" type="submit" />
                  </div>
                </form>
                {hintList && (
                  <DataTable items={hintList} onSort={this.handleSort} fixedLayout>
                    {columns}
                  </DataTable>
                )}
              </div>
            </section>
          </div>
          {isLoading && <Spinner />}
        </main>
      </DocumentTitle>
    );
  }
}

export default SpeechHints;
