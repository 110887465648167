import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const OverviewGraph = ({ overviewGraphData }) => {
  const [chartOptions, setOptions] = useState([]);

  useEffect(() => {
    createGraphSeries();
  }, [overviewGraphData]);

  const createGraphSeries = () => {
    let dates = new Set();
    let series = [
      {
        name: '<1 min',
        color: '#c1e67b',
      },
      {
        name: '1 - 5 min',
        color: '#8cd33b',
      },
      {
        name: '5 - 10 min',
        color: '#7aba2c',
      },
      {
        name: '10 - 20 min',
        color: '#76982b',
      },
      {
        name: '20+ min',
        color: '#5a6127',
      },
    ];
    overviewGraphData.forEach(graphData => dates.add(graphData.interval));
    dates = Array.from(dates);

    series.forEach(seriesElement => {
      seriesElement.data = new Array(dates.length).fill(0);
    });

    overviewGraphData.forEach(graphData => {
      let seriesIndex;

      switch (graphData.timeInterval) {
        case series[0].name:
          seriesIndex = 0;
          break;
        case series[1].name:
          seriesIndex = 1;
          break;
        case series[2].name:
          seriesIndex = 2;
          break;
        case series[3].name:
          seriesIndex = 3;
          break;
        default:
          seriesIndex = 4;
          break;
      }

      series = [
        ...addCallToSeries({
          graphData,
          series,
          seriesIndex,
          dateIndex: dates.indexOf(graphData.interval),
        }),
      ];
    });

    const options = {
      credits: {
        enabled: false,
      },
      chart: {
        type: 'column',
      },
      title: {
        text: 'Overview Graph',
      },
      xAxis: {
        categories: dates,
      },
      yAxis: {
        title: {
          text: 'Number of calls',
        },
      },
      tooltip: {
        useHTML: true,
        style: {
          textAlign: 'center',
        },
        formatter() {
          return `${this.series.name}: <b>${this.y}</b> <br> Total: <b>${this.total}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series,
    };

    setOptions(options);
  };

  const addCallToSeries = data => {
    const { graphData, series, seriesIndex, dateIndex } = data;

    const callCount = Number(graphData.callCount);
    series[seriesIndex].data[dateIndex] += callCount;

    return series;
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default OverviewGraph;
