import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, every, isEqual } from 'lodash';
import queryString from 'query-string';
import { Button, ScopedNotification, Spinner } from '@salesforce/design-system-react';

import { CALL_LIST_OFFSET } from '../constants';
import {
  updateLanguage,
  updateAccount,
  getCalls,
  getQACalls,
  getCallTranscripts,
  transformCallData,
  transformTranscriptData,
  getAccountName,
  getGroupKeywords,
  getPageStats,
  getTagList,
} from '../call-utils';
import { getUserFullName, checkUserRole } from '../auth';
import parseFilters from '../common-utils';
import { getQueryParam } from '../url-utils';

import DocumentTitle from './DocumentTitle';
import CallCard from './CallCard';
import Filters from './Filters';
import CallListTable from './CallListTable';

const qaPermission = checkUserRole('call_transcriber_review');

const defaultFilters = {
  selectedAgent: null,
  selectedTags: [],
  selectedCountry: null,
  selectedLanguage: null,
  selectedDirection: null,
  selectedAnswerStatus: null,
  minAudioLength: 0,
  startDate: undefined,
  endDate: undefined,
  startTime: '',
  endTime: '',
  hideUnknownSuppliers: false,
  hideVoicemails: true,
  hideUntranscribed: false,
  dualChannel: false,
  selectedOrder: 'initiatedTime DESC',
  keywordSearchBy: 'Both',
  keywordSearchText: '',
  keywordGroupName: '',
  groupedKeywords: [],
  supplierSearchText: '',
  phoneSearchText: '',
  selectedSrmTeamName: '',
  selectedSrmTeamMembers: [],
  selectedQAAuthor: null,
  selectedDisposition: null,
};

const createInitialFilters = listType => {
  defaultFilters.hideUntranscribed = listType === 'home';
  defaultFilters.hideVoicemails = listType === 'home';
  defaultFilters.selectedAnswerStatus = listType === 'home' ? 'ANSWERED' : null;
  defaultFilters.minAudioLength = listType === 'home' ? 60 : 0;
  defaultFilters.selectedQAAuthor = listType === 'call-qas' && qaPermission ? getUserFullName() : null;
  const filterParams = queryString.parse(location.search);
  return parseFilters({ ...defaultFilters, ...filterParams });
};

const isSubset = (aSubset, aSuperset) => every(aSubset, (val, key) => isEqual(val, aSuperset[key]));

const CallList = ({ listType, listTitle }) => {
  const history = useHistory();

  // store info about current account, if on an account page
  const { accountId } = useParams();
  const [accountName, setAccountName] = useState(null);
  const pageTitle = accountName || listType !== 'home' ? `${listType.charAt(0).toUpperCase()}${listType.slice(1)}` : '';

  const [keywordSearchTextUrl, setKeywordSearchTextUrl] = useState(
    useMemo(() => getQueryParam('keywordSearchText'), [])
  );
  const [phoneSearchTextUrl, setPhoneSearchTextUrl] = useState(useMemo(() => getQueryParam('phoneSearchText'), []));
  const [supplierSearchTextUrl, setSupplierSearchTextUrl] = useState(
    useMemo(() => getQueryParam('supplierSearchText'), [])
  );

  const [calls, setCalls] = useState([]); // list of calls
  const [call, setCall] = useState(null); // current call
  const [activeCallIndex, setActiveCallIndex] = useState(0); // index of current call

  // these values determine whether the page is loading, can load more pages, etc.
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTranscript, setLoadingTranscript] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);

  // informs downstream components about whether they need to update/re-render
  const [hideCurrent, setHideCurrent] = useState(false);
  const [toggleMeta, setToggleMeta] = useState(false);

  // contain data for the stats info bar at top of list
  const [overallStats, setOverallStats] = useState(null);
  const [filteredStats, setFilteredStats] = useState(null);

  const [tagList, setTagList] = useState([]);

  const loadTagList = async () => {
    const tags = await getTagList();
    setTagList(tags);
  };

  useEffect(() => {
    // waits for a response from getAccountName, sets accountName
    const loadAccountName = async () => setAccountName(await getAccountName(accountId));
    if (listType === 'account') loadAccountName();

    // load overall stats with no filters applied
    loadPageStats('overallStats');

    // load list of tags on page load
    loadTagList();
  }, []);

  // determines whether the "Clear Filters" button is enabled, when currentFilters is different from defaultFilters
  const [showClearFilterButton, setShowClearFilterButton] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(useCallback(() => createInitialFilters(listType), []));

  // decides whether the clear filter button should display, based on current state of filter combinations
  useEffect(() => {
    setShowClearFilterButton(!isSubset(defaultFilters, currentFilters));
  }, [currentFilters]);

  // loads calls if there's no data requests currently in progress
  useEffect(() => {
    if (!isLoading) return;
    loadCalls();
  }, [isLoading]);

  // Set up scroll handler for infinite loading
  useEffect(() => {
    function handleScroll() {
      if (!canLoadMore) return;
      // Check if user has scrolled to bottom of page
      if (
        Number((window.innerHeight + document.documentElement.scrollTop).toFixed(0)) !==
        document.documentElement.offsetHeight
      )
        return;
      // If so, signal readiness for loading
      setIsLoading(true);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [canLoadMore]);

  // case statement to set values for user searching/filtering within the ui, updates query with new filter info, resets calls.
  const onChangeFilterValue = async (value, stateName, debounced) => {
    switch (stateName) {
      case 'selectedDate':
        setCurrentFilters({
          ...currentFilters,
          startDate: value.startDate,
          endDate: value.endDate,
        });
        break;
      case 'selectedTime':
        setCurrentFilters({
          ...currentFilters,
          startTime: value.startTime,
          endTime: value.endTime,
        });
        break;
      case 'keywordGroupName':
        if (!value) {
          setCurrentFilters({
            ...currentFilters,
            keywordGroupName: '',
            groupedKeywords: [],
          });
        } else {
          const selectedGroupKeywords = await getGroupKeywords(value);
          setCurrentFilters({
            ...currentFilters,
            keywordGroupName: value,
            keywordSearchText: '',
            groupedKeywords: selectedGroupKeywords[0].keywords,
          });
          setKeywordSearchTextUrl('');
        }
        break;
      case 'keywordSearchText':
        setKeywordSearchTextUrl(value);
        break;
      case 'supplierSearchText':
        setSupplierSearchTextUrl(value);
        break;
      case 'phoneSearchText':
        setPhoneSearchTextUrl(value);
        break;
      case 'selectedSrmTeamName':
        setCurrentFilters({
          ...currentFilters,
          selectedSrmTeamMembers: value.teamMembers,
          selectedSrmTeamName: value.teamName,
        });
        break;
      case 'selectedTags':
        setCurrentFilters({
          ...currentFilters,
          selectedTags: value,
        });
        break;
      default:
        setCurrentFilters({
          ...currentFilters,
          [stateName]: value,
        });
        break;
    }

    // Do not reload calls when keywordSearchBy dropdown changes but no keywords exist
    if (stateName === 'keywordSearchBy' && !keywordSearchTextUrl && currentFilters.groupedKeywords.length === 0)
      return false;
    if (debounced) resetCallsDebounced(stateName);
    else resetCalls();
  };

  // When currentFilters changes, reflect those changes in the URL query string
  // ex: calls.c2fo-lab.com/?selectedDirection=OUTBOUND&hideVoicemails=false
  useEffect(() => {
    const filters = {};
    Object.entries(defaultFilters).map(([filterName, filterValue]) => {
      if (filterValue !== currentFilters[filterName]) filters[filterName] = currentFilters[filterName];
    });
    history.push({ search: queryString.stringify(filters) });
  }, [currentFilters]);

  // Cleans up url (removes filter query params), returns filters to default state
  const clearFilters = () => {
    history.push({ search: '' });

    setCurrentFilters(defaultFilters);
    setKeywordSearchTextUrl('');
    setSupplierSearchTextUrl('');
    setPhoneSearchTextUrl('');
    resetCalls();
  };

  // Empties the call list, resets the page offset, prepares to load calls
  const resetCalls = () => {
    setCalls([]);
    setCall(null);
    setActiveCallIndex(0);
    setOffset(0);
    setIsLoading(true);
  };
  // Limits the resetCalls() firing by waiting for input to end for 2 seconds
  const resetCallsDebounced = useCallback(debounce(resetCalls, 2000), []);

  // parses filters, awaits firing of all associated funcs for loading calls, sets values for default filters
  const loadCalls = async () => {
    setCurrentFilters({
      ...currentFilters,
      keywordSearchText: keywordSearchTextUrl,
      phoneSearchText: phoneSearchTextUrl,
      supplierSearchText: supplierSearchTextUrl,
    });

    let srmAgent = currentFilters.selectedAgent;
    if (listType === 'my-calls' || (listType === 'call-qas' && !qaPermission)) srmAgent = getUserFullName();

    const payload = {
      offset,
      includeTranscript: false,
      accountId,
      startDate: currentFilters.startDate || '2014-09-11',
      endDate: currentFilters.endDate || new Date().toISOString().slice(0, 10),
      srmAgent,
      tagIds: currentFilters.selectedTags,
      audioLength: currentFilters.minAudioLength,
      keyword: keywordSearchTextUrl,
      hideUnknownAccounts: currentFilters.hideUnknownSuppliers,
      hideVoicemails: currentFilters.hideVoicemails,
      supplier: supplierSearchTextUrl,
      hideUntranscribed: currentFilters.hideUntranscribed,
      dualChannel: currentFilters.dualChannel,
      sortColumn: currentFilters.selectedOrder.split(' ')[0],
      sortDirection: currentFilters.selectedOrder.split(' ')[1],
      answerStatus: currentFilters.selectedAnswerStatus,
      country: currentFilters.selectedCountry,
      language: currentFilters.selectedLanguage,
      direction: currentFilters.selectedDirection,
      startTime: currentFilters.startTime || currentFilters.endTime || '',
      endTime: currentFilters.endTime || currentFilters.startTime || '',
      keywordSearchBy: currentFilters.keywordSearchBy,
      groupedKeywords: currentFilters.keywordGroupName ? currentFilters.groupedKeywords : '',
      phoneNumber: phoneSearchTextUrl ? phoneSearchTextUrl.replace(/[^0-9]/g, '') : '',
      srmTeam: currentFilters.selectedSrmTeamName ? currentFilters.selectedSrmTeamMembers : '',
      isInLibrary: listType === 'call-library',
      isQAed: listType === 'call-qas',
      qaAuthor: currentFilters.selectedQAAuthor,
      disposition: currentFilters.selectedDisposition,
    };

    loadPageStats('filteredStats', payload);
    let callDataList = [];
    if (listType === 'call-qas') {
      callDataList = await getQACalls(payload);
    } else {
      callDataList = await getCalls(payload);
    }
    setIsLoading(false);

    if (callDataList.length) {
      // iterate over call data to set state on various fields, loads more calls if there's room
      const transformedCallDataList = callDataList.map(callData => transformCallData(callData));
      const tempMergedCalls = [...calls, ...transformedCallDataList];

      setCalls(tempMergedCalls);
      if (!call) setCall(tempMergedCalls[0]);
      setLoadingTranscript(true);
      setCanLoadMore(callDataList.length === CALL_LIST_OFFSET);
      setOffset(offset + CALL_LIST_OFFSET);

      // Fetch transcripts in follow-up request
      payload.includeTranscript = true;
      const transcriptDataList = await getCallTranscripts(payload);
      const transformedTranscriptDataList = transcriptDataList.map(callData => transformTranscriptData(callData));

      tempMergedCalls
        .filter(tempCall => !tempCall.transcript && tempCall.transcribed)
        .map(tempCall => {
          const matchedTranscriptData = transformedTranscriptDataList.find(
            transcript => transcript.recordingId === tempCall.recordingId
          );
          if (!matchedTranscriptData) return;
          tempCall.transcript = matchedTranscriptData.transcript;
          tempCall.talkListenRatio = matchedTranscriptData.talkListenRatio;
          tempCall.possibleVoicemail = matchedTranscriptData.possibleVoicemail;
          tempCall.questionCount = matchedTranscriptData.questionCount;
        });
      setCalls([...tempMergedCalls]);
      setLoadingTranscript(false);
    } else setCanLoadMore(false);
  };

  // Sets selected call based on user action (click on a call in the list) or page load
  const loadCall = (selectedCall, index = 0) => {
    setCall(selectedCall);
    setHideCurrent(false);
    setActiveCallIndex(index);
    setToggleMeta(false);
  };

  // parses page stats from the json payload, via filters or myCalls and formats their value, setsStateName
  const loadPageStats = async (stateName, payload = {}) => {
    if (stateName === 'filteredStats') payload = JSON.parse(JSON.stringify(payload));
    else if (listType === 'my-calls') payload.srmAgent = getUserFullName();
    else if (listType === 'account') payload.accountId = accountId;
    else if (listType === 'call-library') payload.isInLibrary = true;
    else if (listType === 'call-qas') payload.isQAed = true;

    const pageStats = await getPageStats(payload);
    const formattedPageStats = {
      longestCallLength: pageStats.longestCallLength,
      avgCallLength: pageStats.avgCallLength,
      callCount: pageStats.callCount,
      oldestDate: pageStats.oldestDate,
      newestDate: pageStats.newestDate,
      totalCallLength: pageStats.totalCallLength,
    };

    if (stateName === 'filteredStats') setFilteredStats(formattedPageStats);
    else if (stateName === 'overallStats') setOverallStats(formattedPageStats);
  };

  // hide current selected call
  const hideCurrentCall = () => {
    // TECHDEBT: Can this be replaced with splice or something else equivalent?
    const tempCalls = calls.filter((_, index) => index !== activeCallIndex);
    setCalls(tempCalls);
    setHideCurrent(true);
  };

  // Find call in list, and update isInLibrary state
  const toggleLibrary = (recordingId, value) => {
    calls.find(c => c.recordingId === recordingId).isInLibrary = value;
    setToggleMeta(true);
    setCalls([...calls]);
  };

  const toggleTag = async (recordingId, tagId, checked) => {
    // Find call in list, and update list of associated tags in state
    const tempCall = calls.find(c => c.recordingId === recordingId);

    if (checked) {
      if (!tempCall.tags) tempCall.tags = [tagId];
      else tempCall.tags.push(tagId);
    } else tempCall.tags.splice(tempCall.tags.indexOf(tagId), 1);

    setToggleMeta(true);
    setCalls([...calls]);
  };

  // gets language selection
  const handleUpdateLanguage = async (recordingId, value) => {
    const languageUpdateResponse = await updateLanguage(recordingId, value);
    // Find call in list, and update callLanguage state
    if (languageUpdateResponse) {
      const tempCalls = Object.assign([], calls);
      tempCalls.find(tempCall => tempCall.recordingId === recordingId).callLanguage = value;
      setCalls(tempCalls);
    }
  };

  // detects an update to an account
  const handleUpdateAccount = async (recordingId, newAccountId, newAccountName) => {
    const accountUpdateResponse = await updateAccount(recordingId, newAccountId, newAccountName);
    // Find call in list, and update relatedAccounts state
    if (accountUpdateResponse) {
      const tempCalls = Object.assign([], calls);
      tempCalls.find(tempCall => tempCall.recordingId === recordingId).relatedAccounts = [
        { accountId: newAccountId, accountName: newAccountName },
      ];
      setCalls(tempCalls);
    }
  };

  return (
    <DocumentTitle title={`SRM Calls | ${listTitle || pageTitle}`}>
      <main>
        <div className="slds-grid slds-wrap">
          <div
            className={`slds-col ${
              calls.length === 0 && !isLoading
                ? 'slds-large-size_12-of-12'
                : 'slds-size_7-of-12 slds-large-size_8-of-12'
            }`}
          >
            <Filters
              onChangeValue={onChangeFilterValue}
              currentPageName={listType}
              accountId={accountId}
              currentFilters={currentFilters}
              showClearFilterButton={showClearFilterButton}
              clearFiltersCallback={clearFilters}
              tagList={tagList}
            />
            {calls && (
              <CallListTable
                calls={calls}
                activeCallIndex={activeCallIndex}
                accountId={accountId}
                selectCall={(selectedCall, index) => loadCall(selectedCall, index)}
                listType={listType}
                accountName={accountName}
                hideCurrent={hideCurrent}
                toggleMeta={toggleMeta}
                keywordSearchText={currentFilters.keywordSearchText}
                groupedKeywords={currentFilters.groupedKeywords}
                keywordSearchBy={currentFilters.keywordSearchBy}
                overallStats={overallStats}
                filteredStats={filteredStats}
                tagList={tagList}
              />
            )}
            {!isLoading && calls.length === 0 && (
              <ScopedNotification theme="light" className="no-records">
                <p>Sorry, no transcripts were found with the selected filter parameters.</p>
              </ScopedNotification>
            )}
            {!isLoading && canLoadMore && (
              <div className="load-button-container">
                <Button label="Load More Items" variant="outline-brand" onClick={loadCalls} />
              </div>
            )}
          </div>
          {call && calls.length !== 0 && (
            <div className="slds-col slds-size_5-of-12 slds-large-size_4-of-12">
              <CallCard
                key={call.recordingId}
                listType={listType}
                call={call}
                loadingTranscript={loadingTranscript}
                updateLanguageCallback={handleUpdateLanguage}
                updateAccountCallback={handleUpdateAccount}
                toggleTagCallback={toggleTag}
                toggleLibraryCallback={toggleLibrary}
                hideCurrentCallCallback={hideCurrentCall}
                keywordSearchText={call.transcript && currentFilters.keywordSearchText}
                keywordSearchBy={currentFilters.keywordSearchBy}
                groupedKeywords={currentFilters.groupedKeywords}
                tagList={tagList}
              />
            </div>
          )}
        </div>
        {isLoading && <Spinner />}
      </main>
    </DocumentTitle>
  );
};

export default CallList;
