import { PureComponent } from 'react';
import moment from 'moment';

class Stats extends PureComponent {
  render() {
    const { overallStats, filteredStats } = this.props;

    return (
      <div>
        {overallStats && filteredStats && (
          <div className="stats slds-size_1-of-1 slds-border_top slds-p-vertical_small filtered-stats">
            <div>
              <strong>&nbsp;</strong>
              <br />
              <strong>Overall</strong>
              <br />
              <strong>Filtered</strong>
              <br />
            </div>
            <div>
              <strong>Total Calls</strong>
              <br />
              {overallStats.callCount}
              <br />
              {filteredStats.callCount}
            </div>
            <div>
              <strong>Average Call</strong>
              <br />
              {formatCallLength(overallStats.avgCallLength)}
              <br />
              {formatCallLength(filteredStats.avgCallLength)}
            </div>
            <div>
              <strong>Longest Call</strong>
              <br />
              {formatCallLength(overallStats.longestCallLength)}
              <br />
              {formatCallLength(filteredStats.longestCallLength)}
            </div>
            <div>
              <strong>Total Call Time</strong>
              <br />
              {formatCallLength(overallStats.totalCallLength, true)}
              <br />
              {formatCallLength(filteredStats.totalCallLength, true)}
            </div>
            <div>
              <strong>Oldest</strong>
              <br />
              {moment.utc(overallStats.oldestDate).format('MMM Do YYYY')}
              <br />
              {moment.utc(filteredStats.oldestDate).format('MMM Do YYYY')}
            </div>
            <div>
              <strong>Newest</strong>
              <br />
              {moment.utc(overallStats.newestDate).format('MMM Do YYYY')}
              <br />
              {moment.utc(filteredStats.newestDate).format('MMM Do YYYY')}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function formatCallLength(value, useHours) {
  if (useHours) return `${Math.floor(value / 3600)}hr ${Math.floor((value - Math.floor(value / 3600) * 3600) / 60)}min`;
  return `${Math.floor(value / 60)}min ${Math.floor(value - Math.floor(value / 60) * 60)}sec`;
}

export default Stats;
