import { PureComponent } from 'react';

class DayPickerHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      months: [],
      years: [],
    };
  }

  componentDidMount() {
    const { localeUtils, yearStart, yearEnd } = this.props;
    if (!this.props.date) {
      this.props.date = new Date();
    }
    const months = localeUtils.getMonths();
    const years = [];
    for (let i = yearStart.getFullYear(); i <= yearEnd.getFullYear(); i++) {
      years.push(i);
    }
    this.populateCalendarHeader(years, months);
  }

  populateCalendarHeader(years, months) {
    this.setState({ years, months });
  }

  handleChange(e) {
    const { year } = e.target.form;
    this.props.onChange(new Date(year.value, this.props.date.getMonth()));
  }

  render() {
    const { date } = this.props;
    const { months, years } = this.state;

    return (
      <form className="DayPicker-Caption">
        <p>{months[date.getMonth()]}</p>
        <select name="year" onChange={this.handleChange} value={date.getFullYear()}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }
}

export default DayPickerHeader;
