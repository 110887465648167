import { useState } from 'react';
import moment from 'moment';
import { Button, SLDSEditDialog, Input, Dropdown, DropdownTrigger } from '@salesforce/design-system-react';
import { CALL_LANGUAGES } from '../constants';
import { formatAnswerStatus } from '../common-utils';

const CallInfo = ({
  recordingId,
  relatedAccounts,
  supplierId,
  supplierName,
  supplierPhone,
  initiatedTime,
  srmName,
  callDirection,
  audioLength,
  callCountry,
  callLanguage,
  answerStatus,
  transcript,
  questionCount,
  qaAuthor,
  qaCreationTime,
  talkListenRatio,
  disposition,
  updateAccountCallback,
  updateLanguageCallback,
}) => {
  const [accountPopoverIsOpen, setAccountPopoverIsOpen] = useState(false);
  const [languagePopoverIsOpen, setLanguagePopoverIsOpen] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');
  const [newAccountId, setNewAccountId] = useState('');
  const [newCallLanguage, setNewCallLanguage] = useState(null);

  const handleOpenAccountPopover = () => {
    setAccountPopoverIsOpen(true);
    setLanguagePopoverIsOpen(false);
  };

  const onChangeAccountId = value => setNewAccountId(value.target.value);

  const onChangeAccountName = value => setNewAccountName(value.target.value);

  const handleCancelAccountPopover = () => {
    setAccountPopoverIsOpen(false);
    setNewAccountId('');
    setNewAccountName('');
  };

  const handleSaveAccount = async () => {
    setAccountPopoverIsOpen(false);
    await updateAccountCallback(recordingId, newAccountId, newAccountName);
  };

  const editAccountDialogPopoverBody = (
    <div className="edit-language-popover-form slds-form slds-form_stacked slds-p-top_medium slds-p-right_small slds-p-left_small slds-p-bottom_small">
      <Input id="new-account-id" label="Account ID" value={newAccountId} onChange={onChangeAccountId} />
      <Input id="new-account-name" label="Account Name" value={newAccountName} onChange={onChangeAccountName} />
    </div>
  );

  const getLabel = (valueList, value) => {
    const elementInList = valueList.find(x => x.value === value);
    if (elementInList) return elementInList.label;
  };

  const onChangeCallLanguage = value => setNewCallLanguage(value.value);

  const handleOpenLanguagePopover = () => {
    setLanguagePopoverIsOpen(true);
    setAccountPopoverIsOpen(false);
  };

  const handleSaveLanguage = async () => {
    setLanguagePopoverIsOpen(false);
    await updateLanguageCallback(recordingId, newCallLanguage);
  };

  const handleCancelLanguagePopover = () => {
    setLanguagePopoverIsOpen(false);
    setNewCallLanguage(null);
  };

  const editLanguageDialogPopoverBody = (
    <div className="edit-language-popover-form slds-form slds-form_stacked slds-p-top_medium slds-p-right_small slds-p-left_small slds-p-bottom_small">
      <label className="slds-form-element__label">Language</label>
      <Dropdown defaultValue={callLanguage} onSelect={onChangeCallLanguage} options={CALL_LANGUAGES} length="7">
        <DropdownTrigger>
          <Button
            iconCategory="utility"
            iconName="down"
            iconPosition="right"
            label={getLabel(CALL_LANGUAGES, newCallLanguage || callLanguage)}
          />
        </DropdownTrigger>
      </Dropdown>
    </div>
  );

  return (
    <ul className="slds-page-header__detail-list info-tab">
      <li className="slds-page-header__detail-item">
        <div className="slds-text-title slds-truncate">Supplier Name</div>
        <div>
          {relatedAccounts.length === 0 && (
            <div className="supplier-name-container">
              <p className="supplier-name">UNKNOWN</p>
              <SLDSEditDialog
                ariaLabelledby="Edit Account"
                body={editAccountDialogPopoverBody}
                isModified={newAccountId !== '' && newAccountName !== ''}
                onCancel={handleCancelAccountPopover}
                onClose={handleCancelAccountPopover}
                onRequestClose={handleCancelAccountPopover}
                onSave={handleSaveAccount}
                handleOpen={handleOpenAccountPopover}
                position="absolute"
                align="bottom right"
                id="edit-account-dialog"
                className
                isOpen={accountPopoverIsOpen}
              />
            </div>
          )}
          {relatedAccounts.map((relatedAccount, index) => (
            <a className="supplier-name" key={index} href={`/account/${relatedAccount.accountId}`} target="_blank">
              {relatedAccount.accountName}
            </a>
          ))}
        </div>
      </li>
      <li className="slds-page-header__detail-item">
        <div className="slds-text-title slds-truncate">Call Date</div>
        {/* TODO: Will need to bring back similar functionality at some point, when we know which office each call belongs to.
                    <div>{moment(initiatedTime).tz(region === 'EU' ? 'Europe/London' : 'America/Chicago').format('MMMM Do YYYY @ h:mm A')}</div>
                */}
        <div>{moment(initiatedTime).format('MMMM Do YYYY @ h:mm A')}</div>
      </li>
      <li className="slds-page-header__detail-item">
        <div className="slds-text-title slds-truncate">SRM / Ring Group</div>
        <div>{srmName}</div>
      </li>
      {supplierPhone && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">Phone Number</div>
          <div>{supplierPhone}</div>
        </li>
      )}
      <li className="slds-page-header__detail-item">
        <div className="slds-text-title slds-truncate">Call Length</div>
        <div>{audioLength}</div>
      </li>
      {callDirection && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">Direction</div>
          <div className="call-direction">{callDirection}</div>
        </li>
      )}
      {callCountry && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">Country</div>
          <div>{callCountry}</div>
        </li>
      )}
      {callLanguage && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">Language</div>
          <div className="call-language-container">
            <p>{callLanguage}</p>
            <SLDSEditDialog
              ariaLabelledby="Edit Language"
              body={editLanguageDialogPopoverBody}
              isModified={newCallLanguage && callLanguage !== newCallLanguage}
              onCancel={handleCancelLanguagePopover}
              onClose={handleCancelLanguagePopover}
              onRequestClose={handleCancelLanguagePopover}
              onSave={handleSaveLanguage}
              handleOpen={handleOpenLanguagePopover}
              position="absolute"
              align="bottom right"
              id="edit-language-dialog"
              className
              isOpen={languagePopoverIsOpen}
            />
          </div>
        </li>
      )}
      {answerStatus && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">Answer Status</div>
          <div>{formatAnswerStatus(answerStatus)}</div>
        </li>
      )}
      {transcript && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">SRM Talk/Listen Ratio</div>
          <div>{talkListenRatio}</div>
        </li>
      )}
      {transcript && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">Questions Asked (SRM)</div>
          <div>{questionCount}</div>
        </li>
      )}
      {qaAuthor && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">QA Author</div>
          <div>{qaAuthor}</div>
        </li>
      )}
      {qaCreationTime && (
        <li className="slds-page-header__detail-item">
          <div className="slds-text-title slds-truncate">QA Date</div>
          <div>{qaCreationTime}</div>
        </li>
      )}
      <li className="slds-page-header__detail-item">
        <div className="slds-text-title slds-truncate">Disposition</div>
        <div>{disposition || 'N/A (No Disposition)'}</div>
      </li>
    </ul>
  );
};

export default CallInfo;
