import { Component } from 'react';
import { IconSettings, Settings } from '@salesforce/design-system-react';

import DocumentTitle from './components/DocumentTitle';
import ProtectedRoutes from './ProtectedRoutes';
import { isLoggedIn } from './auth';

Settings.setAppElement('#root');

class App extends Component {
  state = { validCredentials: false };

  componentDidMount = () => this.loadCredentials();

  loadCredentials = async () => this.setState({ validCredentials: await isLoggedIn() });

  render() {
    const { validCredentials } = this.state;
    return (
      <DocumentTitle title="SRM Calls">
        <IconSettings iconPath="/assets/icons">{validCredentials && <ProtectedRoutes />}</IconSettings>
      </DocumentTitle>
    );
  }
}

export default App;
