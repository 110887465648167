import { Link } from 'react-router-dom';
import { Button } from '@salesforce/design-system-react';

const LinkButton = ({ to, children }) => (
  <Button className="link-button">
    <Link to={to}>{children}</Link>
  </Button>
);

export default LinkButton;
