import { Helmet } from 'react-helmet-async';

const DocumentTitle = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
);

export default DocumentTitle;
