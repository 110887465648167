import { Fragment } from 'react';
import { Checkbox } from '@salesforce/design-system-react';

const isTagged = (tags, tagId) => tags && tags.includes(tagId);

const TagList = ({ tag, callTags, onChangeTagValueCallback }) => (
  <Fragment>
    <Checkbox
      className="maintag-checkbox"
      id={tag.id}
      defaultChecked={isTagged(callTags, tag.id)}
      onChange={e => onChangeTagValueCallback(e, tag.id)}
      labels={{ label: tag.displayName }}
    />

    {tag.subTags && (
      <div className="subtag-list">
        {tag.subTags.map(subtag => (
          <Checkbox
            className="subtag-checkbox slds-truncate"
            key={subtag.id}
            id={subtag.id}
            defaultChecked={isTagged(callTags, subtag.id)}
            onChange={e => onChangeTagValueCallback(e, subtag.id)}
            labels={{ label: subtag.display_name }}
          />
        ))}
      </div>
    )}
  </Fragment>
);

export default TagList;
