import moment from 'moment-timezone';
import axios from 'axios';
import { parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { getUserEmail, getUserFullName, checkUserRole, redirectToLogin } from './auth';
import qs from 'query-string';
import { forOwn } from 'lodash';

let stage;
if (process.env.REACT_APP_STAGING) stage = 'beta';
else stage = 'default';

const API_URL_BASE = `https://ozqovgmzq5.execute-api.us-west-2.amazonaws.com/${stage}/v1`;

export async function getRequest(path, params = {}) {
  try {
    const response = await axios.get(API_URL_BASE + path, {
      params,
      paramsSerializer: params => {
        forOwn(params, function (value, key) {
          if (Array.isArray(value) && value.length > 0) {
            params[key] = JSON.stringify(value);
          }
        });
        return qs.stringify(params);
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response.status == 401) redirectToLogin();
  }
}

async function postRequest(path, body) {
  try {
    const response = await axios.post(API_URL_BASE + path, body);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function putRequest(path, body) {
  try {
    const response = await axios.put(API_URL_BASE + path, body);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function deleteRequest(path) {
  try {
    const response = await axios.delete(API_URL_BASE + path);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getTagList() {
  return getRequest('/filters/tags');
}

export async function detachTag(recordingId, tagId) {
  return deleteRequest(`/call/${recordingId}/tag/${tagId}`);
}

export async function attachTag(recordingId, tagId) {
  return postRequest(`/call/${recordingId}/tag`, { tagId });
}

export async function getAgents(accountId) {
  const responseBody = await getRequest('/filters/srm', { accountId });
  const agents = [{ label: 'All Agents', value: null }];
  const sortedResponse = responseBody.map(agent => agent.localName).sort();
  agents.push(...sortedResponse.map(agent => ({ label: agent, value: agent })));
  return agents;
}

export async function getCountries(accountId) {
  const responseBody = await getRequest('/filters/countries', { accountId });
  const countries = [{ label: 'All Countries', value: null }];
  const sortedResponse = responseBody.map(country => country.remoteCountry).sort();
  countries.push(...sortedResponse.map(country => ({ label: country, value: country })));
  return countries;
}

export async function getValidDates(currentPageName, accountId) {
  const payload = {
    accountId,
    srmAgent: (currentPageName === 'my-calls' && getUserFullName()) || null,
  };
  const responseBody = await getRequest('/filters/dates', payload);
  const sortedDates = responseBody.map(obj => obj.initiatedDate.slice(0, 10)).sort();

  if (!sortedDates.length) return { validDates: null, fromMonth: null, toMonth: null };

  const fromMonth = new Date(sortedDates[0].slice(0, 4), sortedDates[0].slice(5, 7), 0);
  const toMonth = new Date(
    sortedDates[sortedDates.length - 1].slice(0, 4),
    sortedDates[sortedDates.length - 1].slice(5, 7),
    0
  );
  const validDates = sortedDates.map(obj => new Date(obj.split('-').join(',')).toDateString());

  return { validDates, fromMonth, toMonth };
}

export async function getCall(recordingId) {
  return getRequest(`/call/${recordingId}`);
}

export async function getCalls(payload) {
  return getRequest('/metadata', payload);
}

export async function getQACalls(payload) {
  return getRequest('/metadata/qa', payload);
}

export async function getCallTranscripts(payload) {
  return getRequest('/metadata/transcripts', payload);
}

export async function getQAMetrics(payload) {
  return getRequest('/stats/qa/metrics', payload);
}

export async function getQATeamBreakdown(payload) {
  return getRequest('/stats/qa/team-breakdown', payload);
}

export async function getStatsOverview(payload) {
  return getRequest('/stats/overview', payload);
}

export async function getStatsOverviewSrm(payload) {
  return getRequest('/stats/overview/srm', payload);
}

export async function getStatsOverviewSrmCsv(payload) {
  return getRequest('/stats/overview/srm-csv', payload);
}

export async function getAccountName(accountId) {
  const responseBody = await getRequest(`/metadata/account/${accountId}/name`);
  const relatedAccounts = JSON.parse(responseBody[0].relatedAccounts);
  const relatedAccount = relatedAccounts.filter(x => x.accountId === accountId)[0].accountName;
  return relatedAccount;
}

export async function getPageStats(payload) {
  const responseBody = await getRequest('/stats/list-heading', payload);
  return responseBody[0];
}

export async function getActivity(recordingId, initiatedTime, relatedAccounts) {
  const payload = {
    initiatedTime,
    accountIds: relatedAccounts.map(relatedAccount => relatedAccount.accountId),
    numHours: 72,
  };
  return getRequest(`/call/${recordingId}/activity`, payload);
}

export async function updateAccount(recordingId, accountId, accountName) {
  const payload = {
    accountId,
    accountName,
  };
  return putRequest(`/call/${recordingId}/account`, payload);
}

export async function updateLanguage(recordingId, callLanguage) {
  return putRequest(`/call/${recordingId}/language`, { callLanguage });
}

export async function updateTranscript(transcript, recordingId) {
  const payload = {
    transcript: JSON.stringify(transcript),
  };
  return putRequest(`/call/${recordingId}/transcript`, payload);
}

export async function hideCall(recordingId, description) {
  const payload = {
    userEmail: getUserEmail(),
    description,
  };
  return putRequest(`/call/${recordingId}/hide`, payload);
}

export async function requestTranscript(recordingId, s3Key, s3Bucket, callLanguage, callCountry) {
  const payload = {
    s3Key,
    s3Bucket,
    languageCode: getLanguageCode(callLanguage, callCountry),
  };
  return postRequest(`/call/${recordingId}/transcript`, payload);
}

export async function addCallToLibrary(recordingId, notes) {
  const payload = {
    notes: JSON.stringify(notes),
    author: getUserEmail(),
  };
  return postRequest(`/library/${recordingId}`, payload);
}

export async function removeCallFromLibrary(recordingId) {
  return deleteRequest(`/library/${recordingId}`);
}

export async function getLibraryAnnotations(recordingId) {
  const responseBody = await getRequest(`/library/${recordingId}/annotations`);
  return responseBody[0];
}

export async function updateLibraryAnnotations(recordingId, notes) {
  const payload = {
    recordingId,
    notes,
    // notes: JSON.stringify(notes),
  };
  return putRequest(`/library/${recordingId}/annotations`, payload);
}

export async function getHints(offset, sortColumn, sortDirection) {
  const payload = {
    offset,
    sortColumn,
    sortDirection,
  };
  return getRequest('/speech-hints', payload);
}

export async function createHint(phrase) {
  const payload = {
    phrase,
    createdBy: getUserEmail(),
  };
  return postRequest('/speech-hints', payload);
}

export async function deleteHint(hintId) {
  return deleteRequest(`/speech-hints/${hintId}`);
}

export async function createKeywordGroup(groupName, keywords) {
  const payload = {
    groupName,
    keywords,
    createdBy: getUserEmail(),
  };
  return postRequest('/keyword-groups', payload);
}

export async function deleteKeywordGroup(groupId) {
  return deleteRequest(`/keyword-groups/${groupId}`);
}

export async function updateKeywordGroup(groupId, keywords, groupName) {
  const payload = {
    groupName,
    keywords,
  };
  return putRequest(`/keyword-groups/${groupId}`, payload);
}

export async function getKeywordGroups(offset) {
  return getRequest('/keyword-groups', { offset });
}

export async function getGroupKeywords(groupName) {
  return getRequest(`/keyword-groups/${groupName}`);
}

export async function getSrmTeams() {
  return getRequest('/srm-teams');
}

export async function createSrmTeam(teamName, teamMembers, qaTemplateId) {
  const payload = {
    teamName,
    teamMembers,
    qaTemplateId,
    createdBy: getUserEmail(),
  };
  return postRequest('/srm-teams', payload);
}

export async function deleteSrmTeam(teamId) {
  return deleteRequest(`/srm-teams/${teamId}`);
}

export async function updateSrmTeam(teamID, teamMembers, teamName, qaTemplateId) {
  const payload = {
    teamName,
    teamMembers,
    qaTemplateId,
  };
  return putRequest(`/srm-teams/${teamID}`, payload);
}

export async function getBlockedNumbers() {
  return getRequest('/blocked-numbers');
}

export async function unblockNumber(phoneNumberId) {
  return deleteRequest(`/blocked-numbers/${phoneNumberId}`);
}

export async function blockNumber(phoneNumber, hideExistingCalls) {
  const payload = {
    createdBy: getUserEmail(),
    phoneNumber,
    hideExistingCalls,
  };
  return postRequest(`/blocked-numbers`, payload);
}

export async function getQaTemplate(recordingId) {
  return getRequest(`/qa-templates/${recordingId}`);
}

export async function getQATemplates() {
  return getRequest('/qa-templates');
}

export async function createQATemplate(name, template) {
  const payload = {
    name,
    template,
    author: getUserEmail(),
  };

  return postRequest('/qa-templates', payload);
}

export async function hideQATemplate(templateId) {
  const payload = {
    templateId,
  };

  return putRequest(`/qa-templates/${templateId}/hide`, payload);
}

export async function updateQATemplate(templateId, name, template) {
  const payload = {
    name,
    template,
    author: getUserEmail(),
  };
  return postRequest(`/qa-templates/${templateId}`, payload);
}

export async function addCallQA(recordingId, notes, calibrationFormData, aggregatedScore, sectionScores, qaTemplateId) {
  const payload = {
    aggregatedScore,
    notes: JSON.stringify(notes),
    author: getUserEmail(),
    authorName: getUserFullName(),
    calibrationFormData: JSON.stringify(calibrationFormData),
    sectionScores: JSON.stringify(sectionScores),
    qaTemplateId: qaTemplateId,
  };
  return postRequest(`/qa/${recordingId}`, payload);
}

export async function removeCallQA(recordingId) {
  return deleteRequest(`/qa/${recordingId}`);
}

export async function getCallQAData(recordingId) {
  const responseBody = await getRequest(`/qa/${recordingId}`);
  return responseBody[0];
}

export async function acknowledgeCallQA(recordingId) {
  const responseBody = await putRequest(`/qa/${recordingId}/acknowledge`);
  return responseBody[0];
}

export async function listCallQAAuthors() {
  const responseBody = await getRequest('/filters/qa-authors');
  const qaAuthorList = responseBody.map(qaAuthor => qaAuthor.authorName);

  // If the user has QA permissions, and is not found in the list, add their name to list as the default filter value
  const currentUserName = getUserFullName();
  if (checkUserRole('call_transcriber_review') && !qaAuthorList.includes(currentUserName)) {
    qaAuthorList.push(currentUserName);
  }

  const qaAuthors = [{ label: 'All QA Authors', value: null }];
  qaAuthors.push(
    ...qaAuthorList.sort().map(qaAuthor => ({
      label: qaAuthor,
      value: qaAuthor,
    }))
  );
  return qaAuthors;
}

// TODO: Move all the below functions to a separate module, and rename this one to api-utils or something more appropriate.
//
// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, '');
  if (!phoneNumber) return;

  try {
    let parsedNumber = parsePhoneNumber(phoneNumber, 'US');
    if (parsedNumber && parsedNumber.countryCallingCode === '1' && parsedNumber.isValid()) {
      return parsedNumber.formatNational();
    } else {
      if (phoneNumber.substring(0, 3) === '011') phoneNumber = phoneNumber.substring(3);
      parsedNumber = parsePhoneNumberFromString(`+${phoneNumber}`);

      if (!parsedNumber) return phoneNumber;
      else return parsedNumber.formatInternational();
    }
  } catch (err) {
    return phoneNumber;
  }
}

function formatCallLength(seconds) {
  seconds = parseFloat(seconds, 10);
  const tempTime = moment.duration(seconds, 'seconds');
  let formattedTime = '';
  if (tempTime.hours()) formattedTime += `${tempTime.hours()}hr `;
  if (tempTime.minutes()) formattedTime += `${tempTime.minutes()}min `;
  formattedTime += `${tempTime.seconds()}sec`;

  return formattedTime;
}

const getSentenceCountBySpeaker = (transcript, speakerTag) =>
  transcript.filter(x => x.speakerTag === speakerTag).length;

function calculateTalkListenRatio(transcription) {
  const supplierUtterancesCount = getSentenceCountBySpeaker(transcription, 1);
  const srmUtterancesCount = getSentenceCountBySpeaker(transcription, 2);
  const supplierRatio = Math.round(100 * (supplierUtterancesCount / (supplierUtterancesCount + srmUtterancesCount)));

  if (isNaN(supplierRatio)) return '0 : 100';

  return `${100 - supplierRatio} : ${supplierRatio}`;
}

export const getSentenceIndexByTime = (transcript, currentTime) =>
  transcript.findIndex(sentence => sentence.endTime > currentTime);

export function transformCallData(callData) {
  let transcript;
  if (callData.formattedTranscript) transcript = JSON.parse(callData.formattedTranscript);
  return {
    recordingId: callData.recordingId,
    audioSrc: callData.signedUrl,
    s3Key: callData.s3Path,
    s3Bucket: callData.s3Bucket,
    srmName: callData.localName,
    audioLength: callData.audioLength ? formatCallLength(callData.audioLength) : '-',
    rawAudioLength: parseFloat(callData.audioLength),
    supplierPhone: callData.remoteAddress ? formatPhoneNumber(callData.remoteAddress) : '',
    initiatedTime: callData.initiatedTime,
    relatedAccounts: callData.relatedAccounts ? JSON.parse(callData.relatedAccounts) : [],
    talkListenRatio: transcript && calculateTalkListenRatio(transcript),
    questionCount: callData.questionCount,
    transcript,
    transcribed: callData.transcribed,
    possibleVoicemail: callData.possibleVoicemail,
    dualChannel: callData.dualChannel,
    callId: callData.callId,
    uploadedBy: callData.uploadedBy,
    notes: callData.notes,
    transcriptionInProgress: callData.transcriptionInProgress,
    callLanguage: callData.callLanguage,
    answerStatus: callData.answerStatus,
    callCountry: callData.remoteCountry,
    callDirection: callData.callDirection,
    tags: callData.tags,
    isInLibrary: callData.isInLibrary,
    isQAed: callData.isReviewed,
    acknowledged: callData.acknowledged,
    acknowledgedDate: callData.acknowledgedDate,
    qaDate: callData.reviewDate,
    disposition: callData.dispositionCode,
  };
}

export function transformTranscriptData(callData) {
  const transcript = JSON.parse(callData.formattedTranscript);

  return {
    recordingId: callData.recordingId,
    talkListenRatio: transcript && calculateTalkListenRatio(transcript),
    transcript,
    possibleVoicemail: callData.possibleVoicemail,
    questionCount: callData.questionCount,
  };
}

export function getBadgeClassName(feedback) {
  switch (feedback) {
    case 'Successful':
      return 'feedback-badge--successful';
    case 'Needs Improvement':
      return 'feedback-badge--needs-improvement';
    default:
      return 'feedback-badge--neutral';
  }
}

export function getScoreButtonClassName(score) {
  switch (score) {
    case 'Successful':
      return 'feedback-button--successful';
    case 'Needs Improvement':
      return 'feedback-button--needs-improvement';
    default:
      return 'feedback-button--neutral';
  }
}

function getLanguageCode(callLanguage, callCountry) {
  switch (callLanguage) {
    case 'English':
      if (callCountry === 'United Kingdom') return 'en-GB';
      if (callCountry === 'Australia') return 'en-AU';
      if (callCountry === 'India') return 'en-IN';
      else return 'en-US';
    case 'Danish':
      return 'da-DK';
    case 'French':
      return 'fr-FR';
    case 'German':
      return 'de-DE';
    case 'Italian':
      return 'it-IT';
    case 'Japanese':
      return 'ja-JP';
    case 'Mandarin':
      if (callCountry === 'Taiwan') return 'zh-TW';
      else return 'zh';
    case 'Spanish':
      return 'es-US';
    case 'Swedish':
      return 'sv-SE';
    case 'Turkish':
      return 'tr-TR';
    default:
      return 'en-US';
  }
}

export function flattenTagList(tagList) {
  const flattenedTagList = [];
  tagList.forEach(tag => {
    if (tag.subTags) {
      for (const subTag of tag.subTags) {
        flattenedTagList.push({
          displayName: subTag.display_name,
          id: subTag.id,
        });
      }
    }
    flattenedTagList.push(tag);
  });
  return flattenedTagList;
}
